<app-body>

    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="lootingService.settings.timeLimit > 0">
            {{ lootingService.timer.time | milliseconds }}
        </ng-container>
        <ng-container *ngIf="lootingService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <lui-operation-teams-ranking wuiTacticalCorners
        [teams]="registerService.teams"
        [pointsCurrency]="lootingService.settings.pointsCurrency"
        [pointsLimit]="lootingService.settings.pointsLimit">
    </lui-operation-teams-ranking>

    <lui-timeline *ngIf="lootingService.timeline.length" wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="lootingService.timeline"
        [scrolling]="false">
    </lui-timeline>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button *ngIf="lootingService.state === OperationStates.Iddle"       (click)="lootingService.start()">{{ 'shared.start' | translate }}</wui-button>
        <wui-button *ngIf="lootingService.state === OperationStates.Processing"  (click)="lootingService.pause()">{{ 'shared.pause' | translate }}</wui-button>
        <wui-button *ngIf="lootingService.state === OperationStates.Paused"      (click)="lootingService.resume()">{{ 'shared.resume' | translate }}</wui-button>
        <wui-button *ngIf="lootingService.state === OperationStates.Processing"  (click)="lootingService.complete()">{{ 'shared.complete' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
