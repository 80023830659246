<div
    *ngIf="data"
    class="window"
    [ngClass] = "data.display.state"

    [style.width] =  "data.display.width  + '%'"
    [style.height] = "data.display.height + '%'"
    [style.left] =   "data.display.left   + '%'"
    [style.top] =    "data.display.top    + '%'"
    [style.zIndex] = "data.display.priority"

    [wuiDraggable]="{container: '.windows', handle: '.title', bounded: true}"
    (dragStop)="dragStop($event)"

    [wuiResizable]="{container: '.windows', handles: ['all'], limits: data.manifest.desktop.limits }"
    (wuiResizeStop)="wuiResizeStop($event)"

    [wuiMaximizable]="{container: '.windows', handle: '.title'}"

    (click)="focus()"

    wuiTacticalCorners>
    <div class="bar" (mouseover)="focus()">
        <div class="title">{{data.manifest.name}}</div>
        <div class="buttons">
            <div
                class="minimize"
                (click)="minimize()">
            </div>
            <div
                [class]="isMaximized ? 'restore' : 'maximize'"
                (click)="toggleSize()"
                (mouseover)="openAnchors()"
                (mouseout)="cancelOpenAnchors()">
            </div>
            <div
                class="close"
                (click)="close()">
            </div>
        </div>
    </div>
    <div class="content" (wuiPointerSwipe)="swipe($event)">
        <ng-template class="content" #content></ng-template>
    </div>
    <div class="anchors" *ngIf="isAnchorsVisible" (mouseleave)="closeAnchors()">
        <div class="areas grid3x2">
            <div class="zone a" (click)="setAnchor(00,          00, oneThiers, 50)"></div>
            <div class="zone b" (click)="setAnchor(oneThiers,   00, oneThiers, 50)"></div>
            <div class="zone c" (click)="setAnchor(twoThiers,   00, oneThiers, 50)"></div>
            <div class="zone d" (click)="setAnchor(00,          50, oneThiers, 50)"></div>
            <div class="zone e" (click)="setAnchor(oneThiers,   50, oneThiers, 50)"></div>
            <div class="zone f" (click)="setAnchor(twoThiers,   50, oneThiers, 50)"></div>
        </div>
        <div class="areas grid3x1">
            <div class="zone a" (click)="setAnchor(00,          00, oneThiers, 100)"></div>
            <div class="zone b" (click)="setAnchor(oneThiers,   00, oneThiers, 100)"></div>
            <div class="zone c" (click)="setAnchor(twoThiers,   00, oneThiers, 100)"></div>
        </div>
       <div class="areas grid2x2">
            <div class="zone a" (click)="setAnchor(00, 00, 50, 50)"></div>
            <div class="zone b" (click)="setAnchor(50, 00, 50, 50)"></div>
            <div class="zone c" (click)="setAnchor(00, 50, 50, 50)"></div>
            <div class="zone d" (click)="setAnchor(50, 50, 50, 50)"></div>
       </div>
       <div class="areas grid1x2">
            <div class="zone a" (click)="setAnchor(00, 00, 100, 50)"></div>
            <div class="zone b" (click)="setAnchor(00, 50, 100, 50)"></div>
        </div>
        <div class="areas grid2x1">
            <div class="zone a" (click)="setAnchor(00, 00, 50, 100)"></div>
            <div class="zone b" (click)="setAnchor(50, 00, 50, 100)"></div>
        </div>
    </div>
</div>

<!--     [top]="{event: 'mousedown', onInit: true}" -->
