<app-container>
    <app-header logo="assets/apps/reports/logo.svg" [description]="'apps.reports.headerDescription' | translate"></app-header>

    @if(!onlineService.isAvailableAndLoggedIn()) {
        <app-disclamer
            level="warning"
            (click)="loginOnline()"
            wuiStopPropagation
            [isInteractive]="true">
            {{ 'apps.reports.onlineRequiredForUpload' | translate  | capitalize }}
        </app-disclamer>
    }

    <app-body>

        @if(reportsService.reports.length === 0) {
            <wui-placeholder>
                {{ 'apps.reports.noSavedReports' | translate }}
            </wui-placeholder>
        } @else {

            <div class="reports">
                @if(reportsService.reports.length > 0) {
                    <wui-section [name]="'apps.reports.sectionSavedReports' | translate">

                        @for(report of reportsService.reports; track report) {

                            <wui-expendable wuiTacticalCorners>
                                <div preview class="preview">
                                    <div class="logo" [wuiBackgroundImage]="'assets/operations/modes/' + report.mode + '/logo.svg'"></div>
                                    <div class="information">
                                        <div class="title">
                                            <div class="name">
                                                {{ report.timeline[0].time | date : 'yyyy.MM.dd  HH:mm' }}
                                            </div>

                                            @if(report.id) {
                                                <div class="open"
                                                    [wuiDisabled]="!onlineService.isAvailable"
                                                    [title]="'shared.open' | translate"
                                                    (click)="reportsService.openOnline(report)"
                                                    wuiStopPropagation>
                                                </div>
                                            } @else {
                                                <div class="upload"
                                                    [wuiDisabled]="!onlineService.isAvailable"
                                                    [title]="'shared.upload' | translate"
                                                    (click)="reportsService.upload(report)"
                                                    wuiStopPropagation>
                                                </div>
                                            }

                                            <div class="remove"
                                                [title]="'shared.remove' | translate"
                                                (click)="reportsService.remove(report)"
                                                wuiStopPropagation>
                                            </div>
                                        </div>
                                        <div class="properties">

                                            <div class="property">
                                                {{ report.mode }}
                                            </div>

                                            <div class="property">
                                                <!-- <img src="assets/apps/lora/properties/activity.svg"/> -->
                                                {{ report.operators.length }} {{ 'shared.operators' | translate }}
                                            </div>
                                            <div class="property">
                                                <!-- <img src="assets/apps/lora/properties/activity.svg"/> -->
                                                {{ report.timeline[0].context.teams.length }} {{ 'shared.teams' | translate }}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div content class="content">

                                    <lui-operation-report
                                        #reportComponent
                                        [report]="report"
                                        [customIconsRootUrl]="onlineService.backendUrl"
                                        (trophyClick)="trophyClick($event)">
                                    </lui-operation-report>

                                    <wui-buttons>

                                        <wui-button
                                            developpersOnly
                                            (click)="reportsService.export(report)">
                                            {{ 'shared.export' | translate }}
                                        </wui-button>

                                        <wui-button
                                            (click)="reportComponent.toggleReplay()">
                                            {{ 'shared.' + (reportComponent.isReplaying ? 'replaying' : 'replay') | translate }}
                                        </wui-button>

                                    </wui-buttons>

                                    <pre developpersOnly>{{ report | json }}</pre>
                                </div>
                            </wui-expendable>
                        }

                    </wui-section>
                }
            </div>
        }
    </app-body>

    <app-footer>
        <wui-buttons>
            <wui-button
                developpersOnly
                (click)="reportsService.import()">
                {{ 'shared.import' | translate }}
            </wui-button>
            <wui-button
                (click)="reportsService.clear()">
                {{ 'shared.clear' | translate }}
            </wui-button>
            <!-- <wui-button>{{ 'shared.uploadAll' | translate }}</wui-button> -->
        </wui-buttons>
    </app-footer>



</app-container>
