<app-container>

    <app-header [logo]="'assets/apps/operations/logo.svg'"
                [description]="operationsService.operation ?
                    (('apps.operations.modes.' + operationsService.operation.name + '.name' | translate) + ':' + ('apps.operations.modes.' + operationsService.operation.name + '.description' | translate) )  :
                    'apps.operations.headerDescription' | translate | capitalize">
    </app-header>

    @if(!gatewayService.isConnected) {
        <app-disclamer
            level="error"
            wuiStopPropagation
            (click)="environmentService.windowOpenByAppId('gateway')"
            [isInteractive]="true">
            {{ 'apps.operations.disclamers.gatewayRequired' | translate }}
        </app-disclamer>
    }

    @if(operationsService.operation) {
        <ng-container *ngComponentOutlet="operationsService.operation.component"></ng-container>
    } @else {
        <app-body>
            <wui-cards class="cards">
                @for (operation of operationsService.operations; track operation) {
                    <wui-card
                        [badge]="operation.tag"
                        [title]="'apps.operations.modes.' + operation.name + '.name' | translate | lowercase"
                        [description]="'apps.operations.modes.' + operation.name + '.description' | translate"
                        [logo]="operation.logo"

                        [wuiDisabled]="operation.disabled === true"
                        (click)="operationsService.select(operation)">
                    </wui-card>
                }
            </wui-cards>
        </app-body>
    }

</app-container>
