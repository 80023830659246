import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { WarmupService } from '../../services/warmup.service';

@Component({
    selector: 'app-warmup-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss'],
})
export class DebriefingComponent {

    constructor(
        public onlineService: OnlineService,
        public operationService: WarmupService) { }
}
