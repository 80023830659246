export interface Manifest {
    id: string;
    name: string;
    description: string;
    component: any; // Component
    mobile: any;
    desktop: {
        single: boolean;
        limits: {
            minWidth?: number,
            minHeight?: number,
            maxWidth?: number,
            maxHeight?: number
        }
    };
    argument: any;
    isHidden?: boolean;
}
