import { Component, OnInit } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

const STORAGE_NAME = 'operations-reports';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
    styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

    public operationsReports = JSON.parse(localStorage.getItem(STORAGE_NAME) || '{}');

    constructor(public onlineService: OnlineService) { }

    ngOnInit(): void {
        
    }

}
