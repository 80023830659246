import { Injectable } from '@angular/core';
import { TeamUnderOperation, Products, OperationWinner, OperationTrophy, OperationTrophiesTypes, OperationActions, PmrSounds, OperatorAvatarName } from '@lightning/lightning-definitions';
import { NfcCashupData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';

@Injectable({
    providedIn: 'root'
})

export class LootingService extends OperationBase {

    constructor() {

        super('looting');

        this.settings = {
            version: 2,

            name: 'Looting op',
            timeLimit: 1800000,

            pointsLimit: 1000000,
            pointsCurrency: 'USD',

            isBroadcastedExtractionAnnounce: false
        };

        this.organisatorActions = [
            this.getOrganisatorActionNote(),
            this.getOrganisatorActionTeamBonusPenality()
        ];

        this.loraProtocolService.onNfcCashupReceive
            .subscribe((data: NfcCashupData) => this.onNfcCashupReceive(data));

        this.registerService.onTeamsRankingLeadChanged
            .subscribe((team: TeamUnderOperation) => this.teamsRankingLeadChanged(team));
    }


    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams 2-4',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2 && this.registerService.teams.length <= 4,
                help: '',
            },
            {
                name: 'Ground modules 1+',
                value: groundModules.length.toString(),
                isReady: groundModules.length >= 1,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements.some(requierement => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {
        return true;
    }

    public override settingsApply(): void {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return this.getWinnerByTeamRank();
    }

    public override getTrophies(): Array<OperationTrophy> {

        const trophies: Array<OperationTrophy> = [];

        let operators = this.registerService.operators.filter(operator => operator.data.stats);

        // 2 active operators or more
        if (operators.length < 2) {
            return trophies;
        }

        // First one
        trophies.push({
            type: OperationTrophiesTypes.LootingFirstOne,
            data: {
                operator: operators.find(operator => operator.data.stats?.isFirst)
            }
        });

        // Biggest total without equality
        operators = operators.sort((a, b) => { return (b.data.stats?.biggest || 0) - (a.data.stats?.biggest || 0) });

        if((operators[0].data.stats?.biggest || 0)> (operators[1].data.stats?.biggest || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.LootingBiggestOne,
                data: {
                    operator: operators[0]
                }
            });
        }

        // Biggest total without equality
        operators = operators.sort((a, b) => { return (b.data.stats?.total || 0) - (a.data.stats?.total || 0) });

        if((operators[0].data.stats?.total || 0) > (operators[1].data.stats?.total || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.LootingBiggestTotal,
                data: {
                    operator: operators[0]
                }
            });
        }

        return trophies;
    }


    private async onNfcCashupReceive(data: NfcCashupData): Promise<void> {

        const { sender, operator, total } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // Check for staff team
        if (team.isStaff) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: 'The staff member ' + operator.name + ' tested the area' + sender.name,
                color: 'yellow'
            });

            return;
        }

        // Increase team points and update ranking
        this.registerService.increaseTeamScore(team, total);

        // Store data for trophies calculations
        const isFirst: boolean =
            this.timeline.filter(item => item.action === OperationActions.LootingExtracted).length === 0;

        operator.data.stats = operator.data.stats || { isFirst: false, count: 0, biggest: 0, total: 0 };
        operator.data.stats.isFirst = isFirst ? true : operator.data.stats.isFirst;
        operator.data.stats.count = (operator.data.stats.count || 0) + 1;
        operator.data.stats.biggest = total > (operator.data.stats.biggest || 0) ? total : operator.data.stats.biggest;
        operator.data.stats.total = (operator.data.stats.total || 0) + total;

        // Fill the timeline
        this.timelinePush({
            level: 1,
            action: OperationActions.LootingExtracted,
            data: {
                operator, total, team, area: sender.name
            },
            customIconPath:
                this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
        });

        // Announce via PMR
        this.pmrService.announce(PmrSounds.Copter, this.settings.isBroadcastedExtractionAnnounce ? undefined : team);   // TODO: Rename the sound Copter -> Extraction

        // Points limit reached
        if (this.settings.pointsLimit > 0 && team.score.points >= this.settings.pointsLimit) {
            this.complete();
        }

    }

    private teamsRankingLeadChanged(team: TeamUnderOperation): void {

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)['TeamLeads' + team.name]);
    }
}
