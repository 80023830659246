<app-body>
    <wui-section [name]="'shared.objectives' | translate">
        {{ 'apps.operations.modes.sandbox.objectives' | translate }}
    </wui-section>
    <wui-section [name]="'shared.requirements' | translate">
        {{ 'apps.operations.modes.sandbox.requirements' | translate }}
    </wui-section>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="sandboxService.exit()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="sandboxService.setup()">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
