import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';

const MOUTH_INTERVAL =      200;
const EYES_INTERVAL =       4000;
const SLEEP_DELAY =         120000;
const SLEEP_TEXT_DELAY =    500;

@Component({
    selector: 'app-instructor',
    templateUrl: './instructor.component.html',
    styleUrls: ['./instructor.component.scss'],
})
export class InstructorComponent implements OnInit, OnDestroy {

    public isSpeaking = false;
    public hasClosedEyes = false;
    public isSleeping = false;

    public sleepingText = '..ZzzZZ.ZZzzzZ..ZzZZz';

    private timers: any = {
        speaking: undefined,
        eyes: undefined,
        inactivity: undefined,
        sleeping: undefined
    };

    ngOnInit(): void {

        this.timers.speaking = setInterval(() => {

            if(this.isSleeping) {
                return;
            }

            this.isSpeaking = !this.isSpeaking;

            this.hasClosedEyes = false;

        }, MOUTH_INTERVAL);

        this.timers.eyes = setInterval(() => {

            this.hasClosedEyes = true;

        }, EYES_INTERVAL);

        this.wakeUp();
    }

    ngOnDestroy(): void {
        clearInterval(this.timers.speaking);
        clearInterval(this.timers.eyes);
        clearInterval(this.timers.inactivity);
        clearInterval(this.timers.speaking);
    }

    @HostListener('document:mousemove')
    private wakeUp(): void {

        this.isSleeping = false;

        clearTimeout(this.timers.inactivity);

        clearInterval(this.timers.sleeping);

        this.timers.inactivity = setTimeout(() => {

            this.isSleeping = true;

            this.timers.sleeping = setInterval(() => {

                const lastCharacter = this.sleepingText.charAt(this.sleepingText.length - 1);

                this.sleepingText = lastCharacter + this.sleepingText.slice(0, this.sleepingText.length - 1);

            }, SLEEP_TEXT_DELAY);

        }, SLEEP_DELAY);
    }


}
