<app-body>

    @if(registerService.operators.length === 0) {
        <wui-placeholder>
            {{ 'apps.register.placeHolders.noOperators' | translate }}
        </wui-placeholder>
    } @else {

        <!-- header -->
        <div class="line">
            <div class="name"></div>
            <div class="teams">
                @for(team of registerService.teamsSlots; track team) {
                    <div class="team"
                        [class]="team.name | lowercase"
                        [wuiDisabled]="team.operatorsCount === 0"
                        [title]="team.operatorsCount + ' / ' + registerService.assignedOperatorsCount">
                        {{ team.id }}
                    </div>
                }
            </div>
            <div class="button"></div>
        </div>

        <!-- operators -->
        @for(operator of registerService.operators; track operator.number) {
            <div class="line">
                <div class="name">
                    {{ registerService.getOperatorFriendlyName(operator) }}
<!--
                    @if (operator.role) {
                        <span class="role">({{ ('roles.operators.' + operator.role) | translate }})</span>
                    } -->

                </div>
                <div class="role">
                    @if (operator.role) {
                        <span class="role">{{ ('roles.operators.' + operator.role) | translate }}</span>
                    }
                </div>
                <div class="teams">
                    @for(team of registerService.teamsSlots; track team) {
                        <div class="team"
                            [class]="team.name | lowercase"
                            [wuiBackgroundColor]="operator.teamId === team.id ? team.color : ''"
                            (click)="registerService.setOperatorTeam(operator, operator.teamId === team.id ? undefined : team)">
                            {{operator.teamId === team.id ? team.id : '·'}}
                        </div>
                    }
                </div>
                <div class="button" (click)="registerService.removeOperator(operator)">-</div>
            </div>
        }
    }

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="sort()">{{ 'shared.sort' | translate }}</wui-button>
        <wui-button (click)="shuffle()">{{ 'shared.suffle' | translate }}</wui-button>
        <wui-button (click)="clear()">{{ 'shared.clear' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
