import { Component } from '@angular/core';
import { ContaminationService } from '../../services/contamination.service';

@Component({
    selector: 'app-contamination-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.scss'],
})
export class BriefingComponent {
    constructor(public contaminationService: ContaminationService) {}
}
