<app-header *ngIf="description" [logo]="logo" [description]="description"></app-header>

<app-body>

    <!-- header -->
    <div class="line">
        <div class="name"></div>
        <div class="teams">
            <div class="team"
                *ngFor="let team of teams"
                [wuiDisabled]="team.operatorsCount === 0">
                {{ team.id }}
            </div>
        </div>
    </div>

    <!-- operator -->
    <div class="line">
        <div class="name">{{ operator?.name }}</div>
        <div class="teams">
            <div class="team"
                *ngFor="let team of teams"
                [wuiBackgroundColor]="selectedTeam?.id === team.id ? team.color : ''"
                (click)="selectedTeam = (selectedTeam?.id === team.id ? undefined : team)">
                {{ selectedTeam?.id === team.id ? team.id : '·' }}
            </div>
        </div>
    </div>

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="confirm()" [wuiDisabled]="!selectedTeam">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
