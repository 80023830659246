import { Component } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { cicd } from '@lightning/configuration';
import { OnlineService } from '@lightning/lightning-services';

import { GatewayService } from 'apps/headquarter/src/app/apps/shared/services/gateway/gateway.service';
import { LoraProtocolService } from 'apps/headquarter/src/app/apps/shared/services/lora-protocol/lora-protocol.service';
import { PmrService } from 'apps/headquarter/src/app/apps/shared/services/pmr/pmr.service';
import { BatteryService } from 'apps/headquarter/src/app/apps/shared/services/battery/battery.service';
import { GatewayConnectorsNames } from '@lightning/headquarter/app/apps/shared/enums/gateway.enum';
import { ModalOnlineLoginComponent } from '@lightning/headquarter/app/apps/shared/components/modal-online-login/modal-online-login.component';

@Component({
    selector: 'app-tray',
    templateUrl: './tray.component.html',
    styleUrls: ['./tray.component.scss']
})

export class TrayComponent {

    public GatewayConnectorsNames = GatewayConnectorsNames;

    public cicd = cicd;

    constructor(public environmentService: EnvironmentService,
                public gatewayService: GatewayService,
                public loraProtocolService: LoraProtocolService,
                public pmrService: PmrService,
                public onlineService: OnlineService,
                public batteryService: BatteryService) { }

    public loginOnline(): void {

        this.environmentService.modalOpen({ title: '', component: ModalOnlineLoginComponent });
    }
}
