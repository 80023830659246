import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-actions',
    templateUrl: './actions.component.html',
    styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {

    public setup = {
        rootUrl: 'http://192.168.1.206',
        modules: [
            {
                name: 'module A',
                slots: [
                    {
                        name: 'OPR A',
                        servo: 0
                    }, 
                    {
                        name: 'OPR B',
                        servo: 1
                    },
                    {
                        name: 'Cash 1000',
                        servo: 2
                    },
                    {
                        name: 'OTA Update',
                        servo: 3
                    }
                ]
            },
            {
                name: 'module B',
                slots: [
                    {
                        name: 'OPR A',
                        servo: 4
                    }, 
                    {
                        name: 'OPR B',
                        servo: 5
                    }, 
                    {
                        name: 'Cash 1000',
                        servo: 6
                    }, 
                    {
                        name: 'OTA Update',
                        servo: 7
                    }
                ]
            },
            {
                name: 'module C',
                slots: [
                    {
                        name: 'OPR A',
                        servo: 8
                    },
                    {
                        name: 'OPR B',
                        servo: 9
                    },
                    // {
                    //     name: 'Cash 1000',
                    //     servo: 10
                    // },
                    {
                        name: 'OTA Update',
                        servo: 11
                    },
                ]
            }
        ],
        sequences: [
            {
                name: 'operators',
                loop: true,
                servos: [
                    0, 4, 8, 1, 5, 9
                ]
            }
        ]
    };

    
    public sequence: any | null;
    
    constructor() { }

    ngOnInit(): void {

    }

    public run(servo: number): Promise<Response>{

        return fetch(`${this.setup.rootUrl}/${servo}`);
    }

    public runSequence(sequence: any): void {

        this.stopSequence();

        this.sequence = sequence;

        this.sequence.index = 0;

        this.sequence.timer = setInterval(async () => {

            await this.run(this.sequence.servos[this.sequence.index++]);

            if (this.sequence.index >= this.sequence.servos.length) {

                if(this.sequence.loop) {
                    this.sequence.index = 0;
                } else {
                    this.stopSequence();
                }
            }

        }, 5000);
    }

    public stopSequence(): void {

        if (!this.sequence) {
            return;
        }

        clearInterval(this.sequence.timer);

        this.sequence = undefined;

    }

}
