import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modal-list',
    templateUrl: './modal-list.component.html',
    styleUrls: ['./modal-list.component.scss']
})

export class ModalListComponent implements OnInit {

    @Input() 
    public logo: string = '';

    @Input()
    public description: string = '';

    @Input() 
    public items: Array<any> = [];

    @Input() 
    public resolve = (item: any = null) => { };

    constructor(
        public translateService: TranslateService, 
        private environmentService: EnvironmentService) { }

    public ngOnInit(): void {
    }

    public cancelClick(): void {

        this.resolve();

        this.environmentService.modalClose();
    }

    public itemClick(item: any): void {

        this.resolve(item);

        this.environmentService.modalClose();
    }

}
