import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';

import { SharedModule as SharedModuleApps } from '../../../shared/shared.module';


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        WildUiModule,
        SharedModuleApps
    ],
    exports: []
})

export class SharedModule { }
