import { Component } from '@angular/core';
import { AppsUtils } from '@lightning/configuration';

import { EnvironmentService } from '@lightning/wild-environment';
import { LoginRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { TranslateService } from '@ngx-translate/core';
import { StyleUtils } from '@lightning/wild-ui';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    public loginRequest = new LoginRequest();

    constructor(
        private translateService: TranslateService,
        private environmentService: EnvironmentService,
        private onlineService: OnlineService) { }

    public login(): void {
        this.onlineService.login(this.loginRequest)
            .subscribe({
                error: () => {
                    this.environmentService.notificationOpen({
                        logo: 'assets/apps/online/logo.svg',
                        message: this.translateService.instant('apps.online.loginFailed'),
                        color: StyleUtils.getVariable('--color-error')
                    });
                }
            });
    }

    public signin(): void {
        window.open(AppsUtils.resolveAppUrl('website'));
    }

}
