<div class="bubble">
    
    <ng-container *ngIf="isSleeping">
        {{ sleepingText }}
    </ng-container>
    
    <ng-container *ngIf="!isSleeping">
        <ng-content></ng-content>
    </ng-container>
    
</div>
<div 
    class="character"
    [class.closedEyes]="hasClosedEyes || isSleeping"
    [class.speaking]="isSpeaking">
</div>

