<app-body>

    <wui-section *ngFor="let module of setup.modules" [name]="module.name">

        <wui-entry *ngFor="let slot of module.slots">
            <div name>{{ slot.name }}</div>
            <wui-button value (click)="run(slot.servo)">RUN!</wui-button>
        </wui-entry>

    </wui-section>

    <wui-section name="sequences">

        <wui-entry *ngFor="let sequence of setup.sequences">
            <div name>{{ sequence.name }}</div>
            <wui-button value (click)="runSequence(sequence)">RUN!</wui-button>
        </wui-entry>

        <wui-entry *ngIf="sequence">
            <div name>Stop the sequence</div>
            <wui-button value (click)="stopSequence()">STOP</wui-button>
        </wui-entry>

    </wui-section>

</app-body>
