<app-body>
    <wui-section [name]="'shared.generalities' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate }}</div>
            <wui-input value type="text" [(value)]="dominationService.settings.name"></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate }}</div>
            <wui-select value [(value)]="dominationService.settings.timeLimit">
                @for(item of OperationProcessDurations | keyValue; track item) {
                    <option [value]="item.value">
                        {{ 'apps.operations.setup.durations.' + item.key | translate }}
                    </option>
                }
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name>{{ 'apps.operations.setup.pointsLimit' | translate }}</div>
            <wui-input value type="number" [(value)]="dominationService.settings.pointsLimit"></wui-input>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="dominationService.preview()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="dominationService.brief()">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
