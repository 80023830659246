<app-body>
    <wui-section [name]="'shared.generalities' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate }}</div>
            <wui-input
                value
                type="text"
                [(value)]="contaminationService.settings.name">
            </wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate }}</div>
            <wui-select
                value
                [(value)]="contaminationService.settings.timeLimit">
                @for(item of OperationProcessDurations | keyValue; track item) {
                    <option [value]="item.value">
                        {{ 'apps.operations.setup.durations.' + item.key | translate }}
                    </option>
                }
                <option value="60000" developpersOnly>
                    {{ 'apps.operations.setup.durations.s60' | translate }}
                </option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.misc' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.modes.contamination.settings.patientZero.title' | translate }}</div>
            <div detail>{{ 'apps.operations.modes.contamination.settings.patientZero.detail' | translate }}</div>
            <wui-select value [required]="true" [(value)]="contaminationService.settings.patientZero">
                @for(operator of registerService.operators; track operator.number) {
                    <option [value]="registerService.getOperatorFriendlyName(operator)">
                        {{ registerService.getOperatorFriendlyName(operator) }}
                    </option>
                }
            </wui-select>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="contaminationService.preview()">
            {{ 'shared.back' | translate }}
        </wui-button>
        <wui-button
            (click)="contaminationService.brief()"
            [wuiDisabled]="contaminationService.settingsAreValid() === false">
            {{ 'shared.confirm' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
