<app-body>
    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="assemblyService.settings.timeLimit > 0">
            {{ assemblyService.timer.time | milliseconds }}
        </ng-container>
        <ng-container *ngIf="assemblyService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <lui-operation-teams-progress
        wuiTacticalCorners
        [teams]="registerService.teams">
    </lui-operation-teams-progress>

    <lui-timeline
        *ngIf="assemblyService.timeline.length"
        wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="assemblyService.timeline"
        [scrolling]="false">
    </lui-timeline>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button
            *ngIf="assemblyService.state === OperationStates.Iddle"
            (click)="assemblyService.start()">
                {{ 'shared.start' | translate }}
            </wui-button>
        <wui-button
            *ngIf="assemblyService.state === OperationStates.Processing"
            (click)="assemblyService.pause()">
                {{ 'shared.pause' | translate }}
        </wui-button>
        <wui-button
            *ngIf="assemblyService.state === OperationStates.Paused"
            (click)="assemblyService.resume()">
                {{ 'shared.resume' | translate }}
        </wui-button>
        <wui-button
            *ngIf="assemblyService.state === OperationStates.Processing"
            (click)="assemblyService.complete()">
                {{ 'shared.complete' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
