/// <reference types="@types/w3c-web-usb" />

import { EventEmitter } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { GatewayConnectorsNames } from '../../../enums/gateway.enum';
import { GatewayConnector } from './connector.interface';

// const CONFIGURATION = {
//     "DEBUG": true,
//     "DEFAULT_BAUD_RATE": 115200,
//     "BAUD_RATES": [600, 1200, 2400, 4800, 9600, 14400, 19200, 38400, 57600, 115200, 230400],
//     "CH340": {
//         "REQUEST_READ_VERSION": 0x5F,
//         "REQUEST_READ_REGISTRY": 0x95,
//         "REQUEST_WRITE_REGISTRY": 0x9A,
//         "REQUEST_SERIAL_INITIATION": 0xA1,
//         "REG_SERIAL": 0xC29C,
//         "REG_MODEM_CTRL": 0xA4,
//         "REG_MODEM_VALUE_OFF": 0xFF,
//         "REG_MODEM_VALUE_ON": 0xDF,
//         "REG_MODEM_VALUE_CALL": 0x9F,
//         "REG_BAUD_FACTOR": 0x1312,
//         "REG_BAUD_OFFSET": 0x0F2C,
//         "REG_BAUD_LOW": 0x2518,
//         "REG_CONTROL_STATUS": 0x2727,
//         "BAUD_RATE": {
//             600: { "FACTOR": 0x6481, "OFFSET": 0x76 },
//             1200: { "FACTOR": 0xB281, "OFFSET": 0x3B },
//             2400: { "FACTOR": 0xD981, "OFFSET": 0x1E },
//             4800: { "FACTOR": 0x6482, "OFFSET": 0x0F },
//             9600: { "FACTOR": 0xB282, "OFFSET": 0x08 },
//             14400: { "FACTOR": 0xd980, "OFFSET": 0xEB },
//             19200: { "FACTOR": 0xD982, "OFFSET": 0x07 },
//             38400: { "FACTOR": 0x6483, "OFFSET": 0x00 },
//             57600: { "FACTOR": 0x9883, "OFFSET": 0x00 },
//             115200: { "FACTOR": 0xCC83, "OFFSET": 0x00 },
//             230500: { "FACTOR": 0xE683, "OFFSET": 0x00 },
//         }
//     }
// };

// https://stackoverflow.com/questions/64929987/webusb-api-working-but-the-data-received-arent-decoded-properly

export class WebUsbGatewayConnector implements GatewayConnector {

    public onReceive = new EventEmitter<string>;
    public onConnect = new EventEmitter<void>;
    public onConnectFail = new EventEmitter<void>;
    public onDisconnect = new EventEmitter<void>;

    private _isConnected = false;

    private _device: USBDevice | null = null;

    private _decoder = new TextDecoder('utf-8');
    private _encoder = new TextEncoder();

    private _buffer = '';

    public get name(): GatewayConnectorsNames {
        return GatewayConnectorsNames.Usb;
    }

    public async connect(): Promise<void> {

        if (this._isConnected) {
            return;
        }

        if (!('usb' in navigator)) {

            console.error('Web Usb supported');

            this.onConnectFailHandler();

            return;
        }

        this.disconnect();

        // Try to open a previously allowed device
        try {

            // Doesn't require a user gesture but returns device only after a successfully requestPort()
            const devices = await navigator.usb.getDevices();

            if (devices.length > 0) {
                this._device = devices[0];
            }

        } catch (exception) {

            console.log(`Unable to found an allowed device`);
        }

        // Try to ask the user to select a device
        if (this._device === null) {

            try {

                // Requires a user gesture
                this._device = await navigator.usb.requestDevice({ filters: environment.gateway.usb.devicesFilters });

            } catch (exception) {

                console.log(`Unable to select a device`);
            }
        }

        // Failed to select a device
        if (this._device === null) {

            this.onConnectFailHandler();

            return;
        }

        navigator.usb.addEventListener('disconnect', this.onDisconnectHandler.bind(this));

        // Try to open the device
        try {

            await this._device.open();
            await this._device.selectConfiguration(environment.gateway.usb.options.configurationNumber);
            await this._device.claimInterface(environment.gateway.usb.options.interfaceNumber);
            // await this._device.selectAlternateInterface(environment.gateway.usb.options.interfaceNumber,environment.gateway.usb.options.alternateInterfaceNumber);

            // Windows auto detects the right baudrate
            // Android uses a default baudrate 19200, we have to change it for 115200 ...but that doesn't work
            //   B plan (tested): Change the baudrate of the device for 19200


            // https://github.com/Jean-Emile/Serial-to-USB-ANDROID/blob/master/src/main/java/eu/powet/android/serialUSB/UsbSerial.java
            // conn.controlTransfer(0x40, 0, 0, 0, null, 0, 0);//reset
            // conn.controlTransfer(0x40, 0, 1, 0, null, 0, 0);//clear Rx
            // conn.controlTransfer(0x40, 0, 2, 0, null, 0, 0);//clear Tx
            // conn.controlTransfer(0x40, 0x03, baudrate, 0, null, 0, 0);//baudrate
            // conn.controlTransfer(0x40, 0x02, 0x0000, 0, null, 0, 0);//flow control none
            // conn.controlTransfer(0x40, 0x04, 0x0008, 0, null, 0, 0); //data bit 8, parity none, stop bit 1, tx off


            // let transfertResult = await this._device.controlTransferOut({
            //     requestType: 'vendor',
            //     recipient: 'device',
            //     request: CONFIGURATION.CH340.REQUEST_SERIAL_INITIATION,
            //     value: 0xB2B9,
            //     index: CONFIGURATION.CH340.REG_SERIAL,
            // });

            // alert(transfertResult.status); // says OK

            // transfertResult = await this._device.controlTransferOut({
            //     requestType: 'vendor',
            //     recipient: 'device',
            //     request: CONFIGURATION.CH340.REQUEST_WRITE_REGISTRY,
            //     value: CONFIGURATION.CH340.BAUD_RATE[115200].FACTOR,
            //     index: CONFIGURATION.CH340.REG_BAUD_FACTOR,
            // });

            // alert(transfertResult.status);  // says OK

            // transfertResult = await this._device.controlTransferOut({
            //     requestType: 'vendor',
            //     recipient: 'device',
            //     request: CONFIGURATION.CH340.REQUEST_WRITE_REGISTRY,
            //     value: CONFIGURATION.CH340.BAUD_RATE[115200].OFFSET,
            //     index: CONFIGURATION.CH340.REG_BAUD_OFFSET,
            // });

            // alert(transfertResult.status);  // says OK

            // transfertResult = await this._device.controlTransferOut({
            //     requestType: 'vendor',
            //     recipient: 'device',
            //     request: CONFIGURATION.CH340.REQUEST_WRITE_REGISTRY,
            //     value: OPTIONS.interfaceNumber,
            //     index: CONFIGURATION.CH340.REG_CONTROL_STATUS,
            // }, new ArrayBuffer(0));

            // alert(transfertResult.status);  // says OK

            this.onConnectHandler();

            do {
                try {

                    const value = await this._device.transferIn(environment.gateway.usb.options.endpointNumber, 128);

                    // alert(`received data ${this._decoder.decode(value.data)}`);

                    this.buffering(value.data);

                } catch (exception) {

                    // alert(`read error ${exception}`);

                    this.onDisconnectHandler();
                }
            }
            while (this.isConnected);

        }
        catch (exception) {

            console.error(exception);

            // alert(`connection error ${exception}`);

            this.onConnectFailHandler();
        }

    }

    public async disconnect(): Promise<void> {

        if (!this.isConnected) {
            return;
        }

        try {

            if (this._device) {

                await this._device.releaseInterface(environment.gateway.usb.options.interfaceNumber);
                await this._device.close();
            }
        }
        catch (exception) {

            console.log('Failed to release', exception);
        }

        // The event listener seems don't work
        this.onDisconnectHandler();
    }

    public async send(data: string): Promise<void> {

        if (!this._device || !this._isConnected) {
            return;
        }

        try {
            await this._device.transferOut(environment.gateway.usb.options.endpointNumber, this._encoder.encode(data + environment.gateway.linesSeparator));

        } catch(exception) {

            // alert(exception);

            this.onDisconnectHandler();
        }

    }

    public get isConnected(): boolean {

        return this._isConnected;
    }


    private onConnectHandler(): void {

        this._isConnected = true;

        this.onConnect.emit();
    }

    private onConnectFailHandler(): void {

        this._isConnected = false;

        this.onConnectFail.emit();
    }

    private onReceiveHandler(data: string): void {

        this.onReceive.emit(data);
    }

    private onDisconnectHandler(): void {

        this._isConnected = false;

        this.onDisconnect.emit();
    }

    private buffering(value: any): void {

        // Decode binary to text
        const data = this._decoder.decode(value);

        // Concat to the previous incomplete data
        this._buffer += data;

        // No entire line in the buffer, return
        if (this._buffer.indexOf(environment.gateway.linesSeparator) === -1) {
            return;
        }

        // Split lines
        const lines = this._buffer.split(environment.gateway.linesSeparator);

        // Consider the last part is an incomplete line to keep in the buffer
        this._buffer = lines.pop() || '';

        // Handle each line
        for (const line of lines) {
            this.onReceiveHandler(line);
        }

    }

}
