import { Component, OnInit } from '@angular/core';
import { ConvoyService } from '../../services/convoy.service';

@Component({
    selector: 'app-convoy-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

    constructor(public convoyService: ConvoyService) { }

    ngOnInit(): void {
    }

}
