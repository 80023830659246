
import { Injectable } from '@angular/core';
import { TeamUnderOperation, Products, OperationWinner, OperationTrophy, OperationTrophiesTypes, OperationActions, PmrSounds, OperatorAvatarName } from '@lightning/lightning-definitions';
import { NfcOperatorData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';
import { ColorUtils } from '@lightning/utils';

@Injectable({
    providedIn: 'root'
})

export class DominationService extends OperationBase {

    constructor() {

        super('domination');

        this.settings = {
            version: 1,

            name: 'Domination op',
            timeLimit: 1800000,

            pointsLimit: 1000000,
        };

        this.organisatorActions = [
            this.getOrganisatorActionNote(),
            this.getOrganisatorActionTeamBonusPenality()
        ];

        this.loraProtocolService.onNfcOperatorReceive
            .subscribe((data: NfcOperatorData) => this.onNfcOperatorReceive(data));

        this.registerService.onTeamsRankingLeadChanged
            .subscribe((team: TeamUnderOperation) => this.teamsRankingLeadChanged(team));

        this.timer.onSyncSecond.subscribe(() => this.timerOnTick());
    }


    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams 2-4',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2 && this.registerService.teams.length <= 4,
                help: '',
            },
            {
                name: 'Ground modules 1+',
                value: groundModules.length.toString(),
                isReady: groundModules.length > 0,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements.some(requierement => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {
        return true;
    }

    public override settingsApply() {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return this.getWinnerByTeamRank();
    }


    public override getTrophies(): Array<OperationTrophy> {

        const trophies: Array<OperationTrophy> = [];

        // Active operators only
        let operators = this.registerService.operators.filter(operator => operator.data.stats);

        // 2 active operators or more
        if (operators.length < 2) {
            return trophies;
        }

        // First one
        trophies.push({
            type: OperationTrophiesTypes.DominationCapturesFirstOne,
            data: {
                operator: operators.find(operator => operator.data.stats?.isFirst)
            }
        });

        // Biggest total without equality
        operators = operators.sort((a, b) => { return (b.data.stats?.total || 0) - (a.data.stats?.total || 0) });

        if((operators[0].data.stats?.total || 0) > (operators[1].data.stats?.total || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.DominationCapturesBiggestTotal,
                data: {
                    operator: operators[0]
                }
            });
        }

        return trophies;
    }


    private async onNfcOperatorReceive(data: NfcOperatorData): Promise<void> {

        const { sender, operator } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // Check for staff team
        if (team.isStaff) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: `The staff member ${operator.name} tested the areaof the area ${sender.name}`,
                color: 'yellow'
            });

            return;
        }

        // Do it even if the area was already taken, maybe the module failed to set the color
        this.loraProtocolService.sendColorCommand(ColorUtils.saturate(team.color, 100), 0, sender.id);

        // Check if this module was not already captured by this team
        if (sender.data.teamId === team.id) {

            this.environmentService.notificationOpen({
                logo: 'assets/apps/operations/logo.svg',
                message: `The operator ${operator.name} tried to capture again the area ${sender.name}`,
                color: 'yellow'
            });

            return;
        }

        // -------------------------------------------------------------------------
        // Normal case

        // The team captures the area
        sender.data.teamId = team.id;

        // Store data for trophies calculations
        const isFirst: boolean =
            this.timeline.filter(item => item.action === OperationActions.AreaCaptured).length === 0;

        operator.data.stats = operator.data.stats || { isFirst: false, total: 0 };
        operator.data.stats.isFirst = isFirst ? true : operator.data.stats.isFirst;
        operator.data.stats.total = (operator.data.stats.total || 0) + 1;

        //
        this.timelinePush({
            level: 1,
            action: OperationActions.AreaCaptured,
            data: {
                operator, team, area: sender.name
            },
            customIconPath: this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
        });

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)['AreaCaptured' + sender.name]);
        this.pmrService.announce((PmrSounds as any)['TeamBy' + team.name]);
    }

    private teamsRankingLeadChanged(team: TeamUnderOperation): void {

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Announce via PMR
        this.pmrService.announce((PmrSounds as any)['TeamLeads' + team.name]);
    }

    private timerOnTick(): void {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        for (const groundModule of groundModules) {

            if (groundModule.data.teamId) {

                const team = this.registerService.getTeam(groundModule.data.teamId);

                if (!team) {
                    return;
                }

                // Increase team points and update ranking
                this.registerService.increaseTeamScore(team, 1);

                // Points limit reached
                if (this.settings.pointsLimit > 0 && team.score.points >= this.settings.pointsLimit) {

                    this.complete();

                    return;
                }
            }
        }

    }

}
