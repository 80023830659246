<app-body>
    <wui-section [name]="'shared.generalities' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate }}</div>
            <wui-input value type="text" [(value)]="assemblyService.settings.name">
            </wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate }}</div>
            <wui-select value [(value)]="assemblyService.settings.timeLimit">
                @for(item of OperationProcessDurations | keyValue; track item) {
                    <option [value]="item.value">
                        {{ 'apps.operations.setup.durations.' + item.key | translate }}
                    </option>
                }
                <option value="60000" developpersOnly>
                    {{ 'apps.operations.setup.durations.s60' | translate }}
                </option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section name="object">
        <wui-entry>
            <div name>Object to assemble</div>
            <wui-select value [(value)]="assemblyService.settings.object" (valueChange)="resetPartsNumbers()">
                @for(item of Assemblies | keyValue; track item) {
                    <option [value]="item.value">
                        {{ item.key }}
                    </option>
                }
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section name="parts numbers" [wuiDisabled]="assemblyService.settings.object !== Assemblies.Custom">
        <wui-entry *ngFor="let team of registerService.teams; let index = index">
            <div name>Team {{ team.name }}</div>
            <wui-input value [(value)]="assemblyService.settings.partsNumbers[index]" pattern="^\d+( \d+)*$"></wui-input>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="assemblyService.preview()">
            {{ 'shared.back' | translate }}
            </wui-button>
        <wui-button (click)="assemblyService.brief()" [wuiDisabled]="assemblyService.settingsAreValid() === false">
            {{ 'shared.confirm' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
