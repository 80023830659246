import { Component, OnInit } from '@angular/core';
import { PrisonersService } from '../../services/prisoners.service';

@Component({
    selector: 'app-prisonners-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.scss'],
})
export class BriefingComponent implements OnInit {
    constructor(public prisonersService: PrisonersService) {}

    ngOnInit() {}
}
