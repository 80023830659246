import { Component } from '@angular/core';
import { EnvironmentService } from '@lightning/wild-environment';
import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';
import { ModalYesNoComponent } from 'apps/headquarter/src/app/apps/shared/components/modal-yes-no/modal-yes-no.component';
import { TranslateService } from '@ngx-translate/core';
import { OperatorRoles, OperatorUnderOperation } from '@lightning/lightning-definitions';

@Component({
    selector: 'app-operators',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent {

    private sortIndex = 0;

    constructor(
        public registerService: RegisterService,
        private translateService: TranslateService,
        private environmentService: EnvironmentService) { }


    public sort(): void {

        switch(this.sortIndex++) {
            case 0:
                this.registerService.sortOperatorsByTeam();
            break;
            case 1:
                this.registerService.sortOperatorsByNumber();
            break;
            case 2:
                this.registerService.sortOperatorsByName();
            break;
            default:
                this.sortIndex = 0;
                this.sort();
            break;
        }
    }

    public async shuffle(): Promise<void> {

        const isConfirmed = await this.environmentService.modalOpen({
            title: this.translateService.instant('apps.register.modals.shuffle.title'),
            component: ModalYesNoComponent,
            inputs: {
                logo: 'assets/apps/register/logo.svg',
                description: this.translateService.instant('apps.register.modals.shuffle.description'),
            }
        });

        if (isConfirmed) {
            this.registerService.shuffleOperatorsInTeams();
        }
    }

    public async clear(): Promise<void> {

        const isConfirmed = await this.environmentService.modalOpen({
            title: this.translateService.instant('apps.register.modals.clear.title'),
            component: ModalYesNoComponent,
            inputs: {
                logo: 'assets/apps/register/logo.svg',
                description: this.translateService.instant('apps.register.modals.clear.description'),
            }
        });

        if (isConfirmed) {
            this.registerService.clearOperators();
        }
    }

}
