import { Manifest } from './manifest.interface';

export enum WindowState {
    Normal = 'normal',
    Expended = 'expended',
    Minimized = 'minimized'
}

export interface Window {
    id: number;
    argument: any;
    manifest: Manifest;
    display: {
        left: number;
        top: number;
        width: number;
        height: number;
        state: WindowState;
        priority: number;
    };
}


