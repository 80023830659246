import { Component } from '@angular/core';
import { EnvironmentService } from '@lightning/wild-environment';
import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';
import { ModalYesNoComponent } from 'apps/headquarter/src/app/apps/shared/components/modal-yes-no/modal-yes-no.component';

@Component({
    selector: 'app-operators',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent {

    private sortIndex = 0;

    constructor(
        public registerService: RegisterService,
        private environmentService: EnvironmentService) { }


    public sort(): void {

        if (++this.sortIndex > 1) {
            this.sortIndex = 0;
        }

        switch(this.sortIndex) {
            case 0:
                this.registerService.sortOperatorsByTeam();
            break;
            case 1:
                this.registerService.sortOperatorsByName();
            break;
        }
    }

    public async shuffle(): Promise<void> {

        const isConfirmed = await this.environmentService.modalOpen({
            title: 'remix operators',
            component: ModalYesNoComponent,
            inputs: {
                logo: 'assets/apps/register/logo.svg',
                description: 'Are you sure you want to remix the operators inside teams?',
            }
        });

        if (isConfirmed) {
            this.registerService.shuffleOperatorsInTeams();
        }
    }

    public async clear(): Promise<void> {

        const isConfirmed = await this.environmentService.modalOpen({
            title: 'clear operators',
            component: ModalYesNoComponent,
            inputs: {
                logo: 'assets/apps/register/logo.svg',
                description: 'Are you sure you want to clear all operators from the register?',
            }
        });

        if (isConfirmed) {
            this.registerService.clearOperators();
        }
    }

}
