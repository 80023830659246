import { Injectable } from '@angular/core';
import {
    ProductsWithSoftware,
    OperationWinner,
    OperationTrophy,
    OperationActions,
    PmrSounds,
    OperatorAvatarName,
    GroundLocations,
    OperatorUnderOperation,
    OperationTrophiesTypes,
    OperatorRoles,
} from '@lightning/lightning-definitions';
import {
    NfcOperatorData,
} from '@lightning/headquarter/app/apps/shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';

import {
    OperationRequierements,
    OperationRequierement,
} from '../../shared/interfaces/operation.interface';
import { RenderUtils } from '@lightning/wild-ui';


@Injectable({
    providedIn: 'root',
})
export class ContaminationService extends OperationBase {

    constructor() {
        super('contamination');

        // Define operation settings
        this.settings = {
            version: 1,

            name: '',
            timeLimit: 1800000,

            patientZero: ''
        };

        // Define organisator actions
        this.organisatorActions = [this.getOrganisatorActionNote()];

        // Subscribe to events
        this.loraProtocolService.onNfcOperatorReceive.subscribe(
            (data: NfcOperatorData) => {
                this.onNfcOperatorReceive(data);
            }
        );

        this.registerService.onOperatorRemoved.subscribe(
            (operator: OperatorUnderOperation) => {
                this.onOperatorRemoved(operator);
            }
        )
    }

    public override getRequierements(): OperationRequierements {
        const groundModules = this.loraProtocolService.getDevicesByProduct(ProductsWithSoftware.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                // 2 teams or more
                name: 'Count of operators 3+',
                value: this.registerService.operators.length,
                isReady: this.registerService.operators.length >= 3,
                help: '',
            },
            {
                // x ground modules
                name: 'Ground modules 1+',
                value: groundModules.length.toString(),
                isReady: groundModules.length > 0,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady =
            requierements.some((requierement) => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        if (!this.settings.patientZero) {
            return false;
        }

        return true;
    }

    public override async settingsApply(): Promise<void> {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // To prevent a ExpressionChangedAfterItHasBeenCheckedError with the Register app
        await RenderUtils.endOfStack();

        if(this.settings.patientZero) {

            const operator = this.registerService
                .getOperatorByFriendlyName(this.settings.patientZero);

            if (operator) {
                operator.role = OperatorRoles.Contamined;
                operator.data.stats = { isZero: true };
            }

            // Don't save it
            this.settings.patientZero = '';
        }

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {
        // [ CUSTOM LOGIC HERE ]

        return undefined;
    }

    public override getTrophies(): Array<OperationTrophy> {

        const trophies: Array<OperationTrophy> = [];

        // Active operators only
        const operators = this.registerService.operators
            .filter(operator => operator.data.stats);

        // 2 active operators or more
        if (operators.length < 2) {
            return trophies;
        }

        // Zero
        const operatorZero = operators
            .find(operator => operator.data.stats?.isZero);

        if (operatorZero) {
            trophies.push({
                type: OperationTrophiesTypes.ContaminationZero,
                data: {
                    operator: operatorZero
                }
            });
        }

        // First one
        const operatorFirstOne = operators
            .find(operator => operator.data.stats?.isFirst);

        if (operatorFirstOne) {
            trophies.push({
                type: OperationTrophiesTypes.ContaminationFirstOne,
                data: {
                    operator: operatorFirstOne
                }
            });
        }

        // Last one (can be undefined if the operation is manually completed before the end)
        const operatorLastOne = operators
            .find(operator => operator.data.stats?.isLast);

        if (operatorLastOne) {
            trophies.push({
                type: OperationTrophiesTypes.ContaminationLastOne,
                data: {
                    operator: operatorLastOne
                }
            });
        }

        return trophies;
    }

    public override start(): void {

        super.start();

        // Zero
        const operator = this.registerService.operators
            .find(operator => operator.role === OperatorRoles.Contamined);

        if (operator) {
            this.timelinePush({
                level: 1,
                action: OperationActions.OperatorContaminedZero,
                data: {
                    operator
                },
                customIconPath: this.onlineService
                    .getOperatorAvatarPath(operator?.number, OperatorAvatarName.Losing)
            });
        }

        // Mark others as survivors
        this.registerService.operators
            .map(operator => operator.role = operator.role || OperatorRoles.Survivor);
    }

    private async onNfcOperatorReceive(data: NfcOperatorData): Promise<void> {

        const { sender, operator } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Not a survivor
        if (operator.role !== OperatorRoles.Survivor) {
            return;
        }

        // Update the role
        operator.role = sender.name === GroundLocations.NeutralZone ?
            OperatorRoles.Deserter :    // As deserter in the neutral zone
            OperatorRoles.Contamined;   // As infected everywhere on the ground

        // Calculate the count of operators on the ground
        const operators = this.registerService.operators
            .filter(operator => operator.role !== OperatorRoles.Deserter);

        const operatorsCount = operators.length;

        // Calculate the count of survirors
        const survivors = this.registerService.operators
            .filter(operator => operator.role === OperatorRoles.Survivor);

        const survivorsCount = survivors.length;

        // Deserter
        if(operator.role === OperatorRoles.Deserter) {

            // Timeline push as contaminated
            this.timelinePush({
                level: 1,
                action: OperationActions.OperatorDeserted,
                data: {
                    operator,
                    survivorsCount,
                },
                customIconPath: this.onlineService
                    .getOperatorAvatarPath(operator.number, OperatorAvatarName.Neutral)
            });

        // Contamined
        } else {

            // Store data for trophies calculations
            const isFirst = (survivorsCount === operatorsCount - 2);
            const isLast = survivorsCount === 0;

            operator.data.stats = operator.data.stats || {};
            operator.data.stats.isFirst = isFirst;
            operator.data.stats.isLast = isLast;

            // Timeline push as contaminated
            this.timelinePush({
                level: 1,
                action: OperationActions.OperatorContamined,
                data: {
                    operator,
                    survivorsCount,
                    operatorsCount,
                    survivorsNames: this.getOperatorsNames(survivors)
                },
                customIconPath: this.onlineService
                    .getOperatorAvatarPath(operator.number, OperatorAvatarName.Losing)
            });

            // Announce via PMR
            this.pmrService.announce(
                    survivorsCount > 10 ?
                        PmrSounds.OperatorContamined1 :
                        (survivorsCount > 5 ?
                            PmrSounds.OperatorContamined2 :
                            PmrSounds.OperatorContamined3));
        }

        // No survivors: Complete the operation
        if (survivorsCount === 0) {
            this.complete();
        }
    }

    private onOperatorRemoved(operator: OperatorUnderOperation): void {

        // Not a survivor
        if(operator.role !== OperatorRoles.Survivor) {
            return;
        }

        // Get the survirors
        const survivors = this.registerService.operators
            .filter(operator => operator.role === OperatorRoles.Survivor);

        // Store data for trophies calculations
        const isLast = survivors.length === 1;

        survivors[0].data.stats = operator.data.stats || {};
        survivors[0].data.stats.isLast = isLast;

        // Timeline push as deserter
        this.timelinePush({
            level: 1,
            action: OperationActions.OperatorDeserted,
            data: {
                operator,
                survivorsCount: survivors.length
            },
            customIconPath: this.onlineService
                .getOperatorAvatarPath(operator.number, OperatorAvatarName.Neutral)
        });

        // No survivors: Complete the operation
        if (survivors.length === 0) {
            this.complete();
        }
    }

    private getOperatorsNames(operators: Array<OperatorUnderOperation>): string {

        let names = operators
            .slice(0, 3)
            .map(operator => this.registerService.getOperatorFriendlyName(operator))
            .join(', ');

        if(operators.length > 3) {
            names += `... +${operators.length - 3}`;
        }

        return names;
    }

}
