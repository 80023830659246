import { Component, OnInit } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';

import { DevicesComponent } from '../devices/devices.component';
import { LogsComponent } from '../logs/logs.component';
import { CommandsComponent } from '../commands/commands.component';
import { Tabs } from '@lightning/wild-ui';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';

// import { SimulationComponent } from '../simulation/simulation.component';
// import { SettingsComponent } from '../settings/settings.component';


@Component({
    selector: 'app-lora',
    templateUrl: './lora.component.html',
    styleUrls: ['./lora.component.scss']
})
export class LoraComponent implements OnInit {

    public tabs: Tabs = {
        items: [
            { selector: 'devices', text: 'devices', component: DevicesComponent },
            { selector: 'logs', text: 'logs', component: LogsComponent },
            { selector: 'commands', text: 'commands', component: CommandsComponent },
            // {selector : 'simulation', text: 'simulation', component: SimulationComponent},
            // {selector : 'settings',   text: 'settings',   component: SettingsComponent}

        ]
    };

    constructor(
        public environmentService: EnvironmentService,
        public gatewayService: GatewayService) { }

    ngOnInit() {

        this.tabs.selected = this.tabs.items[0];

        this.gatewayService.connect();
    }

}
