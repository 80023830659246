import { Component } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';
import { OperationStates } from '../../../shared/enums/operation.enums';

import { SandboxService } from '../../services/sandbox.service';
import { OnlineService } from '@lightning/lightning-services';

@Component({
    selector: 'app-sandbox-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss']
})
export class ProcessComponent {

    OperationStates = OperationStates;

    constructor(public environmentService: EnvironmentService,
                public registerService: RegisterService,
                public onlineService: OnlineService,
                public sandboxService: SandboxService) { }

}
