import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modal-input',
    templateUrl: './modal-input.component.html',
    styleUrls: ['./modal-input.component.scss']
})

export class ModalInputComponent {

    @Input()
    public logo = '';

    @Input()
    public description = '';

    @Input()
    public type = '';

    @Input()
    public min: number | undefined;

    @Input()
    public max: number | undefined;

    @Input()
    public value: any;

    @Input()
    public placeholder = '';

    @Input() resolve = (item: any = null) => { /* */ };

    constructor(
        public translateService: TranslateService,
        private environmentService: EnvironmentService) { }

    public confirmClick(): void {

        this.resolve(this.value);

        this.environmentService.modalClose();
    }
}
