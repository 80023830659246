export class ColorUtils {

    public static saturate(hex: string, saturation: number): string {

        if (!/^#([0-9a-f]{6})$/i.test(hex)) {
            throw('Unexpected color format');
        }

        if (saturation < 0 || saturation > 100) {
            throw('Unexpected color format');
        }

        // const saturationFloat = saturationPercent / 100;

        const rgbIntensities = [
                parseInt(hex.substring(1, 3), 16) / 255,
                parseInt(hex.substring(3, 5), 16) / 255,
                parseInt(hex.substring(5, 7), 16) / 255
            ];

        const rgbIntensitiesSorted = rgbIntensities
            .slice(0).sort((a, b) => a - b);

        const intensity = {
            max:    rgbIntensitiesSorted[2],
            medium: rgbIntensitiesSorted[1],
            min:    rgbIntensitiesSorted[0],
        };

        // All colors have same intensity, which means
        // the original color is gray, so we can't change saturation.
        if (intensity.max == intensity.min) {
            return hex;
        }

        const intensityAdjusted = {
            medium: 0,
            min:    intensity.max * (1 - saturation / 100),
        };


        if (intensity.medium == intensity.min) {

            // Weak colors have equal intensity.
            intensityAdjusted.medium = intensityAdjusted.min;
        }
        else {
            // Calculate medium intensity with respect to original intensity proportion.
            const intensityProportion = (intensity.max - intensity.medium) / (intensity.medium - intensity.min);

            intensityAdjusted.medium = (intensityProportion * intensityAdjusted.min + intensity.max) / (intensityProportion + 1);
        }

        const newRGB: Array<number> = [];

        const newRgbIntensitySorted = [
            intensityAdjusted.min,
            intensityAdjusted.medium,
            intensity.max
        ];

        // We've found new intensities, but we have then sorted from min to max.
        // Now we have to restore original order.
        rgbIntensities.forEach((originalRgb) => {
            newRGB.push(newRgbIntensitySorted[rgbIntensitiesSorted.indexOf(originalRgb)]);
        });

        return ColorUtils.rgb2hex(newRGB);
    }

    public static rgb2hex(rgb: Array<number>): string {
        return '#' + ColorUtils.floatToHex(rgb[0]) + ColorUtils.floatToHex(rgb[1]) + ColorUtils.floatToHex(rgb[2]);
    }

    public static textToColor(text: string, options?: { brightness: 'light' | 'dark' }): string {

        if (text.length < 6) {
            text = text + text + text; // Helps to generate different colors with very short texts
        }

        let hash = 0;
        for (let i = 0; i < text.length; i++) {
            hash = text.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';
        for (let i = 0; i < 3; i++) {

            let value = (hash >> (i * 8)) & 0xFF;

            if (options?.brightness === 'light' && value < 0x80) {
                value = 0xFF - value;
            } else if (options?.brightness === 'dark' && value > 0x80) {
                value = value - 0x80;
            }

            color += value.toString(16).padEnd(2, '0');
        }

        return color;
    }



    private static floatToHex(value: number): string {
        return ('0' + Math.round(value * 255).toString(16)).substring(-2);
    }
}

