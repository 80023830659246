import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService, ScreenService } from '@lightning/wild-environment';


import { AboutComponent } from './about/components/about/about.component';
import { AboutModule } from './about/about.module';
import { BotComponent } from './bot/components/bot/bot.component';
import { BotModule } from './bot/bot.module';
import { ExternalComponent } from './external/components/external/external.component';
import { ExternalModule } from './external/external.module';
import { GatewayComponent } from './gateway/components/gateway/gateway.component';
import { GatewayModule } from './gateway/gateway.module';
import { LoraComponent } from './lora/components/lora/lora.component';
import { LoraModule } from './lora/lora.module';
import { NgModule } from '@angular/core';
import { OnlineComponent } from './online/components/online/online.component';
import { OnlineModule } from './online/online.module';
import { OperationsComponent } from './operations/components/operations/operations.component';
import { OperationsModule } from './operations/operations.module';
import { PhotographiesComponent } from './photographies/components/photographies/photographies.component';
import { PhotographiesModule } from './photographies/photographies.module';
import { PmrComponent } from './pmr/components/pmr/pmr.component';
import { PmrModule } from './pmr/pmr.module';
import { RegisterComponent } from './register/components/register/register.component';
import { RegisterModule } from './register/register.module';
import { ReportsComponent } from './reports/components/reports/reports.component';
import { ReportsModule } from './reports/reports.module';
import { SandboxModule } from './sandbox/sandbox.module';
import { UpdatesComponent } from './updates/components/about/updates.component';
import { SettingsComponent } from './settings/components/settings/settings.component';
import { SettingsModule } from './settings/settings.module';
import { UpdatesModule } from './updates/updates.module';
import { SettingsService } from './shared/services/settings/settings.service';
import { SimulationComponent } from './simulation/components/simulation/simulation.component';
import { SimulationModule } from './simulation/simulation.module';

import { DiagnosticModule } from './diagnostic/diagnostic.module';
import { DiagnosticComponent } from './diagnostic/components/diagnostic/diagnostic.component';


@NgModule({
    declarations: [],
    imports: [
        AboutModule,
        ExternalModule,
        OnlineModule,
        LoraModule,
        PmrModule,
        OperationsModule,
        RegisterModule,
        ReportsModule,
        SandboxModule,
        PhotographiesModule,
        GatewayModule,
        BotModule,
        SimulationModule,
        UpdatesModule,
        SettingsModule,
        DiagnosticModule,
    ],
    exports: [],
    providers: [],
})


export class AppsModule {

    constructor(
        environmentService: EnvironmentService,
        screenService: ScreenService,
        translateService: TranslateService,
        settingsService: SettingsService) {

        translateService.onLangChange.subscribe(() => {
            environmentService.appRegister([

                {
                    id: 'gateway',
                    name:  translateService.instant('apps.gateway.name'),
                    description: translateService.instant('apps.gateway.description'),
                    component: GatewayComponent,
                    mobile: {

                    },
                    desktop: {
                        single: true,
                        limits: {}
                    },
                    argument: {}
                },
                {
                    id: 'lora',
                    name:  translateService.instant('apps.lora.name'),
                    description: translateService.instant('apps.lora.description'),
                    component: LoraComponent,
                    mobile: {

                    },
                    desktop: {
                        single: false,
                        limits: {}
                    },
                    argument: {}
                },
                {
                    id: 'register',
                    name:  translateService.instant('apps.register.name'),
                    description: translateService.instant('apps.register.description'),
                    component: RegisterComponent,
                    mobile: {

                    },
                    desktop: {
                        single: true,
                        limits: {}
                    },
                    argument: {}
                },
                {
                    id: 'operations',
                    name:  translateService.instant('apps.operations.name'),
                    description: translateService.instant('apps.operations.description'),
                    component: OperationsComponent,
                    mobile: {

                    },
                    desktop: {
                        single: true,
                        limits: {}
                    },
                    argument: {}
                },
                {
                    id: 'reports',
                    name:  translateService.instant('apps.reports.name'),
                    description: translateService.instant('apps.reports.description'),
                    component: ReportsComponent,
                    mobile: {

                    },
                    desktop: {
                        single: true,
                        limits: {}
                    },
                    argument: {}
                },
                {
                    id: 'simulation',
                    name:  translateService.instant('apps.simulation.name'),
                    description: translateService.instant('apps.simulation.description'),
                    component: SimulationComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {}
                },
                {
                    id: 'updates',
                    name:  translateService.instant('apps.updates.name'),
                    description: translateService.instant('apps.updates.description'),
                    component: UpdatesComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {}
                },
                {
                    id: 'settings',
                    name:  translateService.instant('apps.settings.name'),
                    description: translateService.instant('apps.settings.description'),
                    component: SettingsComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {}
                },

                // Hidden apps
                {
                    id: 'diagnostic',
                    name:  translateService.instant('apps.diagnostic.name'),
                    description: translateService.instant('apps.diagnostic.description'),
                    component: DiagnosticComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {},
                    isHidden: true
                },
                {
                    id: 'online',
                    name: 'online',
                    description: 'online services',
                    component: OnlineComponent,
                    mobile: {},
                    desktop: {
                        single: true,
                        limits: {
                            minWidth: 40,
                            minHeight: 40
                        }
                    },
                    argument: {},
                    isHidden: true
                },




                // {
                //     id: 'website',
                //     name: 'website',
                //     description: 'lightning website',
                //     component: ExternalComponent,
                //     mobile: {

                //     },
                //     desktop: {
                //         single: false,
                //         limits: {}
                //     },
                //     argument: { url: environment.website.url }
                // },
                // {
                //     id: 'sandbox',
                //     name: 'sandbox',
                //     description: 'sandbox for development',
                //     component: SandboxComponent,
                //     mobile: {

                //     },
                //     desktop: {
                //         single: true,
                //         limits: null
                //     },
                //     argument: {}
                // },
                // {
                //     id: 'about',
                //     name: 'about',
                //     description: 'about this software',
                //     component: AboutComponent,
                //     mobile: {},
                //     desktop: {
                //         single: true,
                //         limits: {
                //             minWidth: 40,
                //             minHeight: 40
                //         }
                //     },
                //     argument: {}
                // },
            ]);


            if (settingsService.isDeveloppersModeEnabled) {

                environmentService.appRegister([
                    {
                        id: 'photographies',
                        name: 'photographies',
                        description: 'photographies tools',
                        component: PhotographiesComponent,
                        mobile: {},
                        desktop: {
                            single: true,
                            limits: {
                                minWidth: 40,
                                minHeight: 40
                            }
                        },
                        argument: {}
                    },
                    {
                        id: 'pmr',  // RELEASE WHEN THE SOUND BOX WILL BE AVAILABLE
                        name:  translateService.instant('apps.pmr.name'),
                        description: translateService.instant('apps.pmr.description'),
                        component: PmrComponent,
                        mobile: {

                        },
                        desktop: {
                            single: false,
                            limits: {}
                        },
                        argument: {}
                    },
                    // {
                    //     id: 'bot',
                    //     name: 'nfc bot',
                    //     description: 'physical nfc testing',
                    //     component: BotComponent,
                    //     mobile: {

                    //     },
                    //     desktop: {
                    //         single: false,
                    //         limits: {}
                    //     },
                    //     argument: {}
                    // },
                ]);
            }
        });

        environmentService.sessionReady.subscribe(() => {

            const starts = parseInt(localStorage.getItem('starts') || '0');

            // Open the simulation app for its tutorial on the first start on a desktop
            if(starts === 0 && screenService.isDesktop) {
                environmentService.windowOpenByAppId('simulation', { tab: 'tutorial' });
            }

            localStorage.setItem('starts', (starts + 1).toString());

        });

    }
}
