import { Component, OnInit } from '@angular/core';
import { PmrService } from 'apps/headquarter/src/app/apps/shared/services/pmr/pmr.service';

@Component({
    selector: 'app-fx',
    templateUrl: './fx.component.html',
    styleUrls: ['./fx.component.scss']
})
export class FxComponent implements OnInit {

    public track: number = 1;

    constructor(public pmrService:PmrService) { }

    ngOnInit(): void {
    }

    public setTrack(track: number): void {

        this.track = track;
    }

    public play() {

        // this.pmrService.play(this.track.toString());
    }

}
