import { Component, OnInit } from '@angular/core';
import { WarmupService } from '../../services/warmup.service';

@Component({
    selector: 'app-warmup-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
    constructor(public warmupService: WarmupService) {}

    ngOnInit(): void {}
}
