import { Component, HostBinding, Input } from '@angular/core';

@Component({
    selector: 'app-disclamer',
    templateUrl: './disclamer.component.html',
    styleUrls: ['./disclamer.component.scss']
})
export class DisclamerComponent {

    @Input()
    public set level(value: string) {
        this.color = getComputedStyle(document.body).getPropertyValue(`--color-${value}`);
    }

    @Input()
    public set isInteractive(value: boolean) {
        this.cursor = value ? 'pointer' : 'default';
    }

    @HostBinding('style.background-color')
    public color = '';

    @HostBinding('style.cursor')
    private cursor = 'default';
}
