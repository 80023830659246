<app-body>
    <wui-section [name]="'apps.photographies.sectionCurrentSession' | translate">

        <wui-entry>
            <div name>Session</div>
            <div value> {{ session }} </div>
        </wui-entry>

        <wui-entry>
            <div name>Sync code plain text</div>
            <div value> {{ syncCode }} </div>
        </wui-entry>

        <wui-entry>
            <div name>Sync code</div>
            <qr-code value [value]="syncCode" size="200" errorCorrectionLevel="M"></qr-code>
        </wui-entry>

    </wui-section>
</app-body>
<!-- <app-footer>
    <wui-buttons>
        <wui-button>...</wui-button>
    </wui-buttons>
</app-footer> -->


