import { Component, OnInit, Input } from '@angular/core';
import { App, Window } from '@lightning/wild-environment';
import { Tabs } from '@lightning/wild-ui';

import { QRCodeComponent } from '../qrcode/qrcode.component';

@Component({
  selector: 'app-photographies',
  templateUrl: './photographies.component.html',
  styleUrls: ['./photographies.component.scss']
})

export class PhotographiesComponent implements App, OnInit {

    @Input()
    public window: Window | undefined;

    public tabs: Tabs = {
        items: [
            { selector: 'qrcode', text: 'qrcode', component: QRCodeComponent },

            //... other childs for other tabs ...
        ]
    };

    constructor() { }

    ngOnInit() {

        this.tabs.selected = this.tabs.items[0];
    }

}
