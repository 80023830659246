<wui-section [name]="'shared.operators' | translate">

    <wui-entry>
        <div name>{{ 'apps.simulation.simulator.scene.operatorsCount' | translate }}</div>
        <wui-input value
            type="number"
            [min]="0"
            [max]="maxCount"
            [(value)]="count"></wui-input>
    </wui-entry>

    <wui-entry>
        <div name></div>
        <wui-button value (click)="run()">{{ 'shared.generate' | translate }}</wui-button>
    </wui-entry>

</wui-section>
