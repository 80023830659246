import { Component, Input } from '@angular/core';

import { App, Window } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';

@Component({
    selector: 'app-online',
    templateUrl: './online.component.html',
    styleUrls: ['./online.component.scss']
})

export class OnlineComponent implements App {

    @Input()
    public window: Window | undefined;

    constructor(public onlineService: OnlineService) { }
}
