import { Component, OnInit, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { Modal } from '../../interfaces/modal.interface';
import { EnvironmentService } from '../../services/environment/environment.service';

@Component({
    selector: 'wild-modal',
    templateUrl: './modal.component.html',
    styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {

    @Input()
    public data: Modal | undefined;

    @ViewChild('content', { static: true, read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef | undefined;

    constructor(private environmentService: EnvironmentService) { }

    ngOnInit(): void {

        if(!this.data || !this.viewContainerRef) {
            return;
        }

        // Create component instance
        const reference = this.viewContainerRef.createComponent(this.data.component);

        // Assigning inputs
        for (const key in this.data.inputs) {
            (reference.instance as any)[key] = this.data.inputs[key];
        }

        // Insert to the DOM
        this.viewContainerRef.insert(reference.hostView);

        // Update
        reference.changeDetectorRef.detectChanges();
    }

    public close(): void {
        this.environmentService.modalClose();
    }

}
