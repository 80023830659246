import { Component, OnInit } from '@angular/core';
import { DestructionService } from '../../services/destruction.service';

@Component({
  selector: 'app-destruction-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

  constructor(public destructionService: DestructionService) { }

  ngOnInit(): void {
  }

}
