import { EventEmitter, Injectable } from '@angular/core';
import { PmrSounds, TeamUnderOperation } from '@lightning/lightning-definitions';

import { GatewayCommandType, GatewayService } from '../gateway/gateway.service';
import { SettingsService } from '../settings/settings.service';

class PMRState {
    playQueue = 0;
    isTransmitting = false;
    isPlaying = false;
    txChannel = '';
    rxChannel = '';
}

@Injectable({
    providedIn: 'root'
})

export class PmrService {

    public onTransmittingChange = new EventEmitter<PMRState>();
    //public onStateChange = new EventEmitter<any>();

    private _state = new PMRState();

    constructor(
        private settingsService: SettingsService,
        private gatewayService: GatewayService,) {

        // PMR data from the ground service
        this.gatewayService.on('pmr', (data: PMRState) => this.onReceive(data));
    }

    public get broadcastChannel(): string {

        return this.settingsService.settings.pmr.broadcastChannel;
    }


    /**
     * Return true if PRM features are available
     */
    // public get isAvailable(): boolean {

    //     return this._state != {};
    // }


    /**
     * Start transmission (equals to a PTT pressing)
     * @param channel Destination channel between 0 and 15
     */

    public startTransmission(channel: string | undefined = undefined): void {

        channel = channel || this.txChannel;

        this.send('startTx ' + channel);
    }

    /**
     * End the transmission (equals to a PTT release)
     */
    public endTransmission(): void {

        this.send('endTx');
    }

    /**
     * Return true if is currently transmiting
     */
    public get isTransmitting(): boolean {

        return this._state.isTransmitting === true;
    }

    public get state(): PMRState {

        return this._state;
    }


    /**
     * Get the rx channel
     */
    public get rxChannel(): string {

        return this._state.rxChannel || '';
    }

    /**
     * Set the rx channel
     */
    public set rxChannel(channel: string) {

        this.send('setRxChannel ' + channel);
    }

    /**
     * Get the rx channel
     */
    public get txChannel(): string {

        return this._state.txChannel || '';
    }

    /**
     * Set the rx channel
     */
    public set txChannel(channel: string) {

        this.send('setTxChannel ' + channel);
    }



    /**
     * Play a sound stored in the SD card of the gateway to a specific team of the broadcast channel
     * @param sound Name of the stored sound to play
     * @param team Destination team
     */
    public announce(sound: PmrSounds, team: TeamUnderOperation | undefined = undefined): void {

        console.log('PmrService: Announce', PmrSounds[sound]);

        this.play(sound, team ? team.pmr : this.broadcastChannel);
    }



    /**
     * Play a sound stored in the SD card of the gateway
     * @param sound Name of the stored sound to play
     * @param channel Destination channel between 0 and 15 (optional)
     */
    public play(sound: PmrSounds, channel: string): void {

        console.log('PmrService: Play', PmrSounds[sound], 'on the channel', channel);

        this.send('play ' + PmrSounds[sound] + ' ' + channel);
    }

    /**
     * Stop all sounds
     */
    public stop(): void {

        console.log('PmrService: Stop');

        this.send('stop');
    }

    /**
     * Play to stream over Bluetooth a sound to the gateway
     * @param path Path of the sound to play
     * @param channel Destination channel between 0 and 15 (optional)
     */
    public stream(path: string, channel: string): void {

        // TODO:
        //  - Manage a queue
        //  - Call startTransmission
        //  - Play the sound
        //  - Call end transmission
    }

    /**
     * Receive PMR data from the gateway
     * @param state Received data.
     */
    private onReceive(state: PMRState): void {

        console.log('PmrService: Receive:', state);

        this._state = Object.assign(this._state, state);

        // Transmitting changed
        if ('isTransmitting' in state) {
            this.onTransmittingChange.emit(this._state);
        }

        //this.onStateChange.emit(this.state);
    }

    /**
     * Send data
     * @param command Command to send
     * @returns The token of the transaction
     */
    public send(command: string): number {

        return this.gatewayService.send(GatewayCommandType.SendPMR, command);
    }
}
