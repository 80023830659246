import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { SimulationComponent } from './components/simulation/simulation.component';
import { TutorialComponent } from './components/tutorial/tutorial.component';
import { ActionComponent } from './components/action/action.component';
import { SimulatorComponent } from './components/simulator/simulator.component';
import { SceneComponent } from './components/scene/scene.component';
import { OperatorsComponent } from './components/operators/operators.component';
import { DevicesComponent } from './components/devices/devices.component';


@NgModule({
  declarations: [
    SimulationComponent,
    TutorialComponent,
    ActionComponent,
    SimulatorComponent,

    SceneComponent,
    OperatorsComponent,
    DevicesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    SimulationComponent
  ]
})

export class SimulationModule { }
