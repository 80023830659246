import { Component, OnInit } from '@angular/core';
import { LootingService } from '../../services/looting.service';

@Component({
  selector: 'app-looting-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

  constructor(public lootingService: LootingService) { }

  ngOnInit(): void {
  }

}
