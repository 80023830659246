<app-container>

    <app-header logo="assets/apps/simulation/logo.svg"
                [description]="'apps.simulation.headerDescription' | translate | capitalize">
    </app-header>

    <wui-tabs [tabs]="tabs"></wui-tabs>

    <ng-container *ngIf="tabs.selected">
        <ng-container *ngComponentOutlet="tabs.selected.component"></ng-container>
    </ng-container>

</app-container>
