import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ScreenService {

    get isMobile() {
        return window.matchMedia('screen and (max-width:1000px), (any-pointer: coarse)').matches;
    }

    get isDesktop() {
        return !this.isMobile;
    }

    public get isInstalled() {
        return window.matchMedia('(display-mode: browser)').matches == false;
    }

    public enterFullscreen() {
        document.documentElement.requestFullscreen();
    }

    public exitFullscreenCancel() {
        document.exitFullscreen();
    }

    public toggleFullscreen() {

        if (this.isFullscreen) {
            this.exitFullscreenCancel();
        } else {
            this.enterFullscreen();
        }
    }

    public get isFullscreen() {
        return document.fullscreenElement != null;
    }

    public async capture(scale = 1, options?: DisplayMediaStreamOptions): Promise<string> {

        let stream: MediaStream | undefined;

        try {

            // Get the stream asking the user
            stream = await navigator.mediaDevices.getDisplayMedia(options);

            if (stream.getVideoTracks().length === 0) {
                throw 'No tracks';
            }

            const track = stream.getVideoTracks()[0];

            // Create a video element using the stream
            const video = document.createElement('video');
            video.srcObject = stream;
            video.autoplay = true;

            // Await the video is ready
            await new Promise((resolve, reject) => {
                video.onloadeddata = resolve;
                video.onerror = reject;
            });

            // Create a canvas to receive the frame
            const   canvas = document.createElement('canvas')
                    canvas.width =  (track.getSettings().width || window.innerWidth) * scale;
                    canvas.height = (track.getSettings().height || window.innerHeight) * scale;

            const context = canvas.getContext('2d');

            if (!context) {
                throw 'No context';
            }

            // Draw the video into the canvas
            context.scale(scale, scale);
            context.drawImage(video, 0, 0);

            // Return the canvas as base64 image
            return canvas.toDataURL();

        } catch(exception) {

            console.error(`ScreenService: ${exception}`);

            return '';

        } finally {

            // Stop the tracks in any case
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
            }
        }
    }

}
