import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';

import { WindowComponent } from './components/window/window.component';
import { EnvironmentComponent } from './components/environment/environment.component';
import { ModalComponent } from './components/modal/modal.component';
import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        WildUiModule
    ],
    declarations: [
        EnvironmentComponent,
        WindowComponent,
        ModalComponent
    ],
    exports: [
        EnvironmentComponent
    ]
})
export class WildEnvironmentModule { }
