import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { LightningServicesModule } from '@lightning/lightning-services';
import { LightningUiModule } from '@lightning/lightning-ui';
import { WildUiModule } from '@lightning/wild-ui';

import { BodyComponent } from './components/body/body.component';
import { ContainerComponent } from './components/container/container.component';
import { DisclamerComponent } from './components/disclamer/disclamer.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { InstructorComponent } from './components/instructor/instructor.component';
import { ModalAlertComponent } from './components/modal-alert/modal-alert.component';
import { ModalCardsComponent } from './components/modal-cards/modal-cards.component';
import { ModalInputComponent } from './components/modal-input/modal-input.component';
import { ModalListComponent } from './components/modal-list/modal-list.component';
import { ModalTeamAssignComponent } from './components/modal-team-assign/modal-team-assign.component';
import { ModalTeamScoreAdjustComponent } from './components/modal-team-score-adjust/modal-team-score-adjust.component';
import { ModalTextareaComponent } from './components/modal-textarea/modal-textarea.component';
import { ModalYesNoComponent } from './components/modal-yes-no/modal-yes-no.component';
import { ModalWifiCredentialsComponent } from './components/modal-wifi-credentials/modal-wifi-credentials.component';
import { ModalOnlineLoginComponent } from './components/modal-online-login/modal-online-login.component';

import { UsersOnlyDirective } from './directives/users-only/users-only.directive';
import { DeveloppersOnlyDirective } from './directives/developpers-only/developpers-only.directive';


@NgModule({
    providers: [
        DatePipe
    ],
    declarations: [
        ContainerComponent,
        HeaderComponent,
        BodyComponent,
        FooterComponent,
        DisclamerComponent,
        ModalAlertComponent,
        ModalYesNoComponent,
        ModalInputComponent,
        ModalTextareaComponent,
        ModalListComponent,
        ModalCardsComponent,
        ModalTeamAssignComponent,
        ModalTeamScoreAdjustComponent,
        ModalWifiCredentialsComponent,
        ModalOnlineLoginComponent,
        InstructorComponent,

        DeveloppersOnlyDirective,
        UsersOnlyDirective,
    ],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        WildUiModule,
        LightningUiModule,
        LightningServicesModule/*.forRoot(environment) uses automatic backend's url now */,
    ],
    exports: [
        TranslateModule,
        WildUiModule,
        LightningUiModule,

        ContainerComponent,
        HeaderComponent,
        BodyComponent,
        FooterComponent,
        DisclamerComponent,
        ModalAlertComponent,
        ModalYesNoComponent,
        ModalInputComponent,
        ModalTextareaComponent,
        ModalListComponent,
        ModalCardsComponent,
        ModalTeamAssignComponent,
        ModalTeamScoreAdjustComponent,
        ModalWifiCredentialsComponent,
        ModalOnlineLoginComponent,
        InstructorComponent,

        DeveloppersOnlyDirective,
        UsersOnlyDirective,
    ],
})
export class SharedModule { }
