import { Component, Input } from '@angular/core';

import { App, Window, EnvironmentService } from '@lightning/wild-environment';
import { OperationTrophy } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';

import { ReportsService } from 'apps/headquarter/src/app/apps/shared/services/reports/reports.service';
import { ModalOnlineLoginComponent } from '../../../shared/components/modal-online-login/modal-online-login.component';

@Component({
    selector: 'app-operation-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements App {

    @Input()
    public window: Window | undefined;

    constructor(
        public environmentService: EnvironmentService,
        public onlineService: OnlineService,
        public reportsService: ReportsService) { }

    public trophyClick(trophy: OperationTrophy): void { /** */ }

    public loginOnline(): void {

        this.environmentService.modalOpen({ title: '', component: ModalOnlineLoginComponent });
    }
}
