import { Component } from '@angular/core';
import { GroundLocations, MinimalCompatibleVersions, ProductsWithSoftware, SementicVersion, SementicVersionUtils } from '@lightning/lightning-definitions';
import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';

@Component({
    selector: 'app-simulation-devices',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss']
})
export class DevicesComponent {

    public count = 3;
    public maxCount = Object.keys(GroundLocations).length;

    constructor(
        private loraProtocolService: LoraProtocolService) { }

    public run(): void {

        this.loraProtocolService.clearDevices();

        if (this.count > Object.keys(GroundLocations).length) {
            this.count = Object.keys(GroundLocations).length;
        }

        for (let index = 0; index < this.count; index++) {

            const id = `virtual-device-${(index + 1).toString(10).padStart(3, '0')}`;

            const device = this.loraProtocolService.addDevice(id, ProductsWithSoftware.GroundModule, true);

            device.name = Object.values(GroundLocations)[index];
            device.updated = new Date();
            device.isUsable = true;

            if(device.state) {

                device.state.battery = 100;
                device.state.software = MinimalCompatibleVersions.GroundModule.replace('X', '0'); // You can play setting an older version here, eg: = '1.0.0'

                const currentSementicVersion = SementicVersionUtils.parse(device.state.software);
                const minimalSementicVersion = SementicVersionUtils.parse(MinimalCompatibleVersions.GroundModule);

                if (currentSementicVersion && minimalSementicVersion) {
                    device.isCompatible = SementicVersionUtils.isCompatible(currentSementicVersion, minimalSementicVersion);
                }
            }
        }
    }
}
