import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';

import { SharedModule } from '../shared/shared.module';

import { DiagnosticComponent } from './components/diagnostic/diagnostic.component';

@NgModule({
    declarations: [
        DiagnosticComponent,
    ],
    imports: [
        CommonModule,
        WildUiModule,
        SharedModule,
    ],
    exports: [
        DiagnosticComponent
    ]
})

export class DiagnosticModule { }
