import { EventEmitter, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { SoftwaresChannels } from '@lightning/lightning-definitions';
import { EnvironmentService } from '@lightning/wild-environment';

import { ElectronService } from '../electron/electron.service';

import { GatewayConnectorsNames } from '../../enums/gateway.enum';
import { LongRangeProtocolsNames } from '../../enums/lora.enum';
import { ModalWifiCredentialsComponent } from '../../components/modal-wifi-credentials/modal-wifi-credentials.component';


const STORAGE_NAME = 'settings';
const STORAGE_VERSION = 10;

export class SettingsOperations {

    public timeForDeployment = 300000;

    public timeLimit = 1800000;

    public isBriefingAnnounceEnabled = false;

    public isDeployingAnnounceEnabled = false;
}

export class SettingsGateway {

    public connector = GatewayConnectorsNames.Serial;

    public websocketUrl = 'ws://localhost:8888';
}

export class SettingsPMR {

    public broadcastChannel = '8.10';
}

export class SettingsLongRange {

    public protocol = LongRangeProtocolsNames.Comptact;
}

export class SettingsWifi {

    public ssid = '';

    public password = '';
}

export class SettingsAdvanced {

    public developpersMode = false;

    public developpersToolsElectron = false;

    public updatesChannel = SoftwaresChannels.Main;
}

export class SettingsWithoutSensitive {

    public version = STORAGE_VERSION;

    public operations = new SettingsOperations();

    public gateway = new SettingsGateway();

    public longRange = new SettingsLongRange();

    public pmr = new SettingsPMR();

    public advanced = new SettingsAdvanced();
}

export class Settings extends SettingsWithoutSensitive {

    public wifi = new SettingsWifi();

    // Add sensitive settings here
}



@Injectable({
    providedIn: 'root'
})

export class SettingsService {

    public settings = new Settings();

    public onSaved = new EventEmitter<Settings>();

    constructor(
        private electronServive: ElectronService,
        private environmentService: EnvironmentService,
        private translateService: TranslateService) {

        this.load();
    }

    public get settingsWithoutSensitive(): SettingsWithoutSensitive {

        const { wifi, ...settings } = this.settings;

        return settings;
    }

    public load(): void {

        const stored = localStorage.getItem(STORAGE_NAME);

        if (!stored) {
            return;
        }

        const data = JSON.parse(stored);

        if (data.version !== STORAGE_VERSION) {

            this.reset();

            return;
        }

        this.settings = data.settings;

        console.log('Settings service : Loaded'/*, data.values*/);

        this.apply();
    }

    public save(): void {

        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            version: STORAGE_VERSION,
            settings: this.settings
        }));

        this.onSaved.emit(this.settings);

        console.log('Settings service : Saved'/*, this.settings*/);

        this.apply();
    }

    public reset(): void {

        this.settings = new Settings();

        this.save();
    }

    public async getOrRequestWifiCredentials(): Promise<SettingsWifi | null> {

        // Get stored
        if (this.settings.wifi.ssid) {
            return this.settings.wifi;
        }

        // Or ask
        return this.requestWifiCredentials();
    }

    public async requestWifiCredentials(): Promise<SettingsWifi | null> {

        // Ask
        const result = await this.environmentService.modalOpen({
            component: ModalWifiCredentialsComponent,
            title: this.translateService.instant('apps.settings.generalities.wifi.title'),
            inputs: {
                credentials: structuredClone(this.settings.wifi)
            }
        });

        // Asking canceled
        if(!result) {
            return null;
        }

        // Save and get asked
        this.settings.wifi = result;

        this.save();

        return this.settings.wifi;
    }


    private apply(): void {

        this.electronServive.send('developpersTools', this.settings.advanced.developpersToolsElectron);

        console.log('Settings service : Applied');
    }


    // -----------------------------------------------------------------------------------
    // Shortcuts

    public get isDeveloppersModeEnabled(): boolean {

        return this.settings.advanced.developpersMode;
    }

}
