import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChildren,
    ViewChild,
    ViewContainerRef,
    AfterViewInit
} from '@angular/core';

import { MaximizableDirective } from '@lightning/wild-ui';

import { Window } from '../../interfaces/window.interface';
import { EnvironmentService } from '../../services/environment/environment.service';

const DELAY_BEFORE_ANCHORS_DISPLAYING = 1000;

@Component({
    selector: 'wild-window',
    templateUrl: './window.component.html',
    styleUrls: ['./window.component.scss']
})

export class WindowComponent implements AfterViewInit {

    @Input({ required: true })
    public data: Window | undefined;

    @Output()
    public changed = new EventEmitter();

    @Output()
    public closing = new EventEmitter<boolean>();

    public isAnchorsVisible = false;

    public oneThiers = 100 / 3;
    public twoThiers = (100 / 3) * 2;

    @ViewChildren(MaximizableDirective)
    private maximizableDirective: any;

    @ViewChild('content', { read: ViewContainerRef })
    private viewContainerRef: ViewContainerRef | undefined;

    private anchorDisplayTimer: any = undefined;

    constructor(private environmentService: EnvironmentService) { }

    public ngAfterViewInit(): void {

        if(!this.data || !this.viewContainerRef) {
            return;
        }

        // Merge default limits with the ones of the manifest for resizing
        this.data.manifest.desktop.limits = Object.assign(this.data.manifest.desktop.limits, {
            minWidth: 250,
            minHeight: 250
        });

        // Create component instance
        const reference = this.viewContainerRef.createComponent(this.data.manifest.component);

        // Give it some information using its inputs
        (reference.instance as any).window = this.data;

        this.viewContainerRef.insert(reference.hostView);

        reference.changeDetectorRef.detectChanges();
    }

    public get isMaximized(): boolean {

        return this.maximizableDirective?.first?.isMaximized;
    }

    public minimize(): void {

        if(!this.data) {
            return;
        }

        this.environmentService.windowMinimize(this.data);
    }

    public toggleSize(): void {

        // Call directly a function of a directive!
        this.maximizableDirective.first.toggleSize();

        clearTimeout(this.anchorDisplayTimer);
    }

    public openAnchors(): void {

        this.anchorDisplayTimer = setTimeout(() => this.isAnchorsVisible = true, DELAY_BEFORE_ANCHORS_DISPLAYING);
    }

    public cancelOpenAnchors(): void {

        clearTimeout(this.anchorDisplayTimer);
    }

    public setAnchor(left: number, top: number, width: number, height: number): void {

        if(!this.data) {
            return;
        }

        // Call directly a function of a directive!
        this.maximizableDirective.first.setNormalSize();

        this.data.display = { ...this.data.display, left, top, width, height };

        this.isAnchorsVisible = false;

        this.changed.emit();
    }

    public closeAnchors(): void {
        this.isAnchorsVisible = false;
    }

    public focus(): void {

        if(!this.data) {
            return;
        }

        this.environmentService.windowFocus(this.data);
    }

    public close(): void {

        this.closing.emit();
    }

    public dragStop(location: any): void { ///

        if(!this.data) {
            return;
        }

        // Update display data
        this.data.display.left = parseFloat(location.left.replace('%', ''));
        this.data.display.top =  parseFloat(location.top.replace('%', ''));

        this.changed.emit();
    }

    public wuiResizeStop(location: any) { ///

        if(!this.data) {
            return;
        }

        // Update display data
        this.data.display.left =    parseFloat(location.left.replace('%', ''));
        this.data.display.top =     parseFloat(location.top.replace('%', ''));
        this.data.display.width =   parseFloat(location.width.replace('%', ''));
        this.data.display.height =  parseFloat(location.height.replace('%', ''));

        this.changed.emit();
    }

    public swipe(direction: string): void {

        if(direction === 'left down') {
            this.minimize();
            return;
        }

        if(direction === 'right up') {
            this.close();
            return;
        }
    }
}
