<app-container>

    <app-header [logo]="'assets/apps/photographies/logo.svg'"
                [description]="'...'"></app-header>

    <!-- <wui-tabs [tabs]="tabs"></wui-tabs> -->

    <ng-container *ngComponentOutlet="tabs.selected?.component"></ng-container>

</app-container>
