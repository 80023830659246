import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { App } from '@lightning/wild-environment';
import { Window } from 'libs/wild-environment/src/lib/interfaces/window.interface';

@Component({
    selector: 'app-external',
    templateUrl: './external.component.html',
    styleUrls: ['./external.component.scss']
})
export class ExternalComponent implements App, OnInit, AfterViewInit {

    @Input()
    public window: Window = {} as Window;

    public url: string = '';

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private domSanitizer: DomSanitizer) { }

    public ngOnInit(): void {

        console.log(this.window.argument.url);

        this.url = this.window.argument.url;
    }

    public ngAfterViewInit(): void {

        // Prevent infinite iframe reloading
        this.changeDetectorRef.detach();
    }

    public trust(url: string): SafeResourceUrl {

        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}
