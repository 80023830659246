<div class="modal"> <!--wuiTacticalCorners-->
    <div class="bar">
        <div class="title">{{data?.title}}</div>
        <div class="buttons">
            <div class="close" (click)="close()"></div>
        </div>
    </div>
    <div class="content">
        <ng-template class="content" #content></ng-template>
    </div>
</div>
