import { EventEmitter, Injectable } from '@angular/core';

import { ScreenService } from '../../services/screen/screen.service';
import { ThemeService } from '../theme/theme.service';

import { Manifest } from '../../interfaces/manifest.interface';
import { Window, WindowState } from '../../interfaces/window.interface';
import { Modal } from '../../interfaces/modal.interface';
import { Notification } from '../../interfaces/notification.interface';
import { Tray } from '../../interfaces/tray.interface';

const STORAGE_NAME_SESSION = 'environment-session';
const TOAST_DURATION = 10000;

@Injectable({
    providedIn: 'root'
})

export class EnvironmentService {

    public manifests = new Array<Manifest>();
    public windows = new Array<Window>();
    public modals = new Array<Modal>();
    public notifications = new Array<Notification>();
    public toasts = new Array<Notification>();
    public tray: Tray | undefined;

    public sessionReady = new EventEmitter<void>();

    constructor(
            private screenService: ScreenService,
            private themeService: ThemeService) {

        this.themeService.load();
    }


    /**
     * Register one or more app(s)
     * @param manifest The manifest or manifest array of the app(s) to register
     */
    public appRegister(manifest: Manifest | Array<Manifest>): void {

        if (Array.isArray(manifest)) {
            this.manifests = this.manifests.concat(manifest);
        } else {
            this.manifests.push(manifest);
        }
    }

    /**
     * Unregister an app by id
     * @param id Id of the app to unregister
     */
    public appUnregister(id: string): void {
        this.manifests = this.manifests.filter(manifest => manifest.id !== id);
    }

    /**
     * Unregister all apps
     */
    public appsUnregisterAll(): void {
        this.manifests = new Array<Manifest>();
    }

    /**
     * Get the manifest of a registered app
     * @param id Id of the app to get the manifest
     * @returns The manifest of the app
     */
    public appGetRegistered(id: string): Manifest | undefined {
        return this.manifests.find(manifest => manifest.id === id);
    }

    /**
     * Get the windows created for an app
     * @param id The id of the app
     * @returns An array of windows made for the app
     */
    public appGetWindows(id: string): Array<Window> {
        return this.windows.filter(window => window.manifest.id === id);
    }


    /**
     * Open a window for a registred app
     * @param id The id of the app to create in the window
     * @returns The window created for the app
     */
    public windowOpenByAppId(id: string, argument?: any): Window | undefined {

        const manifest = this.appGetRegistered(id);

        if (!manifest) {
            this.notificationOpen({message: 'The app ' + id + ' is not available'});
            return;
        }

        return this.windowOpen(manifest, argument);
    }

    /**
     * Open a window for an app
     * @param manifest The manifest of the app the create in the window
     * @returns The window created for the app
     */
    public windowOpen(manifest: Manifest, argument?: any): Window {

        // Get a pontential existing window displaying this app
        let window = this.windows.find(item => item.manifest.id === manifest.id);

        // If no existing window or multi instance is allowed for this app
        if (window === undefined || (this.screenService.isDesktop && manifest.desktop.single === false)) {

            // The argument object can be specified on window open or during the registration in
            // the manifest (that allow to create somes apps using the same component, eg: webbrowser based apps targeting different urls)
            argument = argument || manifest.argument || {};

            // Creation of a new window
            window = {
                id: Date.now(),
                argument,
                manifest,
                display: {
                    width: 40,
                    height: 80,
                    left: 30,
                    top: 10,
                    state: WindowState.Normal,
                    priority: 0
                }
            };

            // Push it in the windows array
            this.windows.push(window);

            // Play audio
            this.playAudio('windowOpen');
        }

        // Restore the window
        if (window.display.state === WindowState.Minimized) {
            this.windowRestore(window);
        }

        // Focus the window
        this.windowFocus(window);

        // Save the session
        this.sessionSave();

        // Return the window displaying the app
        return window;
    }

    /**
     * Focus a window moving it over the others
     * @param window The window to focus
     */
    public windowFocus(window: Window): void {

        for (const item of this.windows) {
            if (window !== item && window.display.priority <= item.display.priority) {
                window.display.priority = item.display.priority + 1;
            }
        }
    }



    /**
     * Close a window
     * @param window The window to close
     */
    public closeWindow(window: Window): void {

        this.windows = this.windows.filter(a => a !== window);

        this.sessionSave();
    }

    /**
     * Close all windows
     * @param excludes The windows to keep
     */
    public closeWindowAll(excludes: Array<Window> = []): void {

        this.windows = this.windows.filter(a => excludes.some(b => b === a));

        this.sessionSave();
    }

    /**
     * Minimize a window
     * @param window Window to minimize
     */
    public windowMinimize(window: Window): void {

        window.display.state = WindowState.Minimized;

        this.sessionSave();
    }

    /**
     * Minimize all windows
     */
        public windowMinimizeAll(): void {

            for (const window of this.windows) {
                window.display.state = WindowState.Minimized;
            }

            this.sessionSave();
        }

    /**
     * Toggle minimized / normal window
     * @param window Window to toggle
     */
    public toggleMinimizeRestoreWindow(window: Window): void {

        if (window.display.state === WindowState.Minimized) {
            this.windowRestore(window);
        } else {
            this.windowMinimize(window);
        }
    }

    /**
     * Restore a minimized window
     * @param window Window to restore
     */
    public windowRestore(window: Window): void {

        window.display.state = WindowState.Normal;

        this.playAudio('windowRestore');

        this.windowFocus(window);

        this.sessionSave();
    }



    /**
     * Open a modal
     * @param modal Modal to open
     * @returns
     */
    public modalOpen(modal: Modal): Promise<any> {

        return new Promise(resolve => {

            modal.inputs = modal.inputs || {};

            modal.inputs.resolve = resolve;

            // Prevent modification during the environment construction
            setTimeout(() => {

                this.modals.push(modal);

                // Play audio
                this.playAudio('modalOpen');

            }, 0);
        });
    }

    /**
     * Close the last created modal
     */
    public modalClose(): void {

        if (this.modals.length === 0) {
            return;
        }

        // Remove the modal from the list and get it
        const modal = this.modals.pop();

        // Be sure it's promise is resolved
        if (modal?.inputs.resolve) {
            modal.inputs.resolve();
        }
    }




    /**
     * Open a notification
     * @param notification Notification to open
     */
    public notificationOpen(notification: Notification): void {

        // Prevent modification during the environment construction
        setTimeout(() => {

            // Set the date
            notification.date = new Date();

            // Set as toast
            this.toasts.push(notification);

            // Setup the new auto hidding
            notification.timer = setTimeout(() => { this.toastClose(notification); }, TOAST_DURATION);

            // Push with the previous notifications
            this.notifications.push(notification);

            // Play audio
            this.playAudio('notificationOpen');

        }, 0);
    }

    /**
     *
     * @param toast
     */
    public toastClick(toast: Notification): void {

        // Call the optional callback
        if (toast.callback) {
            toast.callback();
        }

        // Close the toast
        this.toastClose(toast);
    }

    /**
     * Close a toast
     * @param toast Toast to close
     */
    public toastClose(toast: Notification): void {

        clearTimeout(toast.timer);

        this.toasts = this.toasts.filter(t => t != toast);
    }

    /**
     * Clear all toasts
     */
    public toastsClear(): void {

        this.toasts.map(toast => clearTimeout(toast.timer));

        this.toasts = [];
    }



    /**
     * Register an item in the tray
     * @param tray Tray item to register
     */
    public trayRegister(tray: Tray): void {
        this.tray = tray;
    }



    /**
     * Reopen the windows openened the last session
     */
    public sessionLoad(): void {

        const stored = localStorage.getItem(STORAGE_NAME_SESSION);

        if (!stored) {

            console.log('Environment: No session to load');

        } else {

            const session = JSON.parse(stored);

            for (const window of session.windows) {

                // The app manifest must be updated for 2 reasons :
                // - During the serialization for saving, the 'component' reference was lost
                // - The manifest may be updated
                const manifest = this.manifests.find(m => m.id === window.manifest.id);

                if (manifest) {

                    // Update the manifest
                    window.manifest = manifest;

                    // Add the window
                    this.windows.push(window);
                }
            }
        }

        // Fire event
        this.sessionReady.emit();
    }

    /**
     * Save the windows for the next session
     */
    public sessionSave(): void {

        localStorage.setItem(STORAGE_NAME_SESSION, JSON.stringify({
            windows: this.windows
        }));
    }



    /**
     * Play an fx sound
     * @param name Name of the fx sound to play
     * @returns
     */
    private playAudio(name: string): void {

        // Audio disabled
        // return;

        new Audio(`assets/wild-environment/audio/${name}.mp3`).play();
    }

}


// TODO Uses this classes to make the environment's api better

export class Apps {

}

export class Windows {

}

export class Modals {

}

export class Notifications {

}

export class Toasts {

}

export class Trayx {

}

export class Session {

}
