import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';
import { SettingsWifi } from '../../services/settings/settings.service';
import { GatewayService } from '../../services/gateway/gateway.service';

@Component({
    selector: 'app-modal-wifi-credentials',
    templateUrl: './modal-wifi-credentials.component.html',
    styleUrls: ['./modal-wifi-credentials.component.scss']
})
export class ModalWifiCredentialsComponent {

    @Input()
    public logo = '';

    @Input()
    public description  = '';

    @Input()
    public ssid = '';

    @Input()
    public password = '';

    @Input()
    public credentials = new SettingsWifi();

    @Input() resolve = (result: SettingsWifi | null) => { /* */ };

    constructor(
        public translateService: TranslateService,
        public gatewayService: GatewayService,
        private environmentService: EnvironmentService) { }

    public ssidChanged(): void {

        if(this.credentials.ssid === '') {
            this.credentials.password = '';
        }
    }

    public cancelClick(): void {

        this.resolve(null);

        this.environmentService.modalClose();
    }

    public confirmClick(): void {

        this.resolve(this.credentials);

        this.environmentService.modalClose();
    }
}
