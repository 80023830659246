    <app-body >

        <app-instructor>

            <ng-container *ngIf="mistake === 0">

                <div *ngIf="step === 0" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.welcome' | translate }}
                </div>

                <div *ngIf="step === 1" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.headquarter' | translate }}
                </div>

                <div *ngIf="step === 2" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.globalTheory' | translate }}
                </div>

                <div *ngIf="step === 3" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.groundModulesDescription' | translate }}
                </div>

                <div *ngIf="step === 4" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.groundModulesNaming' | translate }}
                </div>

                <div *ngIf="step === 5" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.groundModulesOk' | translate }}
                </div>

                <div *ngIf="step === 6" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.operatorsDescription' | translate }}
                </div>

                <div *ngIf="step === 7" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.operatorsTeamsMaking' | translate }}
                </div>

                <div *ngIf="step === 8" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.operationSelection' | translate }}
                </div>

                <div *ngIf="step === 9" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.operationPreview' | translate }}
                </div>

                <div *ngIf="step === 10" wuiTypingText>
                    <br/>
                    {{ 'apps.simulation.tutorial.steps.operationSetup' | translate }}
                </div>

                <div *ngIf="step === 11" wuiTypingText>
                    <br/>
                    {{ 'apps.simulation.tutorial.steps.operationBriefing' | translate }}
                </div>

                <div *ngIf="step === 12" wuiTypingText>
                    <br/>
                    {{ 'apps.simulation.tutorial.steps.operationDeploying' | translate }}
                </div>

                <div *ngIf="step === 13" wuiTypingText>
                    <br/>
                    {{ 'apps.simulation.tutorial.steps.operationDeployed' | translate }}
                </div>

                <ng-container *ngIf="step === 14">

                    <div wuiTypingText>
                        {{ 'apps.simulation.tutorial.steps.operationSimulationPart1' | translate }}
                        {{ 'apps.simulation.tutorial.steps.operationSimulationPart2' | translate }}
                    </div>

                    <br/>

                    <app-simulation-action></app-simulation-action>

                </ng-container>

                <div *ngIf="step === 15" wuiTypingText>
                    {{ 'apps.simulation.tutorial.steps.operationComplete' | translate }}
                </div>

            </ng-container>

            <div *ngIf="mistake === 1" wuiTypingText>
                {{ 'apps.simulation.tutorial.mistakes.modulesRemoved' | translate }}
            </div>

            <div *ngIf="mistake === 2" wuiTypingText>
                {{ 'apps.simulation.tutorial.mistakes.operatorsRemoved' | translate }}
            </div>

        </app-instructor>

    </app-body>

    <app-footer>
        <div class="step">{{ step + 1 }} / {{ stepsCount }}</div>
        <wui-buttons>
            <wui-button
                developpersOnly
                *ngIf="step > 0 && step < 14"
                (click)="back()">{{ 'shared.back' | translate }}</wui-button>
            <wui-button
                *ngIf="step < stepsCount - 1"
                [wuiDisabled]="!canContinue()"
                [wuiTacticalCorners]="canContinue()"
                [wuiTacticalCornersOptions]="{infinite: true, noTop: true}"
                (click)="continue()">{{ 'shared.continue' | translate }}</wui-button>
        </wui-buttons>
    </app-footer>

