import { Component, OnInit, Input } from '@angular/core';
import { App, Window } from '@lightning/wild-environment';
import { Tabs } from '@lightning/wild-ui';
import { TranslateService } from '@ngx-translate/core';

import { RegisterService } from '../../../shared/services/register/register.service';
import { OperatorsComponent } from '../operators/operators.component';
import { TeamsComponent } from '../teams/teams.component';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements App, OnInit {

    @Input()
    public window: Window | undefined;

    public tabs: Tabs = {
        items: [
            {
                selector: 'operators',
                text: this.translateService.instant('shared.operators'),
                component: OperatorsComponent
            },
            {
                selector: 'teams',
                text: this.translateService.instant('shared.teams'),
                component: TeamsComponent
            }
        ],
        selected: { selector: 'operators', text: 'operators', component: OperatorsComponent }
    };

    constructor(
        public registerService: RegisterService,
        private translateService: TranslateService) { }

    ngOnInit() {
        this.tabs.selected = this.tabs.items[0];
    }

}
