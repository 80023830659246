import { Component, OnInit } from '@angular/core';
import { PrisonersService } from '../../services/prisoners.service';

@Component({
    selector: 'app-prisonners-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent implements OnInit {
    constructor(public prisonersService: PrisonersService) {}

    ngOnInit(): void {}
}
