import { Component } from '@angular/core';
import { ContaminationService } from '../../services/contamination.service';

@Component({
    selector: 'app-contamination-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent {
    constructor(public contaminationService: ContaminationService) {}
}
