export enum OperationSteps {
    Previewing,
    Setup,
    Briefing,
    Deploying,
    Operating,
    Debriefing,
    Sharing,
    Exiting
}

export enum OperationStates {
    Iddle,
    Processing,
    Paused,
    Complete,
}
