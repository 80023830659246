import { Component, OnInit } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';

import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';
import { OperationStates } from '../../../shared/enums/operation.enums';
import { LootingService } from '../../services/looting.service';


@Component({
    selector: 'app-looting-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

    OperationStates = OperationStates;

    constructor(public environmentService: EnvironmentService,
                public onlineService: OnlineService,
                public registerService: RegisterService,
                public lootingService: LootingService) { }

    ngOnInit() {
        // this.lootingService.start();
    }

}
