import { Component, OnInit, Input } from '@angular/core';

import { App, Window } from '@lightning/wild-environment';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styleUrls: ['./about.component.scss']
})
export class AboutComponent implements App, OnInit {

    @Input()
    public window: Window | undefined;

    constructor() { }

    ngOnInit() {
    }

}
