<app-container>

    <app-header
        logo="assets/apps/register/logo.svg"
        [description]="'apps.register.headerDescription' | translate">
    </app-header>

    <app-disclamer
        *ngIf="registerService.operators.length === 0"
        level="warning">
        {{ 'apps.register.disclamers.operatorsRequired' | translate | capitalize }}
    </app-disclamer>

    <app-disclamer
        *ngIf="registerService.isTeamsBalancingPossible"
        level="warning">
        {{ 'apps.register.disclamers.teamsBalancingPossible' | translate | capitalize }}
    </app-disclamer>

    <wui-tabs [tabs]="tabs"></wui-tabs>

    <ng-container *ngIf="tabs.selected">
        <ng-container *ngComponentOutlet="tabs.selected.component"></ng-container>
    </ng-container>

</app-container>
