<app-body>
    <wui-section name="objective">

        <p>
        Cette opération s'effectue avec deux équipes opposées.<br/>
        La première doit convoyer de l'argent d'un point A à un point B qui reprsente la banque centrale.<br/>
        La seconde a pour objectif de dérober cet argent et le sécuriser dans la planque.
        </p>

    </wui-section>

    <wui-section name="requierements">
        <p>
        Pour réaliser cette opération, vous devez disposer de deux modules de terrain ainsi que des cartes ressources.
        </p>
        <p>
        Un module doit etre placé dans la banque centrale et l'autre dans la planque.
        Quant-aux cartes ressources, elles doivent toutes se trouver au point de départ de l'équipe de convoyage au début de l'opération.
        </p>

        <wui-entry *ngFor="let requierement of convoyService.getRequierements().requierements"
            [ngClass]="requierement.isReady ? 'valid' : 'invalid'">
            <div name>{{ requierement.isReady ? '✓' : '⚠'}} {{ requierement.name }}</div>
            <div value>{{ requierement.value }}</div>
        </wui-entry>
    </wui-section>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="convoyService.exit()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="convoyService.setup()"
                    [wuiDisabled]="convoyService.getRequierements().isReady === false">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
