import { Component, OnInit, Input } from '@angular/core';

import { App, Window, EnvironmentService } from '@lightning/wild-environment';
import { OperationTrophy } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';

import { ReportsService } from 'apps/headquarter/src/app/apps/shared/services/reports/reports.service';

@Component({
    selector: 'lui-operation-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.scss']
})

export class ReportsComponent implements App, OnInit {

    @Input()
    public window: Window | undefined;

    constructor(
        public environmentService: EnvironmentService,
        public onlineService: OnlineService,
        public reportsService: ReportsService) { }

    ngOnInit() { }

    public trophyClick(trophy: OperationTrophy): void { }
}
