
<app-body>
    <wui-section name="{{ 'apps.online.account' | translate }}">

        {{ 'apps.online.loggedIn' | translate: { account: onlineService.tokenPayload } | capitalize }}

        <pre developpersOnly>{{ onlineService.tokenPayload | json}}</pre>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="onlineService.logout()">{{ 'apps.online.logout' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
