<app-body>
    <wui-section [name]="'shared.generalities' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate }}</div>
            <wui-input
                value
                type="text"
                [(value)]="prisonersService.settings.name"
            ></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate }}</div>
            <wui-select value [(value)]="prisonersService.settings.timeLimit">
                @for(item of OperationProcessDurations | keyValue; track item) {
                    <option [value]="item.value">
                        {{ 'apps.operations.setup.durations.' + item.key | translate }}
                    </option>
                }
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section name="prisons">
        @for(team of registerService.teams; track team) {
            <wui-entry>
                <div name>{{ team.name }}</div>
                <wui-select value [(value)]="team.data.prison" [required]="true" (valueChange)="prisonChanged(team)">
                    @for(location of groundLocations; track location) {
                        <option [value]="location">
                            {{ location }}
                        </option>
                    }
                </wui-select>
            </wui-entry>
        }
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="prisonersService.preview()">
            {{ 'shared.back' | translate }}
        </wui-button>
        <wui-button
            (click)="prisonersService.brief()"
            [wuiDisabled]="prisonersService.settingsAreValid() === false">
            {{ 'shared.confirm' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
