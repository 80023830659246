<wui-section [name]="'shared.action' | translate">

    <wui-entry>
        <div name>{{ 'shared.operator' | translate | capitalize }}</div>
        <wui-select value [(value)]="action.operator">
            <option *ngFor="let operator of registerService.operators; let index = index" [value]="index">
                {{ operator.name }}
            </option>
        </wui-select>
    </wui-entry>

    <wui-entry>
        <div name>{{ 'shared.action' | translate | capitalize }}</div>
        <wui-select value [(value)]="action.action">
            <option *ngFor="let action of ActionsNames | keyValue" [value]="action.value">
                {{ 'apps.simulation.simulator.actions.' + action.value | translate }}
            </option>
        </wui-select>
    </wui-entry>

    <wui-entry *ngIf="action.action === ActionsNames.Resource">
        <div name>{{ 'shared.value' | translate | capitalize }}</div>
        <wui-input [(value)]="action.value" type="number" [min]="1000"></wui-input>
    </wui-entry>

    <wui-entry *ngIf="action.action === ActionsNames.Part">
        <div name>{{ 'shared.number' | translate | capitalize }}</div>
        <wui-input [(value)]="action.number" type="number" [min]="1"></wui-input>
    </wui-entry>

    <wui-entry>
        <div name>{{ 'shared.module' | translate | capitalize }}</div>
        <wui-select value [(value)]="action.module" type="number">
            <option *ngFor="let item of loraProtocolService.devices; let index = index" [value]="index">
                {{ item.name }}
            </option>
        </wui-select>
    </wui-entry>

    <wui-entry>
        <div name></div>
        <wui-button value (click)="run()">
            {{ 'shared.simulate' | translate }}</wui-button>
    </wui-entry>

</wui-section>
