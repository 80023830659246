<app-container>

    <app-header
        [logo]="'assets/apps/feedbacks/logo.svg'"
        [description]="'apps.feedbacks.headerDescription' | translate">
    </app-header>

    @if (onlineService.isAvailable() === false) {
        <app-disclamer>{{ 'apps.feedbacks.disclamers.notConnected' | translate }}</app-disclamer>

    } @else if (onlineService.isTokenValid() === false) {
        <app-disclamer
            (click)="environmentService.windowOpenByAppId('online')"
            wuiStopPropagation
            [isInteractive]="true">
            {{ 'apps.feedbacks.disclamers.notLoggedIn' | translate  | capitalize }}
        </app-disclamer>
    }

    <app-body>

        <wui-section [name]="'apps.feedbacks.content.name' | translate">

            <wui-entry>
                <div name>{{ "apps.feedbacks.content.description.name" | translate }}</div>
                <div detail>{{ "apps.feedbacks.content.description.detail" | translate }}</div>
                <wui-textarea value [(value)]="request.description"></wui-textarea>
            </wui-entry>

            @let hasScreenshot = (request.screenshot || []).length > 0;

            <wui-entry class="desktop-only">
                <div name>{{ "apps.feedbacks.content.screenshot.name" | translate }}</div>
                <div detail>{{ "apps.feedbacks.content.screenshot.detail" | translate }}</div>
                <wui-input value [value]="hasScreenshot" type="checkbox" (valueChange)="changeHasScreenshot($event)"></wui-input>
            </wui-entry>

            @if (request.screenshot) {
                <img [src]="request.screenshot">
            }

        </wui-section>

    </app-body>
    <app-footer>
        <wui-buttons>
            <wui-button value (click)="send()" [wuiDisabled]="isInvalid">
                {{ (onlineService.isAvailableAndLoggedIn() ? 'shared.send' : 'shared.save') | translate }}
            </wui-button>
        </wui-buttons>
    </app-footer>

</app-container>
