import { Injectable } from '@angular/core';
import { SwUpdate, VersionEvent } from '@angular/service-worker';
import { EnvironmentService } from '@lightning/wild-environment';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class UpdatesService {

    private _hasUpdatesAvailable = false;

    constructor(

        private updates: SwUpdate,
        private translateService: TranslateService,
        private environmentService: EnvironmentService) { }

    public initialize(): void {

        // Check if the PWA is installed
        console.log('UpdatesService: Is installed:', this.isApplicationInstalled);

        // Check if a service worker is enabled
        console.log('UpdatesService: Is service worker enabled:', this.isServiceWorkerEnabled);

        console.log('UpdatesService: Is production:', environment.production);

        if (!this.isServiceWorkerEnabled) {
            return;
        }

        this.updates.versionUpdates.subscribe({
            next: (versionEvent: VersionEvent) => {

                switch(versionEvent.type) {

                    case 'VERSION_DETECTED':

                        if (this._hasUpdatesAvailable === false) {

                            // Notify once
                            this.environmentService.notificationOpen({
                                message: this.translateService.instant('services.updates.detected'),
                                callback: this.reload
                            });
                        }

                        this._hasUpdatesAvailable = true;

                        console.log('UpdatesService: Available updates detected:');
                        console.log('UpdatesService: > available version is', versionEvent.version.hash);

                    break;

                    case 'VERSION_READY':

                        console.log('UpdatesService: The lastest updates has been activated:');
                        console.log('UpdatesService: > current version is', versionEvent.currentVersion.hash);

                        this.environmentService.notificationOpen({
                            message: this.translateService.instant('services.updates.activated'),
                            callback: this.reload
                        });

                    break;
                }
            }
        })
    }

    public get hasUpdatesAvailable(): boolean {

        return this._hasUpdatesAvailable;
    }

    public get isApplicationInstalled(): boolean {

        // For Safari...
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (window.navigator['standalone']) {

            return true;
        }

        // For regular browsers
        if (window.matchMedia('(display-mode: standalone)').matches) {

            return true;
        }

        // Not installed
        return false;
    }

    public get isServiceWorkerEnabled(): boolean {

        return this.updates.isEnabled;
    }

    public reload(): void {

        // eslint-disable-next-line no-self-assign
        window.location = window.location;
    }

}
