import { Component, OnInit } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';
import { ReportsService } from 'apps/headquarter/src/app/apps/shared/services/reports/reports.service';
import { UuidUtils } from '@lightning/utils';

@Component({
    selector: 'app-qrcode',
    templateUrl: './qrcode.component.html',
    styleUrls: ['./qrcode.component.scss']
})

export class QRCodeComponent implements OnInit {

    public session = '';
    public syncCode = '';

    constructor(
        public environmentService: EnvironmentService,
        public onlineService: OnlineService,
        public reportsService: ReportsService) { }

    ngOnInit() {

        this.session = UuidUtils.getDayUniqueId();

        setInterval(() => {

            const date = new Date();
            const seconds = (date.getUTCHours() * 3600 + date.getMinutes() * 60 + date.getSeconds());

            this.syncCode =
                //environment.url +
                //'/session/' +
                this.session +
                '-' +
                seconds//.toString(16);
                //(new Date().getTime());

        }, 1000);

    }

}
