import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService } from '@lightning/headquarter/app/apps/shared/services/settings/settings.service';
import { Timer } from '@lightning/headquarter/app/apps/shared/classes/timer';

import { ContaminationService } from '../../services/contamination.service';

@Component({
    selector: 'app-contamination-deploying',
    templateUrl: './deploying.component.html',
    styleUrls: ['./deploying.component.scss'],
})
export class DeployingComponent implements OnDestroy {
    public timer: Timer = new Timer();

    private timerSubscription: Subscription;

    constructor(
        public contaminationService: ContaminationService,
        private settingsService: SettingsService
    ) {
        // Same deployment time for all operations
        this.timer.startCountdown(
            this.settingsService.settings.operations.timeForDeployment
        );

        this.timerSubscription = this.timer.onComplete.subscribe(() => {
            this.contaminationService.operate(true);
        });
    }

    public ngOnDestroy(): void {
        this.timer.clear();

        this.timerSubscription.unsubscribe();
    }

    public continue(): void {
        this.contaminationService.operate(false);
    }
}
