import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';

import { WildUiModule } from '@lightning/wild-ui';
import { LightningUiModule } from '@lightning/lightning-ui';

import { SharedModule } from '../shared/shared.module';
import { OperationsModule } from '../operations/operations.module';

import { ReportsComponent } from './components/reports/reports.component';


@NgModule({
    declarations: [
        ReportsComponent
    ],
    imports: [
        CommonModule,
        TranslateModule,
        WildUiModule,
        LightningUiModule,
        SharedModule,
        OperationsModule
    ],
    exports: [
        ReportsComponent
    ]
})

export class ReportsModule { }
