import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';

import { BotComponent } from './components/bot/bot.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ActionsComponent } from './components/actions/actions.component';

@NgModule({
  declarations: [
    BotComponent,
    SettingsComponent,
    ActionsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    BotComponent
  ]
})

export class BotModule { }
