import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class BatteryService {

    private _battery: any;

    constructor() {

        this.initialize();
    }

    private async initialize(): Promise<void> {

        if ('getBattery' in navigator) {
            this._battery = await(navigator as any).getBattery();
        } else if ('battery' in navigator) {
            this._battery = (navigator as any).battery;
        }

        if (!this._battery) {
            return;
        }
    }

    public get battery() {

        return this._battery;
    }

    // {{ batteryService.battery.dischargingTime }}

}
