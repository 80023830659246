import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OperationProcessDurations } from '@lightning/lightning-definitions';

import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { SandboxService } from '../../services/sandbox.service';

@Component({
    selector: 'app-sandbox-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],

})
export class SetupComponent implements OnInit, OnDestroy {

    public OperationProcessDurations = OperationProcessDurations;

    constructor(
        public sandboxService: SandboxService,
        private translateService: TranslateService,
        private settingsService: SettingsService) { }

    ngOnInit() {

        this.sandboxService.settingsLoad();

        this.sandboxService.settings.name =
            `${ new Date().toLocaleTimeString() } - ${ this.translateService.instant('apps.operations.modes.' + this.sandboxService.mode + '.name')}`;

        this.sandboxService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;
    }

    ngOnDestroy() {

        this.sandboxService.settingsApply();
    }
}
