import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpBackend, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppsModule } from './apps/apps.module';
import { BrowserModule } from '@angular/platform-browser';
import { JwtInterceptor } from '@lightning/lightning-services';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MultiTranslateHttpLoader} from 'ngx-translate-multi-http-loader';
import { TrayModule } from './tray/tray.module';
import { WildEnvironmentModule } from '@lightning/wild-environment';
import { WildUiModule } from '@lightning/wild-ui';
import { CoreModule } from './core/core.module';
import { UpdatesService } from './apps/shared/services/updates/updates.service';
import { tap } from 'rxjs';

// export function httpLoaderFactory(http: HttpClient) {
//     return new TranslateHttpLoader(http);
// }

// Multiple
export const httpLoaderFactory = (http: HttpBackend): MultiTranslateHttpLoader => {
    return new MultiTranslateHttpLoader(http, [
        'assets/i18n/core/',
        'assets/i18n/shared/',
        'assets/i18n/http/',
        'assets/i18n/operations/',
    ]);
};

export function appInitializerFactory(translate: TranslateService, updateService: UpdatesService) {
    return () => {

        translate.setDefaultLang('fr');

        return translate.use('fr')
            .pipe(tap(() => {
                updateService.initialize();
            }));
    };
}

function isOverHttps(): boolean {
    return window.location.protocol === 'https:';
}

@NgModule({
    declarations: [
        AppComponent
    ],
    bootstrap: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpBackend],
            }
        }),
        WildEnvironmentModule,
        WildUiModule,
        CoreModule,
        AppsModule,
        TrayModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: isOverHttps(),
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [
                TranslateService,
                UpdatesService
            ],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        provideHttpClient(withInterceptorsFromDi()),
    ]
})
export class AppModule { }
