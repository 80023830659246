import { Component, OnInit } from '@angular/core';

import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';

@Component({
    selector: 'app-logs',
    templateUrl: './logs.component.html',
    styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {

    constructor(public loraProtocolService: LoraProtocolService) { }

    ngOnInit() {
    }

}
