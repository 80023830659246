import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { SettingsComponent } from './components/settings/settings.component';
import { ThemeComponent } from './components/theme/theme/theme.component';
import { GeneralitiesComponent } from './components/generalities/generalities.component';


@NgModule({
    declarations: [
        SettingsComponent,
        ThemeComponent,
        GeneralitiesComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ],
    exports: [
        SettingsComponent
    ]
})

export class SettingsModule { }
