import { FilesUtils, JSONUtils } from '@lightning/utils';

import { Observable } from 'rxjs';

export interface Entry {
    time: Date;
    data: any;
}

export interface ReplayOptions {
    loop: boolean;
    realIntervals: boolean;
    speed: number;
}

export class Historizer {

    private id: string;
    private limit: number;

    private _replayOutObserver: any;
    private _replayTimer: any;
    private _replayIndex = 0;
    private _isReplaying = false;
    private _replayOut: Observable<any>;
    public  _entries: Array<Entry>;

    public replayOptions: ReplayOptions;

    constructor(id: string, limit = 1000, continuous = true, replayOptions: ReplayOptions | undefined = undefined) {

        this.id = id;
        this.limit = limit;

        this._entries = new Array<Entry>();

        // Enable continuous historization (even if the app is stopped / reloaded)
        // using local storage.
        if (continuous) {

            this.loadFromLocalStorage();

            window.onbeforeunload = () => {
                this.saveToLocalStorage();
            };
        }

        // Initialize replay observable
        this._replayOut = new Observable(observer => {
            this._replayOutObserver = observer;
        });

        // Set default replay actions if needed
        this.replayOptions = replayOptions || {
            loop: true,
            realIntervals: true,
            speed: 1
        };

        this._isReplaying = false;
    }

    public dispose(): void {
        this.stopReplay();
    }

    public get replayOut() {
        return this._replayOut;
    }

    public get entries() {
        return this._entries;
    }

    public set entries(entries: Array<Entry>) {

        this.stopReplay();

        this._entries = entries;
    }

    public write(entry: Entry): void {

        if (this._isReplaying === false) {

            // Push is used instead of unshift (to add to the beginning) beacause performances.
            this._entries.push(entry);

            if (this._entries.length > this.limit) {
                this._entries.splice(0, this._entries.length - this.limit);
            }
        }
    }

    public clear(): void {

        this.stopReplay();

        this._entries.splice(0, this._entries.length);
    }

    private saveToLocalStorage(): void {

        window.localStorage.setItem(this.id, JSON.stringify({
            date: new Date(),
            entries: this._entries
        }));
    }

    private loadFromLocalStorage(): void {

        const stored = localStorage.getItem(this.id);

        if (!stored) {
            return;
        }

        const data = JSON.parse(stored, JSONUtils.dateReviver);

        if (data.entries) {
            this._entries = data.entries;
        }
    }

    public saveToFile(): void {

        FilesUtils.save(JSON.stringify({
            date: new Date(),
            entries: this._entries
        }, null, 4), this.id + '.json');
    }

    public async loadFromFile(): Promise<void> {

        // Get data from file
        let data: any = await FilesUtils.load('application/json');

        // Parse with careful for date
        data = JSON.parse(data as string, JSONUtils.dateReviver);

        if (data.entries) {
            this._entries = data.entries;
        }
    }

    public startReplay(startIndex = 0): void {

        if (startIndex < this._entries.length) {

            this._isReplaying = true;

            this._replayIndex = startIndex;

            this.replayRun();
        }
    }

    public stopReplay(): void {

        if (this.isReplaying) {

            clearTimeout(this._replayTimer);

            this._replayTimer = null;
            this._replayIndex = 0;
            this._isReplaying = false;
        }
    }

    public toggleReplay(): void {
        if (this.isReplaying) {
            this.stopReplay();
        } else {
            this.startReplay();
        }
    }

    public get isReplaying(): boolean {
        return this._isReplaying;
    }

    public get isEmpty(): boolean {
        return this._entries.length === 0;
    }

    public get replayIndex(): number {
        return this._replayIndex;
    }

    public replaySingleEntry(index: number): void {

        this._isReplaying = true;

        this._replayIndex = index;

        this._replayOutObserver.next(this._entries[this.replayIndex].data);
    }


    private replayRun(): void {

        // Clear timeout
        if (this._replayTimer) {
            clearTimeout(this._replayTimer);
        }

        // Entries was cleared
        if (this._entries.length === 0) {
            this.stopReplay();

            return;
        }

        // Output
        this._replayOutObserver.next(this._entries[this.replayIndex].data);

        // Default fixed interval
        let interval = 1000;

        // Real interval
        // if (this.replayOptions.realIntervals && this._replayIndex < this._entries.length - 1) {
        //     interval = this._entries[this._replayIndex + 1].time.getTime() -
        //                this._entries[this._replayIndex].time.getTime();
        // }

        // Apply speed
        interval /= this.replayOptions.speed;

        // Wait for the next entry
        this._replayTimer = setTimeout(() => {

            // Target next entry
            this._replayIndex++;

            // End of entries management
            if (this._replayIndex >= this._entries.length) {
                if (this.replayOptions.loop) {
                    this._replayIndex = 0;
                } else {

                    this.stopReplay();

                    return;
                }
            }

            this.replayRun();
        },
        interval);

    }

}
