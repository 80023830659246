<app-header *ngIf="description" [logo]="logo" [description]="description"></app-header>

<div class="list">
    <wui-button *ngFor="let item of items" (click)="itemClick(item)">
        {{ item.label }}
    </wui-button>
</div>

<app-footer>
    <wui-buttons>
        <wui-button (click)="cancelClick()">{{ 'shared.cancel' | translate }}l</wui-button>
    </wui-buttons>
</app-footer>
