import { Component } from '@angular/core';
import { GroundLocations, ProductsWithSoftware } from '@lightning/lightning-definitions';
import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { RegisterService } from '../../../shared/services/register/register.service';
import { ActionsNames } from '../../enums/simulation.enums';


@Component({
    selector: 'app-simulation-action',
    templateUrl: './action.component.html',
    styleUrls: ['./action.component.scss']
})
export class ActionComponent {

    public ActionsNames = ActionsNames;

    public action = {
        operatorNumber: 1,
        action: ActionsNames.Presence,
        value: 1000,
        number: 1,
        moduleName: GroundLocations.Alpha
    };

    constructor(
        public registerService: RegisterService,
        public loraProtocolService: LoraProtocolService) { }

    public run(): void {

        const operator = this.registerService.operators
            .find(operator => operator.number === this.action.operatorNumber);

        const module = this.loraProtocolService
            .getDevicesByProduct(ProductsWithSoftware.GroundModule)
            .find(module => module.name === this.action.moduleName);

        if (!operator || !module) {
            return;
        }

        const owner = operator.isGuest ? `GST ${operator.number}` : `OPR ${operator.number} ${operator.name}`;

        let payload = {};

        switch (this.action.action) {

            case ActionsNames.Presence:
                payload = {
                    nfc: {
                        record: owner
                    }
                };
                break;
            case ActionsNames.Resource:
                payload = {
                    nfcCashup: {
                        owner,
                        total: this.action.value
                    }
                };
                break;
            case ActionsNames.Part:
                payload = {
                    nfcPart: {
                        owner,
                        number: this.action.number
                    }
                };
                break;
        }

        this.loraProtocolService.simulateReceive({
            protocolVersion: 0,
            senderId: module.id,
            packetNumber: 0,
            destinationId: ''.padEnd(10, '0'),
            rebounds: 0,
            payloadNumber: 0,
            payload,
            rssi: 0
        });

    }

}
