<app-body>
    <wui-section [name]="'shared.objectives' | translate">
        {{ 'apps.operations.modes.looting.objectives' | translate }}
    </wui-section>
    <wui-section [name]="'shared.rules' | translate">
        {{ 'apps.operations.modes.looting.rules' | translate }}
    </wui-section>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="lootingService.setup()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="lootingService.briefSpeech()">{{ 'shared.speech' | translate }}</wui-button>
        <wui-button (click)="lootingService.deploy()">{{ 'shared.deploy' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
