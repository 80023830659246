import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';

import { SharedModule } from '../shared/shared.module';

import { OnlineComponent } from './components/online/online.component';
import { LoginComponent } from './components/login/login.component';
import { AccountComponent } from './components/account/account.component';

@NgModule({
    declarations: [
        OnlineComponent,
        LoginComponent,
        AccountComponent
    ],
    imports: [
        CommonModule,
        WildUiModule,
        SharedModule,
    ],
    exports: [
        OnlineComponent
    ]
})

export class OnlineModule { }
