import { Component, Input } from '@angular/core';

import { OperatorUnderOperation, TeamUnderOperation } from '@lightning/lightning-definitions';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modal-team-assign',
    templateUrl: './modal-team-assign.component.html',
    styleUrls: ['./modal-team-assign.component.scss']
})

export class ModalTeamAssignComponent {

    @Input()
    public logo = '';

    @Input()
    public description = '';

    @Input()
    public operator: OperatorUnderOperation | undefined;

    @Input()
    public teams: Array<TeamUnderOperation> = [];

    @Input()
    private resolve = (team: TeamUnderOperation) => { /* */ };

    public selectedTeam: TeamUnderOperation | undefined;

    constructor(private environmentService: EnvironmentService) { }

    public confirm(): void {

        if (!this.selectedTeam) {
            return;
        }

        this.resolve(this.selectedTeam);

        this.environmentService.modalClose();
    }
}
