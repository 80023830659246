import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';

import { OperationDeploymentDurations, OperationProcessDurations, SoftwaresChannels } from '@lightning/lightning-definitions';

import { GatewayConnectorsNames } from '../../../shared/enums/gateway.enum';
import { LongRangeProtocolsNames } from '../../../shared/enums/lora.enum';
import { SettingsService } from '../../../shared/services/settings/settings.service';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';
import { StringUtils } from '@lightning/wild-ui';


@Component({
    selector: 'app-generalities',
    templateUrl: './generalities.component.html',
    styleUrls: ['./generalities.component.scss']
})
export class GeneralitiesComponent {

    public readonly OperationDeploymentDurations = OperationDeploymentDurations;

    public readonly OperationProcessDurations = OperationProcessDurations;

    public readonly GatewayConnections = GatewayConnectorsNames;

    public readonly LongRangeProtocols = LongRangeProtocolsNames;

    public readonly SoftwaresChannels = SoftwaresChannels;

    public environmentAudioVolume = 0;

    constructor(
        public environmentService: EnvironmentService,
        public settingsService: SettingsService,
        public gatewayService: GatewayService,
        private translateService: TranslateService) {

        this.environmentAudioVolume = this.environmentService.audioVolume;
    }

    public wifiSsidChanged(): void {

        if( this.settingsService.settings.wifi.ssid === '') {
            this.settingsService.settings.wifi.password = '';
        }
    }

    public environmentAudioVolumeChanged(): void {

        if (this.environmentService.audioVolume === this.environmentAudioVolume) {
            return;
        }

        this.environmentService.audioVolume = this.environmentAudioVolume;

        this.environmentService
            .sessionSave();

        this.environmentService
            .notificationOpen({ message: StringUtils.capitalize(this.translateService.instant('shared.test'))});
    }

    public restart(): void {
        // eslint-disable-next-line no-self-assign
        window.location = window.location;
    }

}
