/* eslint-disable @angular-eslint/directive-selector */
import { Directive, ElementRef, OnInit } from '@angular/core';
import { SettingsService } from '../../services/settings/settings.service';

@Directive({
    selector: '[developpersOnly]'
})
export class DeveloppersOnlyDirective implements OnInit {

    private defaultDisplay = '';

    constructor(
        private elementRef: ElementRef,
        private settingsService: SettingsService) { }

    public ngOnInit() {

        this.defaultDisplay = this.elementRef.nativeElement.style.display;

        this.settingsService.onSaved
            .subscribe(() => this.update());

        this.update();
    }

    private update(): void {

        this.elementRef.nativeElement.style.display = this.settingsService.isDeveloppersModeEnabled ? this.defaultDisplay : 'none';
    }
}
