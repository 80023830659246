import { Component, OnInit, OnDestroy } from '@angular/core';
import { OperationProcessDurations } from '@lightning/lightning-definitions';
import { TranslateService } from '@ngx-translate/core';

import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { DominationService } from '../../services/domination.service';

@Component({
    selector: 'app-domination-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],

})
export class SetupComponent implements OnInit, OnDestroy {

    public OperationProcessDurations = OperationProcessDurations;

    constructor(
        public settingsService: SettingsService,
        private translateService: TranslateService,
        public dominationService: DominationService) { }

    public ngOnInit() {

        this.dominationService.settingsLoad();

        this.dominationService.settings.name =
            `${ new Date().toLocaleTimeString() } - ${ this.translateService.instant('apps.operations.modes.' + this.dominationService.mode + '.name')}`;

        this.dominationService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;
    }

    public ngOnDestroy() {

        this.dominationService.settingsApply();
    }
}
