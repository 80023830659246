import { Component, OnInit, Input } from '@angular/core';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modal-alert',
    templateUrl: './modal-alert.component.html',
    styleUrls: ['./modal-alert.component.scss']
})

export class ModalAlertComponent implements OnInit {

    @Input()
    public logo: string = '';
    
    @Input() 
    public description: string = '';

    @Input() 
    public resolve = () => { };

    constructor(private environmentService: EnvironmentService) { }

    ngOnInit(): void {
    }

    public confirmClick(): void {
        this.resolve();
        this.environmentService.modalClose();
    }

}
