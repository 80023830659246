import { Component, OnInit } from '@angular/core';
import { LootingService } from '../../services/looting.service';

@Component({
  selector: 'app-looting-briefing',
  templateUrl: './briefing.component.html',
  styleUrls: ['./briefing.component.scss']
})
export class BriefingComponent implements OnInit {

  constructor(public lootingService: LootingService) { }

  ngOnInit() {
  }

}
