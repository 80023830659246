<wui-entry>
    <div name>{{ 'apps.simulation.simulator.scene.modulesCount' | translate }}</div>
    <wui-input value
        type="number"
        [min]="0"
        [max]="devices.maxCount"
        [(value)]="devices.count"></wui-input>
</wui-entry>

<wui-entry>
    <div name>{{ 'apps.simulation.simulator.scene.operatorsCount' | translate }}</div>
    <wui-input value
        type="number"
        [min]="0"
        [max]="operators.maxCount"
        [(value)]="operators.count"></wui-input>
</wui-entry>

<wui-entry>
    <div name></div>
    <wui-button value (click)="run()">{{ 'shared.generate' | translate }}</wui-button>
</wui-entry>
