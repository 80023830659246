import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modal-textarea',
    templateUrl: './modal-textarea.component.html',
    styleUrls: ['./modal-textarea.component.scss']
})

export class ModalTextareaComponent implements OnInit {

    @Input() 
    public logo: string = '';

    @Input()
    public description: string = '';
    
    @Input()
    public value: string = '';

    @Input()
    public placeholder: string = '';

    @Input() resolve = (item: any = null) => { };

    constructor(
        public translateService: TranslateService, 
        private environmentService: EnvironmentService) { }

    public ngOnInit(): void {
    }

    public confirmClick(): void {

        this.resolve(this.value);
        
        this.environmentService.modalClose();
    }
}
