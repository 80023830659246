<app-body>
    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="prisonersService.settings.timeLimit > 0">
            {{ prisonersService.timer.time | milliseconds}}
        </ng-container>
        <ng-container *ngIf="prisonersService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <lui-operation-teams-ranking wuiTacticalCorners [teams]="registerService.teams"
        [pointsCurrency]="prisonersService.settings.pointsCurrency"
        [pointsLimit]="prisonersService.settings.pointsLimit"></lui-operation-teams-ranking>

    <lui-timeline *ngIf="prisonersService.timeline.length" wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="prisonersService.timeline"
        [scrolling]="false">
    </lui-timeline>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button *ngIf="prisonersService.state === OperationStates.Iddle"       (click)="prisonersService.start()">{{ 'shared.start' | translate }}</wui-button>
        <wui-button *ngIf="prisonersService.state === OperationStates.Processing"  (click)="prisonersService.pause()">{{ 'shared.pause' | translate }}</wui-button>
        <wui-button *ngIf="prisonersService.state === OperationStates.Paused"      (click)="prisonersService.resume()">{{ 'shared.resume' | translate }}</wui-button>
        <wui-button *ngIf="prisonersService.state === OperationStates.Processing"  (click)="prisonersService.complete()">{{ 'shared.complete' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
