<app-body>

    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="sandboxService.settings.timeLimit > 0">
            {{ sandboxService.timer.time | milliseconds }}
        </ng-container>
        <ng-container *ngIf="sandboxService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <lui-timeline *ngIf="sandboxService.timeline.length" wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="sandboxService.timeline"
        [scrolling]="false">
    </lui-timeline>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button *ngIf="sandboxService.state === OperationStates.Iddle"       (click)="sandboxService.start()">{{ 'shared.start' | translate }}</wui-button>
        <wui-button *ngIf="sandboxService.state === OperationStates.Processing"  (click)="sandboxService.pause()">{{ 'shared.pause' | translate }}</wui-button>
        <wui-button *ngIf="sandboxService.state === OperationStates.Paused"      (click)="sandboxService.resume()">{{ 'shared.resume' | translate }}</wui-button>
        <wui-button *ngIf="sandboxService.state === OperationStates.Processing"  (click)="sandboxService.complete()">{{ 'shared.complete' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
