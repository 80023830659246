<app-body>
    <wui-section [name]="'shared.objectives' | translate">
        {{ 'apps.operations.modes.contamination.objectives' | translate }}
    </wui-section>
    <wui-section [name]="'shared.requirements' | translate">
        <p>
            {{ 'apps.operations.modes.contamination.requirements' | translate }}
        </p>
        <wui-entry
            *ngFor="
                let requierement of contaminationService.getRequierements()
                    .requierements
            "
            [ngClass]="requierement.isReady ? 'valid' : 'invalid'"
        >
            <div name>
                {{ requierement.isReady ? '✓' : '⚠' }} {{ requierement.name }}
            </div>
            <div value>{{ requierement.value }}</div>
        </wui-entry>
    </wui-section>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="contaminationService.exit()">{{
            'shared.back' | translate
        }}</wui-button>
        <wui-button
            (click)="contaminationService.setup()"
            [wuiDisabled]="
                contaminationService.getRequierements().isReady === false
            "
            >{{ 'shared.confirm' | translate }}</wui-button
        >
    </wui-buttons>
</app-footer>
