import { Component } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';

import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';
import { OperationStates } from '../../../shared/enums/operation.enums';
import { AssemblyService } from '../../services/assembly.service';

@Component({
    selector: 'app-assembly-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss'],
})
export class ProcessComponent {
    OperationStates = OperationStates;

    constructor(
        public environmentService: EnvironmentService,
        public onlineService: OnlineService,
        public registerService: RegisterService,
        public assemblyService: AssemblyService
    ) {}

}
