import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QrCodeModule } from 'ng-qrcode';

import { WildUiModule } from '@lightning/wild-ui';

import { SharedModule } from '../shared/shared.module';

import { PhotographiesComponent } from './components/photographies/photographies.component';
import { QRCodeComponent } from './components/qrcode/qrcode.component';


@NgModule({
    declarations: [
        PhotographiesComponent,
        QRCodeComponent
    ],
    imports: [
        CommonModule,
        WildUiModule,
        SharedModule,
        QrCodeModule,
    ],
    exports: [
        PhotographiesComponent
    ]
})

export class PhotographiesModule { }
