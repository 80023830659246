import { Injectable } from '@angular/core';
import { Theme } from '../../interfaces/theme.interface';

const STORAGE_NAME_THEME= 'environment-theme';

@Injectable({
    providedIn: 'root'
})

export class ThemeService {

    // Be careful, the color picker doesn't recognize names, use color codes
    private default: Theme = {
        properties: [
            { name: 'colorBackground0', type: 'color', value: '#13181D' },
            { name: 'colorBackground1', type: 'color', value: '#181D20' },
            { name: 'colorBackground2', type: 'color', value: '#151A1F' },
            { name: 'colorBackground3', type: 'color', value: '#EEEEEE' },
            { name: 'colorBackground4', type: 'color', value: '#BFA480' },          // tan
            { name: 'colorBackground5', type: 'color', value: '#FFFFFF' },
            { name: 'colorBackground6', type: 'color', value: '#4682b4' },          // steelblue
            { name: 'colorBorder0', type: 'color', value: '#111F11' },
            { name: 'colorBorder1', type: 'color', value: '#181D20' },
            { name: 'colorBorder3', type: 'color', value: '#8F8C88' },
            { name: 'colorBorder5', type: 'color', value: '#FFFFFF' },
            { name: 'colorText0', type: 'color', value: '#FFFFFF' },
            { name: 'colorText1', type: 'color', value: '#BFA480' },                // tan
            { name: 'colorText2', type: 'color', value: '#000F08' },
            { name: 'colorText3', type: 'color', value: '#8f8c88' },

            { name: 'colorBackgroundHover', type: 'color', value: '#BFA480' },      // tan
            { name: 'colorTextHover', type: 'color', value: '#FFFFFF' },
            { name: 'colorBackgroundActive', type: 'color', value: '#FFFFFF' },
            { name: 'colorTextActive', type: 'color', value: '#000F08' },

            { name: 'colorValid', type: 'color', value: '#5EDB2F' },                // greenyellow
            { name: 'colorWarning', type: 'color', value: '#FFA500' },              // orange
            { name: 'colorError', type: 'color', value: '#DC143C' }                 // crimson
        ],
    };

    private current = structuredClone(this.default);

    public get theme() {

        return this.current;
    }

    public set theme(value: Theme) {

        if (!value.properties) {
            return;
        }

        this.current = value;

        this.apply();
    }

    public apply(): void {

        for (const item of this.current.properties) {

            let property = '--';

            // Convert json notation to css variable notation
            // Eg: colorBackground0 -> --color-background-0
            for (const itemChar of item.name) {
                if (itemChar.toLowerCase() !== itemChar || !isNaN(parseInt(itemChar, 10))) {
                    property += '-' + itemChar.toLowerCase();
                } else {
                    property += itemChar;
                }
            }

            document.body.style.setProperty(property, item.value /*, 'important'*/);
        }

        this.save();
    }

    public reset(): void {

        this.theme = {... this.default};

        this.apply();
    }

    private save(): void {

        localStorage.setItem(STORAGE_NAME_THEME, JSON.stringify(this.current));
    }

    public load(): void {

        console.log('ThemeService: Loading');

        const stored = localStorage.getItem(STORAGE_NAME_THEME);

        if (!stored) {

            console.log('ThemeService: No data to load');

            this.reset();

            return;
        }

        this.current = JSON.parse(stored) as Theme;

        this.apply();
    }

}


