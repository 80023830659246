import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OnlineService } from '../../services/online/online.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor(private onlineService: OnlineService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

        const token = this.onlineService.token();

        // Intercept request
        if (token) {

            // Inject x-access-token and authorization headers with the current jwt
            request = request.clone({
                setHeaders: {
                    'x-access-token': token,
                    'Authorization': `Bearer ${ token }`
                }
            });
        }

        return next.handle(request);
    }
}
