import { Injectable } from '@angular/core';
import { IpcRenderer } from 'electron';

@Injectable({
    providedIn: 'root'
})

export class ElectronService {

    private ipcRender: IpcRenderer | undefined;

    constructor() {

        if (!window.require) {

            console.log(`ElectronService: IPC was not loaded`);

            return;
        }

        try {

            this.ipcRender = window.require('electron').ipcRenderer;

            console.log(`ElectronService: Ready`);

        } catch (error) {

            console.log(`ElectronService: Not available: ${error}`);

            return;
        }

        this.on('message', (event: any, data: any) => {
            console.log(`ElectronService: From electron`, data);
        });
    }

    public send(channel: string, ...args: any[]) {

        if (this.ipcRender) {
            this.ipcRender.send(channel, args);
        }
    }

    public on(channel: string, listener: any): void {

        if (this.ipcRender) {
            this.ipcRender.on(channel, listener);
        }
    }

    public get isElectron(): boolean {

        return navigator.userAgent.indexOf('Electron') >= 0;
    }
}
