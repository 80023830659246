import { EventEmitter } from '@angular/core';
import { GatewayConnectorsNames } from '../../../enums/gateway.enum';
import { GatewayConnector } from './connector.interface';
import { GatewayState } from '../gateway.service';

export class SimulationGatewayConnector implements GatewayConnector {

    public onReceive = new EventEmitter<string>;
    public onConnect = new EventEmitter<void>;
    public onConnectFail = new EventEmitter<void>;
    public onDisconnect = new EventEmitter<void>;

    private _isConnected = false;

    public get name(): GatewayConnectorsNames {
        return GatewayConnectorsNames.Simulation;
    }

    public async connect(): Promise<void> {

        if (this._isConnected) {
            return;
        }

        // Simulate immediate connection
        this.onConnectHandler();
    }

    public async disconnect(): Promise<void> {

        if (!this._isConnected) {
            return;
        }

        // Simulate immediate disconnection
        this.onDisconnectHandler();
    }

    public async send(data: string): Promise<void> {

        // Simulate response to state request
        if (data.startsWith('state?')) {

            const state: GatewayState = {
                id: 'virtual-device-000',
                hardware: '0.0.0',
                software: 'X.X.X',
                isCompatible: true, // ?
                sd: {
                    version: 'X.X.X',
                    description: '',
                    language: '',
                    isCompatible: true // ?
                }
            };

            this.onReceiveHandler('state ' + JSON.stringify(state));
        }
    }

    public get isConnected(): boolean {

        return this._isConnected;
    }

    private onReceiveHandler(data: string): void {

        this.onReceive.emit(data);
    }

    private onConnectHandler(): void {

        this._isConnected = true;

        this.onConnect.emit();
    }

    private onDisconnectHandler(): void {

        this._isConnected = false;

        this.onDisconnect.emit();
    }

}
