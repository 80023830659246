import { LoraDevice, OperatorUnderOperation, TeamUnderOperation } from '@lightning/lightning-definitions';



export enum LongRangeProtocolsNames {
    Json = 'json',
    Comptact = 'compact',
}


export interface NfcData {
    sender: LoraDevice;
    record: string;
}

export interface NfcOperatorData {
    sender: LoraDevice;
    operator: OperatorUnderOperation;
    team?: TeamUnderOperation;
}

export interface NfcCashupData extends NfcOperatorData {
    total: number;
}

export interface NfcPartData extends NfcOperatorData {
    number: number;
}

export interface NfcSpecialData extends NfcOperatorData {
    special: string;
}
