import { Expose } from 'class-transformer';
import { IsString, IsOptional, ApiProperty, IsMongoId } from '@lightning/decorators';
import { PaginedRequest, PaginedResponse } from './pagined.dto';
import { Feedback } from '../entities/feedback.entity';

export class FeedbacksSearchRequest extends PaginedRequest {

    @ApiProperty()
    @Expose()
    @IsOptional()
    @IsMongoId()
    public id?: string;

    @ApiProperty()
    @Expose()
    @IsOptional()
    public created?: string;

}

export class FeedbacksSearchResponse extends PaginedResponse {

    @ApiProperty()
    public items!: Array<Feedback>;
}

export class FeedbackCreateRequest {

    @ApiProperty()
    @Expose()
    @IsString()
    public description!: string;

    @ApiProperty()
    @Expose()
    @IsString()
    @IsOptional()
    public screenshot?: string;

    @ApiProperty()
    @IsOptional()
    public context?: any;
}

export class FeedbackCreatedResponse {

    @ApiProperty()
    public id!: string;
}
