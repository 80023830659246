<app-body>
    <wui-section [name]="'shared.objectives' | translate">
        {{ 'apps.operations.modes.assembly.objectives' | translate }}
    </wui-section>
    <wui-section [name]="'shared.rules' | translate">
        {{ 'apps.operations.modes.assembly.rules' | translate }}
    </wui-section>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="assemblyService.setup()">{{
            'shared.back' | translate
        }}</wui-button>
        <wui-button (click)="assemblyService.briefSpeech()">{{
            'shared.speech' | translate
        }}</wui-button>
        <wui-button (click)="assemblyService.deploy()">{{
            'shared.deploy' | translate
        }}</wui-button>
    </wui-buttons>
</app-footer>
