import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { PmrSounds } from '@lightning/lightning-definitions';

import { PmrService } from '../../..//shared/services/pmr/pmr.service';
import { RegisterService } from '../../../shared/services/register/register.service';

@Component({
    selector: 'app-sandbox',
    templateUrl: './sandbox.component.html',
    styleUrls: ['./sandbox.component.scss']
})
export class SandboxComponent implements OnDestroy {

    public sounds = PmrSounds;
    public broadcastChannel: string;
    public rxChannel: string;
    public txChannel: string;
    public track = 0;

    public isLoopPlaying = false;

    private onStateChangeSubscription: Subscription;

    constructor(
        public pmrService: PmrService,
        public registerService: RegisterService) {

        this.broadcastChannel =
        this.rxChannel =
        this.txChannel = this.pmrService.broadcastChannel;

        this.onStateChangeSubscription = this.pmrService.onTransmittingChange.subscribe(state => this.pmrOnTransmittingChange(state));
    }

    ngOnDestroy(): void {
        this.onStateChangeSubscription.unsubscribe();
    }

    private pmrOnTransmittingChange(state: any): void {

        // Loop playing
        if (this.isLoopPlaying && state.isTransmitting === false && state.playQueue === 0) {
            this.pmrService.play(this.track, this.txChannel);
        }
    }

    public applyRxChannel(): void {

        this.pmrService.rxChannel = this.rxChannel;
    }

    public applyTxChannel(): void {

        this.pmrService.txChannel = this.txChannel;
    }

    public play(): void {

        this.pmrService.play(this.track, this.txChannel);
    }

    public loopPlay(): void {

        this.isLoopPlaying = ! this.isLoopPlaying;

        if (this.isLoopPlaying) {
            this.pmrService.play(this.track, this.txChannel);
        }
    }

    public successivePlay(): void {

        this.pmrService.announce(PmrSounds.Caution);
        this.pmrService.announce(PmrSounds.OperationStarted);
    }

    public stop(): void {

        this.pmrService.stop();
    }

    public startTransmission(): void {

        this.pmrService.startTransmission(this.txChannel);
    }

    public endTransmission(): void {

        this.pmrService.endTransmission();
    }

}
