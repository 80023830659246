<app-header *ngIf="description" [logo]="logo" [description]="description"></app-header>

<app-body>
    <wui-section>
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.wifi.ssid.label' | translate | capitalize }}</div>
            <div detail>{{ 'apps.settings.generalities.wifi.ssid.detail' | translate | capitalize }}</div>
            <wui-input value
                [(value)]="credentials.ssid"
                [suggestions]="gatewayService.wifiNetworks"
                type="text"
                (valueChange)="ssidChanged()">
            </wui-input>
        </wui-entry>
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.wifi.password.label' | translate | capitalize }}</div>
            <wui-input value
                [(value)]="credentials.password"
                type="password"
                autocomplete="new-password">
            </wui-input>
        </wui-entry>
    </wui-section>
</app-body>

<app-footer>
    <wui-buttons>
        <wui-button
            (click)="cancelClick()">
            {{ 'shared.cancel' | translate }}
        </wui-button>
        <wui-button
            [wuiDisabled]="credentials.ssid === ''"
            (click)="confirmClick()">
            {{ 'shared.confirm' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
