import { Component, OnInit } from '@angular/core';
import { SandboxService } from '../../services/sandbox.service';

@Component({
  selector: 'app-sandbox-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

  constructor(public sandboxService: SandboxService) { }

  ngOnInit(): void {
  }

}
