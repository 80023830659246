import { Component } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';

import { RegisterService } from '../../../../../shared/services/register/register.service';
import { LoraProtocolService } from '../../../../../shared/services/lora-protocol/lora-protocol.service';
import { OperationStates } from '../../../shared/enums/operation.enums';
import { PrisonersService } from '../../services/prisoners.service';

@Component({
    selector: 'app-prisonners-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss'],
})
export class ProcessComponent  {

    OperationStates = OperationStates;

    constructor(
        public environmentService: EnvironmentService,
        public onlineService: OnlineService,
        public registerService: RegisterService,
        public LoraProtocolService: LoraProtocolService,
        public prisonersService: PrisonersService
    ) {}
}
