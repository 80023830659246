import { Injectable } from '@angular/core';
import { AppsUtils } from '@lightning/configuration';
import { ProductsWithSoftware } from '@lightning/lightning-definitions';

@Injectable({
    providedIn: 'root'
})
export class WebsiteService {

    private url;

    constructor() {

        this.url = AppsUtils.resolveAppUrl('website');
    }

    public openSoftwarePage(product?: ProductsWithSoftware): void {

        if (navigator.onLine === false) {
            return;
        }

        window.open(`${this.url}/downloads#${product || ''}`, `_blank`);
    }

    public openReleaseNotes(): void {

        window.open(`${this.url}/updates/release-notes`, `_blank`); // TODO
    }

}
