import { Component, Input } from '@angular/core';

import { LoraDevice, ProductsWithSoftware, TeamUnderOperation } from '@lightning/lightning-definitions';

@Component({
    selector: 'lui-operation-areas-captures',
    templateUrl: './operation-areas-captures.component.html',
    styleUrls: ['./operation-areas-captures.component.scss']
})

export class OperationAreasCapturesComponent {

    @Input()
    public devices: Array<LoraDevice> = [];

    @Input()
    public teams: Array<TeamUnderOperation> = [];

    public get groundModules(): Array<LoraDevice> {

        return this.devices
            .filter(device => device['state']?.product === ProductsWithSoftware.GroundModule && device.isUsable && device.isEnabled)
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }

    public getTeam(item: LoraDevice): TeamUnderOperation | undefined {

        if (!item.data?.teamId) {
            return;
        }

        return this.teams.find(team => team.id === item.data.teamId);
    }

}
