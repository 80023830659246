import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';
import { AppsUtils } from '@lightning/configuration';
import { LoginRequest } from '@lightning/lightning-definitions';
import { StyleUtils } from '@lightning/wild-ui';

@Component({
    selector: 'app-modal-online-login',
    templateUrl: './modal-online-login.component.html',
    styleUrls: ['./modal-online-login.component.scss']
})

export class ModalOnlineLoginComponent {

    public loginRequest = new LoginRequest();

    constructor(
        public translateService: TranslateService,
        public onlineService: OnlineService,
        private environmentService: EnvironmentService) { }

    public login(): void {
        this.onlineService.login(this.loginRequest)
            .subscribe({
                error: () => {
                    this.environmentService.notificationOpen({
                        logo: 'assets/apps/online/logo.svg',
                        message: this.translateService.instant('modals.onlineLogin.loginFailed'),
                        color: StyleUtils.getVariable('--color-error')
                    });
                }
            });
    }

    public logout(): void {
        this.onlineService.logout();
    }

    public signin(): void {
        window.open(AppsUtils.resolveAppUrl('website'));
    }

    public close(): void {
        this.environmentService.modalClose();
    }
}
