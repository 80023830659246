import { Component, OnInit } from '@angular/core';

import { ProductsWithSoftware } from '@lightning/lightning-definitions';

import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

    public targets: Array<string | undefined> = [];

    constructor(private loraProtocolService: LoraProtocolService) { }

    ngOnInit(): void {
        this.targets = this.loraProtocolService
            .getDevicesByProduct(ProductsWithSoftware.GroundModule)
            .map(item => item.name);
    }

}
