import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorHandlerService } from './services/errors-handler/errors-handler.service';

@NgModule({
    // providers: [
    //     {
    //         provide: ErrorHandler,
    //         useClass: ErrorHandlerService,
    //     },
    // ],
    declarations: [],
    imports: [
        CommonModule
    ]
})

export class CoreModule { }
