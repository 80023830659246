<app-body>

    <!-- operations -->
    <wui-section [name]="'apps.settings.generalities.operations.title' | translate">

        <wui-entry>
            <div name>{{ 'apps.settings.generalities.operations.timeForDeployment.label' | translate }}</div>
            <!-- <div detail>{{ 'apps.settings.generalities.operations.timeForDeployment.detail' | translate }}</div> -->
            <wui-select value [(value)]="settingsService.settings.operations.timeForDeployment" (change)="settingsService.save()">

            @for(item of OperationDeploymentDurations | keyValue; track item) {
                <option [value]="item.value">{{ 'apps.operations.setup.durations.' + item.key | translate }}</option>
            }
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'apps.settings.generalities.operations.timeLimit.label' | translate }}</div>
            <!-- <div detail>{{ 'apps.settings.generalities.operations.timeLimit.detail' | translate }}</div> -->
            <wui-select value [(value)]="settingsService.settings.operations.timeLimit" (change)="settingsService.save()">
            @for(item of OperationProcessDurations | keyValue; track item) {
                <option [value]="item.value">{{ 'apps.operations.setup.durations.' + item.key | translate }}</option>
            }
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'apps.settings.generalities.operations.briefingAnnounce.label' | translate }}</div>
            <wui-input value
                [(value)]="settingsService.settings.operations.isBriefingAnnounceEnabled"
                type="checkbox"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'apps.settings.generalities.operations.deployingAnnounce.label' | translate }}</div>
            <wui-input value
                [(value)]="settingsService.settings.operations.isDeployingAnnounceEnabled"
                type="checkbox"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>

    </wui-section>

    <wui-section [name]="'apps.settings.generalities.gateway.title' | translate">
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.gateway.connection.label' | translate }}</div>
            <!-- <div detail>{{ 'apps.settings.generalities.gateway.connection.detail' | translate }}</div> -->
            <wui-select value [(value)]="settingsService.settings.gateway.connector" (change)="settingsService.save()">
            @for(item of GatewayConnections | keyValue; track item) {
                <option [value]="item.value">{{ item.key }}</option>
            }
            </wui-select>
        </wui-entry>

        <wui-entry *ngIf="settingsService.settings.gateway.connector === GatewayConnections.Websocket">
            <div name>{{ 'apps.settings.generalities.gateway.websocketUrl.label' | translate }}</div>
            <div detail>{{ 'apps.settings.generalities.gateway.websocketUrl.detail' | translate }}</div>
            <wui-input value
                [(value)]="settingsService.settings.gateway.websocketUrl"
                type="text"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'apps.settings.generalities.longRange.title' | translate" developpersOnly>
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.longRange.protocol.label' | translate }}</div>
            <div detail>{{ 'apps.settings.generalities.longRange.protocol.detail' | translate }}</div>
            <wui-select value [(value)]="settingsService.settings.longRange.protocol" (change)="settingsService.save()">
            @for(item of LongRangeProtocols | keyValue; track item) {
                <option [value]="item.value">{{ item.key }}</option>
            }
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'apps.settings.generalities.pmr.title' | translate">
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.pmr.broadcastChannel.label' | translate }}</div>
            <!-- <div detail>{{ 'apps.settings.generalities.pmr.broadcastChannel.detail' | translate }}</div> -->
            <wui-input value
                [(value)]="settingsService.settings.pmr.broadcastChannel"
                type="text"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'apps.settings.generalities.wifi.title' | translate">
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.wifi.ssid.label' | translate }}</div>
            <div detail>{{ 'apps.settings.generalities.wifi.ssid.detail' | translate }}</div>
            <wui-input value
                [(value)]="settingsService.settings.wifi.ssid"
                [suggestions]="gatewayService.wifiNetworks"
                type="text"
                (change)="settingsService.save()"
                (valueChange)="wifiSsidChanged()">
            </wui-input>
        </wui-entry>
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.wifi.password.label' | translate }}</div>
            <wui-input value
                [(value)]="settingsService.settings.wifi.password"
                type="password"
                autocomplete="new-password"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'apps.settings.generalities.environment.title' | translate">
        <wui-entry>
            <div name>{{ 'apps.settings.generalities.environment.audioVolume.label' | translate }}</div>
            <div detail>{{ 'apps.settings.generalities.environment.audioVolume.detail' | translate }}</div>

            <wui-select value [(value)]="environmentAudioVolume" (valueChange)="environmentAudioVolumeChanged()">
                <option [value]="0">{{   'shared.disabled' | translate | capitalize }}</option>
                <option [value]="5">{{   'shared.low'      | translate | capitalize }}</option>
                <option [value]="25">{{  'shared.medium'   | translate | capitalize }}</option>
                <option [value]="100">{{ 'shared.high'     | translate | capitalize }}</option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'apps.settings.generalities.advanced.title' | translate">

        <wui-entry>
            <div name>{{ 'apps.settings.generalities.advanced.updatesChannel.label' | translate }}</div>
            <div detail>{{ 'apps.settings.generalities.advanced.updatesChannel.detail' | translate }}</div>
            <wui-select value [(value)]="settingsService.settings.advanced.updatesChannel" (change)="settingsService.save()">
                @for(item of SoftwaresChannels | keyValue; track item) {
                    <option [value]="item.value">{{ item.key }}</option>
                }
                </wui-select>
        </wui-entry>

        <wui-entry>
            <div name>{{ 'apps.settings.generalities.advanced.developpersMode.label' | translate }}</div>
            <div detail>{{ 'apps.settings.generalities.advanced.developpersMode.detail' | translate }}</div>
            <wui-input value
                [(value)]="settingsService.settings.advanced.developpersMode"
                type="checkbox"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>

        <wui-entry wuiElectronOnly>
            <div name>{{ 'apps.settings.generalities.advanced.developpersToolsElectron.label' | translate }}</div>
            <!-- <div detail>{{ 'apps.settings.generalities.advanced.developpersToolsElectron.detail' | translate }}</div> -->
            <wui-input value
                [(value)]="settingsService.settings.advanced.developpersToolsElectron"
                type="checkbox"
                (change)="settingsService.save()">
            </wui-input>
        </wui-entry>

    </wui-section>

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="settingsService.reset()">{{ 'shared.reset' | translate }}</wui-button>
        <wui-button (click)="restart()">{{ 'shared.restart' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
