import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { ConvoyService } from '../../services/convoy.service';

@Component({
    selector: 'app-convoy-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss']
})
export class DebriefingComponent {

    constructor(
        public onlineService: OnlineService,
        public operationService: ConvoyService) { }
}
