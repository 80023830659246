<app-body>
    <wui-section name="{{ 'apps.online.register' | translate }}">
        <wui-entry>
            <div name>{{ 'apps.online.email' | translate | capitalize }}</div>
            <wui-input value type="email" [(value)]="loginRequest.email"></wui-input>
        </wui-entry>
        <wui-entry>
            <div name>{{ 'apps.online.password' | translate | capitalize }}</div>
            <wui-input value type="password" [(value)]="loginRequest.password"></wui-input>
        </wui-entry>
    </wui-section>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button value (click)="signin()">{{ 'apps.online.register' | translate }}</wui-button>
        <wui-button value (click)="login()">{{ 'apps.online.login' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
