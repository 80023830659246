import { Component, OnInit } from '@angular/core';
import { DominationService } from '../../services/domination.service';

@Component({
  selector: 'app-domination-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

  constructor(public dominationService: DominationService) { }

  ngOnInit(): void {
  }

}
