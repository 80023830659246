import { Component } from '@angular/core';
import { WarmupService } from '../../services/warmup.service';

@Component({
    selector: 'app-warmup-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.scss'],
})
export class BriefingComponent {

    constructor(public warmupService: WarmupService) {}
}
