<app-body>

    <ng-container *ngIf="registerService.teams.length > 0">
        <wui-section *ngFor="let team of registerService.teams" [name]="team.name">
            <wui-entry>
                <div name>{{ 'shared.color' | translate | capitalize }}</div>
                <div value [style.color]="team.color">▦</div>
            </wui-entry>
            <wui-entry>
                <div name>{{ 'apps.register.pmrChannel' | translate | capitalize }}</div>
                <div value>{{ team.pmr }}</div>
            </wui-entry>
            <wui-entry>
                <div name>{{ 'apps.register.operatorsCount' | translate | capitalize }}</div>
                <div value>{{ team.operatorsCount }} / {{ registerService.assignedOperatorsCount }}</div>
            </wui-entry>
            <!-- <wui-entry developpersOnly>
                <div name>Raw data</div>
                <wui-json [value]="team"></wui-json>
            </wui-entry> -->
        </wui-section>
    </ng-container>

    <wui-placeholder *ngIf="registerService.teams.length === 0">
        {{ 'apps.register.noTeamsPlaceholder' | translate }}
    </wui-placeholder>

</app-body>
