<!-- ci/cd -->
@if(cicd.tag || cicd.pipeline) {
    <div class="icon" [wuiDisabled]="true">
        #{{ cicd.tag || cicd.pipeline }}
    </div>
}

@if(gatewayService.isStarted) {

    <!--- ground connected-->
    @if(gatewayService.isConnected) {

        <!-- Lora service -->
        <div class="icon"
            [class.warning]="loraProtocolService.isWarning"
            (click)="environmentService.windowOpenByAppId('lora')">
                LR {{ loraProtocolService.devices.length > 0 ? ' ' + loraProtocolService.devices.length : '' }}
        </div>

        <!-- pmr -->
        <div class="icon"
            [class.transmitting]="pmrService.state.isTransmitting"
            (click)="environmentService.windowOpenByAppId('pmr')">
                PMR {{ pmrService.state.isTransmitting ? pmrService.state.txChannel : ''}}
        </div>

    } @else {

        <!-- ground not connected-->
        <div
            class="icon"
            [class.simulating]="gatewayService.connectorName === GatewayConnectorsNames.Simulation"
            [title]="gatewayService.connectorName"
            (click)="environmentService.windowOpenByAppId('gateway')">
            GATEWAY
        </div>
    }
}

<!-- online -->
<div class="icon"
    (click)="environmentService.windowOpenByAppId('online')">
    {{ onlineService.isAvailable() ? (onlineService.isTokenValid() ? 'CONNECTED' : 'LOGGED OUT') : 'OFFLINE' }}
</div>

<!-- battery -->
@if(batteryService.battery) {
    <div class="icon battery"
        [class.warning]="batteryService.battery.level > 0.1 && batteryService.battery.level < 0.3"
        [class.error]="batteryService.battery.level <= 0.1">
        {{ batteryService.battery.level | percent }}
    </div>
}
