import { Injectable } from '@angular/core';
import {
    TeamUnderOperation,
    OperationWinner,
    OperationTrophy,
    PmrSounds,
    ProductsWithSoftware,
    OperationActions,
    OperatorAvatarName,
    OperationTrophiesTypes,
} from '@lightning/lightning-definitions';
import {
    NfcData,
    NfcOperatorData,
    NfcCashupData,
    NfcSpecialData,
    NfcPartData,
} from '@lightning/headquarter/app/apps/shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import {
    OperationRequierements,
    OperationRequierement,
} from '../../shared/interfaces/operation.interface';
import { Assemblies } from '../enums/assembly.enums';


@Injectable({
    providedIn: 'root',
})
export class AssemblyService extends OperationBase {

    constructor() {

        super('assembly');

        // Define operation settings
        this.settings = {
            version: 1,

            name: 'Assembly op',
            timeLimit: 1800000,

            object: Assemblies.Missile
        };

        // Define organisator actions
        this.organisatorActions = [this.getOrganisatorActionNote()];

        this.loraProtocolService.onNfcPartReceive
            .subscribe((data: NfcPartData) => { this.onNfcPartReceive(data); });

        this.loraProtocolService.onNfcSpecialReceive
            .subscribe((data: NfcSpecialData) => { this.onNfcSpecialReceive(data); });
    }

    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(ProductsWithSoftware.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2,
                help: '',
            },
            {
                name: 'Ground modules',
                value: groundModules.length.toString(),
                isReady: groundModules.length >= this.registerService.teams.length,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements
            .some((requierement) => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        for (const partsNumbers of this.settings.partsNumbers) {
            if(partsNumbers === null) {
                return false;
            }
        }

        return true;
    }

    public override settingsApply(): void {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Parts for teams setup
        this.settings.partsNumbers.forEach((item: string, index: number) => {

            const steps = item
                .split(' ')
                .map((number: string) => ({
                    isDone: false,
                    number: parseInt(number, 10)
                }));

            this.registerService.teamsSlots[index].data.steps = steps;
        });

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return this.getWinnerByProgress();
    }

    public override getTrophies(): Array<OperationTrophy> {

        const trophies: Array<OperationTrophy> = [];

        let operators = this.registerService.operators.filter(operator => operator.data.stats);

        // 2 active operators or more
        if (operators.length < 2) {
            return trophies;
        }

        // First one
        trophies.push({
            type: OperationTrophiesTypes.AssemblyFirstOne,
            data: {
                operator: operators.find(operator => operator.data.stats?.isFirst)
            }
        });

        // Biggest total without equality
        operators = operators.sort((a, b) => { return (b.data.stats?.total || 0) - (a.data.stats?.total || 0) });

        if((operators[0].data.stats?.total || 0) > (operators[1].data.stats?.total || 0)) {
            trophies.push({
                type: OperationTrophiesTypes.AssemblyBiggestTotal,
                data: {
                    operator: operators[0]
                }
            });
        }

        return trophies;
    }

    private async onNfcPartReceive(data: NfcPartData): Promise<void> {

        const { sender, operator, number } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        if (!team.data.steps) {
            return;
        }

        const step = team.data.steps
            .find(part => part.number === number);

        // Part not assigned to this team
        if (!step) {

            this.environmentService.notificationOpen({
                logo: `assets/apps/operations/logo.svg`,
                message: `The operator ${operator.name} is tring to use the part number ${ number } which is not for its team!`,
                color: `yellow`
            });

            return;
        }

        // This step was already done
        if (step.isDone) {

            this.environmentService.notificationOpen({
                logo: `assets/apps/operations/logo.svg`,
                message: `The operator ${operator.name} is tring to use again the part number ${ number } which is already assembled!`,
                color: `yellow`
            });

            return;
        }

        // Set the step as done
        step.isDone = true;

        // Store data for trophies calculations
        const isFirst: boolean =
            this.timeline.filter(item => item.action === OperationActions.PartAssembled).length === 0;

        operator.data.stats = operator.data.stats || { isFirst: false, total: 0 };
        operator.data.stats.isFirst = isFirst ? true : operator.data.stats.isFirst;
        operator.data.stats.total = (operator.data.stats.total || 0) + 1;

        this.timelinePush({
            level: 1,
            action: OperationActions.PartAssembled,
            data: {
                operator, team, area: sender.name, number
            },
            customIconPath:
                this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
        });

        // Announce via PMR
        this.pmrService.announce(PmrSounds.AssemblyPart, team);

        // There is still steps to do
        if (team.data.steps.some(step => step.isDone === false)) {

            return;
        }

        // Announce via PMR
        this.pmrService.announce(PmrSounds.AssemblyCompleteMissile);

        this.complete();
    }

    private async onNfcSpecialReceive(data: NfcSpecialData): Promise<void> {

        const { sender, operator, team, special } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        if (!team) {
            return;
        }

        // [ CUSTOM LOGIC HERE ]
    }
}
