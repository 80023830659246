import { Component, OnInit } from '@angular/core';

import { FilesUtils } from '@lightning/utils';
import { ThemeService } from '@lightning/wild-environment';

@Component({
    selector: 'app-theme',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit {

    constructor(public themeService: ThemeService) { }

    ngOnInit(): void {
    }

    public export(): void {

        FilesUtils.save(JSON.stringify(this.themeService.theme , null, 4), 'theme.json');
    }

    public async import(): Promise<void> {

        // Get data from file
        let data: any = await FilesUtils.load('application/json');

        // Parse with careful for date
        data = JSON.parse(data as string);

        this.themeService.theme = data;
    }

}
