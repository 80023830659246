import { Component, OnInit, Input } from '@angular/core';
import { App, Window } from '@lightning/wild-environment';
import { Tabs } from '@lightning/wild-ui';
import { TranslateService } from '@ngx-translate/core';

import { SettingsService } from '../../../shared/services/settings/settings.service';
import { GeneralitiesComponent } from '../generalities/generalities.component';
import { ThemeComponent } from '../theme/theme/theme.component';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.scss']
})

export class SettingsComponent implements App, OnInit {

    @Input() public window: Window | undefined;

    public tabs: Tabs = {
        items: [
            {
                selector: 'generalities',
                text: this.translateService.instant('apps.settings.generalities.title'),
                component: GeneralitiesComponent
            },
            {
                selector: 'theme',
                text: this.translateService.instant('apps.settings.theme.title'),
                component: ThemeComponent
            },
        ]
    };

    constructor(
        public settingsService: SettingsService,
        private translateService: TranslateService) { }

    ngOnInit() {
        this.tabs.selected = this.tabs.items[0];
    }

}
