<app-container>

    <app-header [logo]="'assets/apps/online/logo.svg'"
                description="Utilisez les fonctionnalités en ligne pour échanger avec la communauté d'opérateurs."></app-header>

    @if(onlineService.isTokenValid()) {
        <app-account></app-account>
    } @else {
        <app-login></app-login>
    }

</app-container>
