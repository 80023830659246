<app-header *ngIf="description" [logo]="logo" [description]="description"></app-header>

<wui-cards>
    <wui-card *ngFor="let item of items"
     [title]="item.title"
     [logo]="item.logo"
     [description]="item.description"
     [data]="item.data"
     (click)="itemClick(item)"></wui-card>
</wui-cards>

<app-footer>
    <wui-buttons>
        <wui-button (click)="cancelClick()">cancel</wui-button>
    </wui-buttons>
</app-footer>
