import { FilesUtils, JSONUtils } from '@lightning/utils';
import { OperationReport, OperationReportCreatedResponse } from '@lightning/lightning-definitions';

import { AppsUtils } from '@lightning/configuration';
import { DatePipe } from '@angular/common';
import { EnvironmentService } from '@lightning/wild-environment';
import { Injectable } from '@angular/core';
import { ModalYesNoComponent } from '../../components/modal-yes-no/modal-yes-no.component';
import { OnlineService } from '@lightning/lightning-services';
import { TranslateService } from '@ngx-translate/core';
import { ModalOnlineLoginComponent } from '../../components/modal-online-login/modal-online-login.component';

const STORAGE_NAME = 'operations-reports';

@Injectable({
    providedIn: 'root'
})

export class ReportsService {

    private _reports: Array<OperationReport> = [];

    constructor(
        private translateService: TranslateService,
        private environmentService: EnvironmentService,
        private onlineService: OnlineService,
        private datePipe: DatePipe) {

            this.load();
    }


    public get reports(): Array<OperationReport> {

        return this._reports;
    }

    public export(report: OperationReport): void {

        const name = `${report.begin.toISOString().substring(0, 10)} operation report.json`;

        FilesUtils.save(JSON.stringify(report, null, 4), name);
    }

    public async import(): Promise<void> {

        try {

            const report = JSON.parse((await FilesUtils.load('.json')) as string, JSONUtils.dateReviver);

            if (!report.mode || !report.settings || !report.timeline) {

                this.environmentService.notificationOpen({
                    message: this.translateService.instant('services.reports.import.errorContent'),
                    color: 'red'
                });

                return;
            }

            this._reports.push(report);

            this.save();

            const name = this.datePipe.transform(report.begin);

            this.environmentService.notificationOpen({
                message: this.translateService.instant('services.reports.import.success', { name })
            });

        } catch(error) {

            this.environmentService.notificationOpen({
                message: this.translateService.instant('services.reports.import.errorRead'),
                color: 'red'
            });
        }
    }


    public add(report: OperationReport): void {

        if (report.timeline.length === 0) {

            console.log('Reports service: Save: Empty timeline, saving aborted');

            return;
        }

        this._reports.push(report);

        this.save();
    }

    public async remove(report: OperationReport) {

        // Ask confirmation for not uploaded reports
        if (report.id === undefined) {

            const result = await this.environmentService.modalOpen({
                title: this.translateService.instant('shared.remove'),
                component: ModalYesNoComponent,
                inputs: {
                    description: this.translateService.instant('services.reports.removeNotUploaded'),
                    logo: 'assets/apps/reports/logo.svg'
                }
            });

            if (result !== true) {
                return;
            }
        }

        this._reports = this._reports.filter(r => r !== report);

        this.save();
    }

    public uploadAll(): void {

        const reports: Array<OperationReport> = this._reports.filter(report => !report.id);

        for (const report of reports) {

            this.upload(report);
        }
    }

    public async upload(report: OperationReport): Promise<void> {

        if (!this.onlineService.isAvailable) {
            return;
        }

        if (!this.onlineService.isTokenValid()) {

            const result = await this.environmentService.modalOpen({
                title: this.translateService.instant('services.reports.upload.title'),
                component: ModalYesNoComponent,
                inputs: {
                    logo: 'assets/apps/online/logo.svg',
                    description: this.translateService.instant('services.reports.upload.onlineLoginRequired'),
                }
            });

            if (result) {
                this.environmentService.modalOpen({ title: '', component: ModalOnlineLoginComponent });
            }

            return;
        }

        this.onlineService.uploadOperationReport(report)
            .subscribe((result: OperationReportCreatedResponse) => {

                // Apply the id provided by the backend
                report.id = result.id;

                const name = this.datePipe.transform(report.begin);

                this.environmentService.notificationOpen({
                    message: this.translateService.instant('services.reports.upload.success', { name }),
                    logo: 'assets/apps/reports/logo.svg'
                });

                this.save();

                console.log(result);
            });
    }

    public openOnline(report: OperationReport): void {

        window.open(`${AppsUtils.resolveAppUrl('website')}/operations/reports/${report.id}`);
    }

    public async clear(): Promise<void> {

        // Confirm if there is reports not uploaded yet
        if (this.reports.some(report => report.id === undefined)) {

            const result = await this.environmentService.modalOpen({
                title: this.translateService.instant('services.reports.clear.title'),
                component: ModalYesNoComponent,
                inputs: {
                    logo: 'assets/apps/online/logo.svg',
                    description: this.translateService.instant('services.reports.clear.description'),
                }
            });

            if (result !== true) {
                return;
            }
        }

        this._reports = [];

        this.save();

    }



    private save(): void {

        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            reports: this.reports
        }));
    }

    private load(): void {

        const stored = localStorage.getItem(STORAGE_NAME);

        if (!stored) {
            return;
        }

        const data = JSON.parse(stored, JSONUtils.dateReviver);

        if (data.reports?.length > 0) {
            this._reports = data.reports;
        }
    }


}
