import { Component, OnInit } from '@angular/core';
import { ConvoyService } from '../../services/convoy.service';

@Component({
    selector: 'app-convoy-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.scss'],
})
export class BriefingComponent implements OnInit {

    constructor(public convoyService: ConvoyService) { }

    ngOnInit() {
    }

}
