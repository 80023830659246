<app-container>

    <app-header
        [logo]="'assets/apps/about/logo.svg'"
        [description]="'apps.about.headerDescription' | translate"></app-header>

    <app-body>
        <!--<wui-placeholder>...</wui-placeholder>     -->

        <wui-section name="...">
            <wui-entry>
                <div name>{{ "apps.about.name" | translate }}</div>
                <div value></div>
            </wui-entry>

        </wui-section>

    </app-body>

</app-container>
