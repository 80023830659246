<app-body>
    <wui-section [name]="'shared.generalities' | translate ">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate | capitalize }}</div>
            <wui-input
                value
                type="text"
                [(value)]="prisonersService.settings.name"
            ></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate ">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate | capitalize }}</div>
            <wui-select value [(value)]="prisonersService.settings.timeLimit">
                <option *ngFor="let item of OperationProcessDurations | keyValue" [value]="item.value">{{ 'apps.operations.setup.durations.' + item.key | translate }}</option>
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section name="prisons">
        <wui-entry *ngFor="let team of registerService.teams">
            <div name>{{ team.name }}</div>
            <wui-select value [(value)]="team.data.prison" [required]="true">
                <option *ngFor="let location of groundLocations" [value]="location">{{ location }}</option>
            </wui-select>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="prisonersService.preview()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button
            (click)="prisonersService.brief()"
            [wuiDisabled]="prisonersService.settingsAreValid() === false"
            >{{ 'shared.confirm' | translate }}</wui-button
        >
    </wui-buttons>
</app-footer>
