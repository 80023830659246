import { effect, Injectable } from '@angular/core';
import { cicd } from '@lightning/configuration';
import { environment } from '@lightning/headquarter/environments/environment';
import { FeedbackCreatedResponse, FeedbackCreateRequest } from '@lightning/lightning-definitions';
import { OnlineService } from '@lightning/lightning-services';
import { EnvironmentService } from '@lightning/wild-environment';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, tap } from 'rxjs';
import { SettingsService } from '../../../shared/services/settings/settings.service';
import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { RegisterService } from '../../../shared/services/register/register.service';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';

const STORAGE_NAME = 'feedbacks';
const STORAGE_VERSION = 1;
const STORAGE_LIMIT = 5;

@Injectable({
    providedIn: 'root'
})
export class FeedbacksService {

    public feedbacks: Array<FeedbackCreateRequest> = [];

    private onlineAvailableEffect = effect(() => {

        if (this.onlineService.isAvailableAndLoggedIn()) {
            this.sendAllPendings()
                .pipe(tap(() =>
                    this.environmentService.notificationOpen({
                        message: this.translateService.instant('services.feedbacks.savedSent'),
                    })
                ))
                .subscribe();
        }
    });

    constructor(
        private translateService: TranslateService,
        private environmentService: EnvironmentService,
        private onlineService: OnlineService,

        private gatewayService: GatewayService,
        private loraProtocolService: LoraProtocolService,
        private registerService: RegisterService,
        private settingsService: SettingsService,) {}

    public load(): void {

        const stored = localStorage.getItem(STORAGE_NAME);

        if (!stored) {
            return;
        }

        const data = JSON.parse(stored);

        if (data.version != STORAGE_VERSION || !data.values) {
            return;
        }

        this.feedbacks = data.items;
    }

    public save(): void {

        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            version: STORAGE_VERSION,
            items: this.feedbacks
        }));
    }

    public send(feedback: FeedbackCreateRequest): void {

        // Complete the feedback with some context (without sensitive data)
        // TODO: Create a telemetry service to manage and obtain this set of data?
        feedback.context = {
            product: 'headquarter',
            isProduction: environment.production,
            release: `#${cicd.tag || cicd.pipeline}`,

            gateway: this.gatewayService.state,
            devices: this.loraProtocolService.devices,
            operators: this.registerService.operators,
            settings: this.settingsService.settingsWithoutSensitive
        };

        // Push it in the queue
        this.feedbacks.push(feedback);

        // Size limitation
        if (this.feedbacks.length > STORAGE_LIMIT) {
            this.feedbacks.shift();
        }

        // Saving into the local storage
        this.save();

        // Send to the backend if possible
        if (this.onlineService.isAvailableAndLoggedIn()) {
            this.sendAllPendings()
                .pipe(tap(() =>
                    this.environmentService.notificationOpen({
                        message: this.translateService.instant('services.feedbacks.sent'),
                    })
                ))
                .subscribe();
        } else {
            this.environmentService.notificationOpen({
                message: this.translateService.instant('services.feedbacks.saved'),
            });
        }
    }

    private sendAllPendings(): Observable<Array<FeedbackCreatedResponse>> {

        return forkJoin(this.feedbacks.map(feedback => this.onlineService.createFeedback(feedback)))
            .pipe(tap(responses => {

                if(responses.length === this.feedbacks.length) {
                    this.feedbacks = [];
                    this.save();
                }
            }));
    }
}
