import { Component, OnInit, Input } from '@angular/core';
import { App, Window } from '@lightning/wild-environment';
import { SettingsComponent } from '../settings/settings.component';
import { ActionsComponent } from '../actions/actions.component';
import { Tabs } from '@lightning/wild-ui';

@Component({
  selector: 'app-bot',
  templateUrl: './bot.component.html',
  styleUrls: ['./bot.component.scss']
})

export class BotComponent implements App, OnInit {

    @Input()
    public window: Window | undefined;

    public tabs: Tabs = {
        items: [
            { selector: 'actions', text: 'actions', component: ActionsComponent },
            { selector: 'settings', text: 'settings', component: SettingsComponent },
        ],
        selected: undefined
    };

    ngOnInit() {
        this.tabs.selected = this.tabs.items[0];
    }

}
