import { OnInit, Input, Component, OnDestroy } from '@angular/core';
import { App, Window } from '@lightning/wild-environment';
import { Tabs } from '@lightning/wild-ui';
import { TranslateService } from '@ngx-translate/core';
import { SimulatorComponent } from '../simulator/simulator.component';
import { TutorialComponent } from '../tutorial/tutorial.component';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';
import { GatewayConnectorsNames } from '../../../shared/enums/gateway.enum';
import { SettingsService } from '../../../shared/services/settings/settings.service';


@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.scss']
})

export class SimulationComponent implements App, OnInit, OnDestroy {

    @Input()
    public window: Window | undefined;

    public tabs = new Tabs();

    constructor(
        private translateService: TranslateService,
        private settingsService: SettingsService,
        private gatewayService: GatewayService) { }

    ngOnInit() {

        // Define tabs
        this.tabs.items = [
            {
                selector: 'simulator',
                text: this.translateService.instant('apps.simulation.simulator.title'),
                component: SimulatorComponent
            },
            {
                selector: 'tutorial',
                text: this.translateService.instant('apps.simulation.tutorial.title'),
                component: TutorialComponent
            }
        ];

        // Select a tab
        if (this.window?.argument?.tab) {

            this.tabs.selected = this.tabs.items.find(tab => tab.selector === this.window?.argument.tab);

        } else {

            this.tabs.selected = this.tabs.items[0];
        }

        // Select the dummy gateway connector while this app is open
        this.selectDummyGatewayConnector();
    }

    ngOnDestroy() {

        // Restore the saved connector once this app is closed
        this.restoreRegularGatewayConnector();
    }

    private selectDummyGatewayConnector(): void {

        if (this.gatewayService.connectorName === GatewayConnectorsNames.Simulation) {
            return;
        }

        // To prevent a ExpressionChangedAfterItHasBeenCheckedError with the gateway app
        setTimeout(() => {

            this.gatewayService.selectConnector(GatewayConnectorsNames.Simulation);

            this.gatewayService.connect();

        }, 0);
    }

    private restoreRegularGatewayConnector(): void {

        if (this.settingsService.settings.gateway.connector === GatewayConnectorsNames.Simulation) {
            return;
        }

        // To prevent a ExpressionChangedAfterItHasBeenCheckedError with the gateway app
        setTimeout(() => {

            this.gatewayService.selectConnector(this.settingsService.settings.gateway.connector);

            this.gatewayService.connect();

        }, 0);
    }

}
