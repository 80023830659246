import { Component, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { App, EnvironmentService, Window } from '@lightning/wild-environment';
import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';
import { PmrService } from '../../../shared/services/pmr/pmr.service';

import { PmrSounds } from '@lightning/lightning-definitions';

const LORA_RANGE_TEST_INTERVAL = 5000;

@Component({
    selector: 'app-diagnostic',
    templateUrl: './diagnostic.component.html',
    styleUrls: ['./diagnostic.component.scss']
})
export class DiagnosticComponent implements OnDestroy, App {

    @Input()
    public window: Window | undefined;

    public isPMRRangeTesting = false;

    private timerLoraRangeTest: any = null;

    private onTransmittingChangeSubscription: Subscription;

    constructor(
        public environmentService: EnvironmentService,
        public gatewayService: GatewayService,
        private pmrService: PmrService,
        private loraProtocolService: LoraProtocolService) {

        this.onTransmittingChangeSubscription = this.pmrService.onTransmittingChange
            .subscribe(state => this.pmrTransmittingChange(state));
    }

    public ngOnDestroy(): void {

        this.stopLoraRangeTest();

        this.stopPMRRangeTest();

        this.onTransmittingChangeSubscription.unsubscribe();
    }


    // ------------------------------------------------------------------------------------------
    // Lora range test
    // ------------------------------------------------------------------------------------------

    public get isLoraRangeTesting(): boolean {

        return this.timerLoraRangeTest !== null;
    }

    public toggleLoraRangeTest(): void {

        this.isLoraRangeTesting ? this.stopLoraRangeTest() : this.startLoraRangeTest();
    }

    private startLoraRangeTest(): void {

        if (this.isLoraRangeTesting) {
            return;
        }

        this.loraProtocolService.sendRangeTestCommand();

        this.timerLoraRangeTest = setInterval(() => {

            this.loraProtocolService.sendRangeTestCommand();

        }, LORA_RANGE_TEST_INTERVAL);

    }

    private stopLoraRangeTest(): void {

        clearInterval(this.timerLoraRangeTest);

        this.timerLoraRangeTest = null;
    }

    // ------------------------------------------------------------------------------------------
    // PMR range test
    // ------------------------------------------------------------------------------------------

    public togglePMRRangeTest(): void {

        this.isPMRRangeTesting ? this.stopPMRRangeTest() : this.startPMRRangeTest();
    }

    public startPMRRangeTest(): void {

        this.isPMRRangeTesting = true;

        this.playPMR();
    }

    public stopPMRRangeTest(): void {

        this.isPMRRangeTesting = false;
    }

    private pmrTransmittingChange(state: any): void {

        if (state.isTransmitting === false) {
            this.playPMR();
        }
    }

    private playPMR(): void {

        if (this.isPMRRangeTesting === false) {
            return;
        }

        this.pmrService.play(PmrSounds.PmrCheckBroadcast, this.pmrService.broadcastChannel);
    }

}
