<app-body>
    <wui-section [name]="'shared.generalities' | translate ">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate | capitalize }}</div>
            <wui-input value type="text" [(value)]="destructionService.settings.name"></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate ">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate | capitalize }}</div>
            <wui-select value [(value)]="destructionService.settings.timeLimit">
                <option *ngFor="let item of OperationProcessDurations | keyValue" [value]="item.value">{{ 'apps.operations.setup.durations.' + item.key | translate }}</option>
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section name="sub modes">
        <wui-entry>
            <div name>Mode</div>
            <!-- <wui-input value type="checkbox" [(value)]="destructionService.settings.assignedAreas"></wui-input> -->
            <wui-select value type="number" [(value)]="destructionService.settings.submode">
                <option value="0">directional</option>
                <option value="1">crossed</option>
            </wui-select>
            <div detail>
                With the directional submode, one team attacks and the other protects the areas.
                With the crossed one, each team attacks the area of the other team.
            </div>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="destructionService.preview()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="destructionService.brief()"
                    [wuiDisabled]="destructionService.settingsAreValid() === false">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
