import { Component, Input, OnInit } from '@angular/core';

import { TeamUnderOperation } from '@lightning/lightning-definitions';
import { EnvironmentService } from '@lightning/wild-environment';

import { RegisterService } from '../../services/register/register.service';

@Component({
    selector: 'app-modal-team-score-adjust',
    templateUrl: './modal-team-score-adjust.component.html',
    styleUrls: ['./modal-team-score-adjust.component.scss']
})
export class ModalTeamScoreAdjustComponent implements OnInit {

    @Input()
    private resolve = ({}) => { };
    
    public team: TeamUnderOperation | undefined;
    public points: number = 1000;
    public isBonus: boolean = true;
    public reason: string = '';

    constructor(
        private environmentService: EnvironmentService,
        public registerService: RegisterService) { }

    public ngOnInit(): void {

        if (this.registerService.teams.length > 0) {
            this.team = this.registerService.teams[0];
        }
    }

    public get isInvalid() {

        return !this.team || this.points === 0;
    }

    public confirmClick(): void {

        if(!this.team) {
            return;
        }

        console.log({
            team: this.team,
            points: this.points,
            isBonus: this.isBonus
        });

        this.registerService.increaseTeamScore(this.team, this.points * (this.isBonus ? 1 : -1));

        this.resolve({
            team: this.team,
            points: this.points,
            isBonus: this.isBonus
        });

        this.environmentService.modalClose();
    }

}
