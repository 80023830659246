/// <reference types="@types/w3c-web-serial" />

import { EventEmitter } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { GatewayConnectorsNames } from '../../../enums/gateway.enum';
import { GatewayConnector } from './connector.interface';

export class WebSerialGatewayConnector implements GatewayConnector {

    public onReceive = new EventEmitter<string>;
    public onConnect = new EventEmitter<void>;
    public onConnectFail = new EventEmitter<void>;
    public onDisconnect = new EventEmitter<void>;

    private _isConnected = false;

    private _port: SerialPort | null = null;
    private _reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
    private _writer: WritableStreamDefaultWriter<Uint8Array> | undefined;

    private _decoder = new TextDecoder();
    private _encoder = new TextEncoder();

    private _buffer = '';

    public get name(): GatewayConnectorsNames {
        return GatewayConnectorsNames.Serial;
    }

    public async connect(): Promise<void> {

        if (this._isConnected) {
            return;
        }

        if (!('serial' in navigator)) {

            console.error('Web Serial not supported');

            this.onConnectFailHandler();

            return;
        }

        this.disconnect();

        // Try to open a previously allowed port
        try {

            // Doesn't require a user gesture but returns port only after a successfully requestPort()
            const ports = await navigator.serial.getPorts();

            if (ports.length > 0) {
                this._port = ports[0];
            }

        } catch(exception) {

            console.log(`Unable to found an allowed port`);
        }

        // Try to ask the user to select a port
        if (this._port === null) {

            try {

                // Requires a user gesture
                this._port = await navigator.serial.requestPort({ filters: environment.gateway.serial.devicesFilters });

            } catch(exception) {

                console.log(`Unable to select a port`);
            }
        }

        // Failed to select a port
        if (this._port === null) {

            this.onConnectFailHandler();

            return;
        }

        // Not totally reliable, prefer to catch exceptions from the reading loop
        // navigator.serial.addEventListener('disconnect', this.onDisconnectHandler.bind(this));

        // Try to open the port
        try {

            await this._port.open(environment.gateway.serial.options);

            this.onConnectHandler();
        }
        catch (exception) {

            this.onConnectFailHandler();

            return;
        }


        this._writer = this._port.writable?.getWriter();
        this._reader = this._port.readable?.getReader();

        if (!this._writer || !this._reader) {

            this.onConnectFailHandler();

            return;
        }

        while(this.isConnected) {

            try {

                const { value } = await this._reader.read();

                if (value === undefined) {
                    continue;
                }

                this.buffering(value);

            } catch(exception) {

                this.onDisconnectHandler();
            }
        }
    }

    public async disconnect(): Promise<void> {

        if (!this.isConnected) {
            return;
        }

        try {

            if (this._writer) {
                this._writer.releaseLock();
            }

            if (this._reader) {
                this._reader.cancel();
                this._reader.releaseLock();
            }

            if (this._port) {
                this._port.close();
            }

            console.log('WebSerialConnector: Closed');
        }
        catch(exception) {

            console.log('WebSerialConnector: Failed to release', exception);
        }

        // The event listener seems don't work
        this.onDisconnectHandler();
    }

    public async send(data: string): Promise<void> {

        if (!this._port || !this._isConnected) {
            return;
        }

        if (!this._writer) {

            this.onDisconnectHandler();

            return;
        }

        await this._writer.write(this._encoder.encode(data + environment.gateway.linesSeparator))
            .catch(() => this.onDisconnectHandler.bind(this));
    }

    public get isConnected(): boolean {

        return this._isConnected;
    }


    private onConnectHandler(): void {

        this._isConnected = true;

        this.onConnect.emit();
    }

    private onConnectFailHandler(): void {

        this._isConnected = false;

        this.onConnectFail.emit();
    }

    private onReceiveHandler(data: string): void {

        this.onReceive.emit(data);
    }

    private onDisconnectHandler(): void {

        this._isConnected = false;

        this.onDisconnect.emit();
    }


    private buffering(value: Uint8Array): void {

        // Decode binary to text
        const data = this._decoder.decode(value);

        // Concat to the previous incomplete data
        this._buffer += data;

        // No entire line in the buffer, return
        if (this._buffer.indexOf(environment.gateway.linesSeparator) === -1) {
            return;
        }

        // Split lines
        const lines = this._buffer.split(environment.gateway.linesSeparator);

        // Consider the last part is an incomplete line to keep in the buffer
        this._buffer = lines.pop() || '';

        // Handle each line
        for (const line of lines) {
            this.onReceiveHandler(line);
        }

    }

}
