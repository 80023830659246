<app-body>
    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="warmupService.settings.timeLimit > 0">
            {{ warmupService.timer.time | milliseconds }}
        </ng-container>
        <ng-container *ngIf="warmupService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <lui-operation-teams-ranking wuiTacticalCorners
        [teams]="registerService.teams"
        [pointsCurrency]="warmupService.settings.pointsCurrency"
        [pointsLimit]="warmupService.settings.pointsLimit"></lui-operation-teams-ranking>

    <lui-timeline *ngIf="warmupService.timeline.length" wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="warmupService.timeline"
        [scrolling]="false">
    </lui-timeline>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button *ngIf="warmupService.state === OperationStates.Iddle"       (click)="warmupService.start()">{{ 'shared.start' | translate }}</wui-button>
        <wui-button *ngIf="warmupService.state === OperationStates.Processing"  (click)="warmupService.pause()">{{ 'shared.pause' | translate }}</wui-button>
        <wui-button *ngIf="warmupService.state === OperationStates.Paused"      (click)="warmupService.resume()">{{ 'shared.resume' | translate }}</wui-button>
        <wui-button *ngIf="warmupService.state === OperationStates.Processing"  (click)="warmupService.complete()">{{ 'shared.complete' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
