import { EventEmitter, Injectable } from '@angular/core';

import { OperationBase } from '../../modes/shared/classes/operation.base';
import { OperationSteps } from '../../modes/shared/enums/operation.enums';

import { WarmupService } from '../../modes/warmup/services/warmup.service';
import { DominationService } from   '../../modes/domination/services/domination.service';
import { LootingService } from   '../../modes/looting/services/looting.service';
import { DropsService } from   '../../modes/drops/services/drops.service';
import { PrisonersService } from '../../modes/prisoners/services/prisoners.service';
import { AssemblyService } from '../../modes/assembly/services/assembly.service';
import { SandboxService } from   '../../modes/sandbox/services/sandbox.service';

import { WarmupComponent } from '../../modes/warmup/components/warmup/warmup.component';
import { DominationComponent } from '../../modes/domination/components/domination/domination.component';
import { LootingComponent } from '../../modes/looting/components/looting/looting.component';
import { DropsComponent } from '../../modes/drops/components/drops/drops.component';
import { PrisonersComponent } from '../../modes/prisoners/components/prisoners/prisoners.component';
import { AssemblyComponent } from '../../modes/assembly/components/assembly/assembly.component';
import { SandboxComponent } from '../../modes/sandbox/components/sandbox/sandbox.component';

export interface OperationManifest {
    name: string;
    description: string;
    logo: string;
    tag: string;
    disabled?: boolean;
    component: any; //Component,
    service: OperationBase;
};

@Injectable({
    providedIn: 'root'
})

export class OperationsService {

    private _operations: Array<OperationManifest> = [

        {
            name: 'warmup',
            description: 'Fight and respawn',
            logo: 'assets/operations/modes/warmup/logo.svg',
            tag: '',
            component: WarmupComponent,
            service: this.warmupService
        },

    {
        name: 'looting',
        description: 'Loot & extract cash',
        logo: 'assets/operations/modes/looting/logo.svg',
        tag: '',
        component: LootingComponent,
        service: this.lootingService
    },
    // {
    //     name: 'convoy',
    //     description: 'Convoy cash to the central bank',
    //     logo: 'assets/operations/modes/convoy/logo.svg',
    //     tag: '',
    //     component: ConvoyComponent,
    //     service: this.convoyService
    // },
    {
        name: 'drops',
        description: 'Be the first on the place',
        logo: 'assets/operations/modes/drops/logo.svg',
        disabled: false,
        tag: '',
        component: DropsComponent,
        service: this.dropsService
    },
    {
        name: 'domination',
        description: 'Take & keep areas',
        logo: 'assets/operations/modes/domination/logo.svg',
        tag: '[locked]',
        component: DominationComponent,
        service: this.dominationService
    },
    // {
    //     name: 'blackout',
    //     description: 'Break all simultaneously',
    //     logo: 'assets/operations/modes/blackout/logo.svg',
    //     tag: '[locked]',
    //     component: undefined, // BlackoutComponent,
    //     service: undefined, // this.backoutService

    //     disabled: true
    // },
    // {
    //     name: 'encounter',
    //     description: 'Kill them all with limited respawns',
    //     logo: 'assets/operations/modes/kill/logo.svg',
    //     tag: '',
    //     component: EncounterComponent,
    //     service: this.encounterService
    // },
    // {
    //     name: 'invasion',
    //     description: 'Take areas one after one',
    //     logo: 'assets/operations/modes/invasion/logo.svg',
    //     tag: '[locked]',
    //     component: undefined, //InvasionComponent,
    //     service: undefined, //this.invasionService

    //     disabled: true
    // },
    // {
    //     name: 'destruction',
    //     description: 'Find and destroy areas',
    //     logo: 'assets/operations/modes/destruction/logo.svg',
    //     tag: '',
    //     component: DestructionComponent,
    //     service: this.destructionService
    // },
    {
        name: 'prisoners',
        description: 'Make prisoners',
        logo: 'assets/operations/modes/prisoners/logo.svg',
        tag: '',
        component: PrisonersComponent,
        service: this.prisonersService
    },
    {
        name: 'assembly',
        description: 'Built first',
        logo: 'assets/operations/modes/assembly/logo.svg',
        tag: '',
        component: AssemblyComponent,
        service: this.assemblyService
    },
    {
        name: 'sandbox',
        description: 'Free mode',
        logo: 'assets/operations/modes/sandbox/logo.svg',
        tag: '',
        component: SandboxComponent,
        service: this.sandboxService
    }];

    public selectedChanged = new EventEmitter<OperationManifest | undefined>();

    private _selected: OperationManifest | undefined = undefined;

    constructor(
                private warmupService: WarmupService,
                private lootingService: LootingService,
                private dominationService: DominationService,
                // private convoyService: ConvoyService,
                private dropsService: DropsService,
                // private encounterService: EncounterService,
                // private destructionService: DestructionService,
                private prisonersService: PrisonersService,
                private assemblyService: AssemblyService,
                private sandboxService: SandboxService) {}

    public get operations(): Array<OperationManifest> {

        return this._operations;
    }

    public get operation(): OperationManifest | undefined {

        return this._selected;
    }

    public select(operation: OperationManifest): void {

        this.unselect();

        this._selected = operation;

        this._selected.service.initialize();

        this._selected.service.stepChanged.subscribe((step: OperationSteps) => {

            if (step === OperationSteps.Exiting) {
                this.unselect();
            }
        });

        this.selectedChanged.emit(this._selected);
    }

    public unselect(): void {

        if(this._selected === undefined) {
            return;
        }

        this._selected = undefined;

        this.selectedChanged.emit(this._selected);
    }

}
