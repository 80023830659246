import { Component, OnInit } from '@angular/core';
import { DestructionService } from '../../services/destruction.service';

@Component({
  selector: 'app-destruction-briefing',
  templateUrl: './briefing.component.html',
  styleUrls: ['./briefing.component.scss']
})
export class BriefingComponent implements OnInit {

  constructor(public destructionService: DestructionService) { }

  ngOnInit() {
  }

}
