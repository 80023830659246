import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { LootingService } from '../../services/looting.service';

@Component({
    selector: 'app-looting-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss']
})
export class DebriefingComponent {

    constructor(
        public onlineService: OnlineService,
        public operationService: LootingService) { }
}
