import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';
import { LightningUiModule } from '@lightning/lightning-ui';

import { SharedModule as SharedModuleApps } from '../../../shared/shared.module';
import { SharedModule as SharedModuleOperations } from '../shared/shared.module';

import { DestructionComponent } from './components/destruction/destruction.component';
import { PreviewComponent } from './components/preview/preview.component';
import { SetupComponent } from './components/setup/setup.component';
import { BriefingComponent } from './components/briefing/briefing.component';
import { DeployingComponent } from './components/deploying/deploying.component';
import { ProcessComponent } from './components/process/process.component';
import { DebriefingComponent } from './components/debriefing/debriefing.component';

@NgModule({
    declarations: [
        DestructionComponent,
        PreviewComponent,
        SetupComponent,
        BriefingComponent,
        DeployingComponent,
        ProcessComponent,
        DebriefingComponent
    ],
    imports: [
        CommonModule,
        WildUiModule,
        LightningUiModule,
        SharedModuleApps,
        SharedModuleOperations
    ],
    exports: []
})

export class DestructionModule {}

