<app-container>

    <app-header [logo]="'assets/apps/lora/logo.svg'"
                [description]="'apps.lora.headerDescription' | translate"></app-header>

    <app-disclamer
        *ngIf="!gatewayService.isConnected"
        level="error"
        (click)="environmentService.windowOpenByAppId('gateway'); $event.stopPropagation()"
        [isInteractive]="true">
        {{ 'apps.lora.disclamers.gatewayRequired' | translate }}
    </app-disclamer>

    <wui-tabs [tabs]="tabs" developpersOnly></wui-tabs>

    <ng-container *ngIf="tabs.selected">
        <ng-container *ngComponentOutlet="tabs.selected.component"></ng-container>
    </ng-container>

</app-container>
