
import { Injectable } from '@angular/core';
import { Products, OperationActions, PmrSounds, NfcTagsSpecialsActions, TeamUnderOperation, OperatorAvatarName } from '@lightning/lightning-definitions';
import { NfcCashupData, NfcSpecialData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';

@Injectable({
    providedIn: 'root'
})

export class ConvoyService extends OperationBase {

    constructor() {

        super('convoy');

        this.settings = {
            version: 1,

            name: '',
            timeLimit: 1800000,

            pointsLimit: 1000000,
            pointsCurrency: 'USD',
            modules: {
                bank: '',
                stash: ''
            },
            teams: {
                conveyors: '',
                attackers: ''
            },
        };

        this.organisatorActions = [
            this.getOrganisatorActionNote(),
            this.getOrganisatorActionTeamBonusPenality()
        ];

        this.loraProtocolService.onNfcCashupReceive
            .subscribe((data: NfcCashupData) => this.onNfcCashupReceive(data));

        this.loraProtocolService.onNfcSpecialReceive
            .subscribe((data: NfcSpecialData) => this.onNfcSpecialReceive(data));
    }


    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams 2',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2,
                help: '',
            },
            {
                name: 'Ground modules 2',
                value: groundModules.length.toString(),
                isReady: groundModules.length >= 2,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements.some(requierement => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        return this.settings.modules.bank &&
            this.settings.modules.stash &&
            this.settings.modules.bank != this.settings.modules.stash &&
            this.settings.teams.conveyors &&
            this.settings.teams.attackers &&
            this.settings.teams.conveyors != this.settings.teams.attackers;
    }

    public override settingsApply(): void {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Teams setup
        const convoyorTeam = this.registerService.getTeam(this.settings.teams.conveyors);

        if (convoyorTeam) {
            convoyorTeam.role = 'convoyors';
        }

        const attackersTeam = this.registerService.getTeam(this.settings.teams.attackers);

        if (attackersTeam) {
            attackersTeam.role = 'attackers';
        }

        // Save settings of the next time
        this.settingsSave();
    }

    /* public getWinner(): OperationWinner { return {}; } */

    /* public getTrophies(): Array<OperationTrophy> { return []; } */


    private async onNfcCashupReceive(data: NfcCashupData): Promise<void> {

        const { operator } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // To the bank
        if (data.sender.id === this.settings.modules.bank) {

            this.onNfcCashupToTheBank(data);

            // To the stash
        } else if (data.sender.id === this.settings.modules.stash) {

            this.onNfcCashupToTheStash(data);

            // To otherwhere
        } else {

            console.warn('ConvoyService: Money into a wrong place');
        }
    }

    private async onNfcCashupToTheBank(data: NfcCashupData): Promise<void> {

        const { sender, operator, total } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const { conveyorsTeam } = this.getAssignedTeams();

        if (!conveyorsTeam) {
            return;
        }

        // Increase score
        this.registerService.increaseTeamScore(conveyorsTeam, total);

        // By a conveyor
        if (team.id === conveyorsTeam.id) {

            this.timelinePush({
                level: 1,
                action: OperationActions.ConvoySuccess,
                data: {
                    operator, total, conveyorsTeam, area: sender.name
                },
                customIconPath: this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
            });

            // By an attacker!!!
        } else {

            console.warn('ConvoyService: Oh wait! An attacker pushed money to the bank!!!');

            this.timelinePush({
                level: 1,
                action: OperationActions.ConvoyByAttacker,
                data: {
                    operator, total, conveyorsTeam, area: sender.name
                },
                customIconPath: this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Losing)
            });
        }

        // Announce via PMR
        this.pmrService.announce(PmrSounds.Success, conveyorsTeam);

    }

    private async onNfcCashupToTheStash(data: NfcCashupData): Promise<void> {

        const { sender, operator, total } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const { attackersTeam } = this.getAssignedTeams();

        if (!attackersTeam) {

            return;
        }

        // Increase score
        this.registerService.increaseTeamScore(attackersTeam, total);

        // By an attacker
        if (team.id === attackersTeam.id) {

            this.timelinePush({
                level: 1,
                action: OperationActions.ConvoyHijacked,
                data: {
                    operator, team, total, area: sender.name
                },
                customIconPath: this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
            });

            // By an conveyor!!!
        } else {

            console.warn('ConvoyService: Oh wait! An conveyor pushed money to the stash!!!');

            this.timelinePush({
                level: 1,
                action: OperationActions.ConvoyHijackedByConveyor,
                data: {
                    operator, team, total, area: sender.name
                },
                customIconPath: this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Losing)
            });
        }

        // Announce via PMR
        this.pmrService.announce(PmrSounds.Success, attackersTeam);

    }

    private async onNfcSpecialReceive(data: NfcSpecialData): Promise<void> {

        const { sender, operator, team, special } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team
        if (!team) {
            return;
        }

        const { conveyorsTeam, attackersTeam } = this.getAssignedTeams();

        if (!conveyorsTeam || !attackersTeam) {

            return;
        }

        if (special === NfcTagsSpecialsActions.Drill) {

            if (sender.id !== this.settings.modules.bank) {

                console.warn('ConvoyService: The drill was used to a wrong place');
                return;
            }

            this.registerService.increaseTeamScore(attackersTeam, conveyorsTeam.score.points);
            this.registerService.clearTeamScore(conveyorsTeam);

            this.timelinePush({
                level: 1,
                action: OperationActions.ConvoyDrill,
                data: {
                    operator, team, area: sender.name
                },
                customIconPath: this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Winning)
            });

            // TODO: PMR sound **********************
        }

    }

    private getAssignedTeams(): { conveyorsTeam: TeamUnderOperation | undefined, attackersTeam: TeamUnderOperation | undefined } {

        const conveyorsTeam = this.registerService.getTeam(this.settings.teams.conveyors);

        if (!conveyorsTeam) {

            console.warn('ConvoyService: There is no conveyors team');
        }

        const attackersTeam = this.registerService.getTeam(this.settings.teams.attackers);

        if (!attackersTeam) {

            console.warn('ConvoyService: There is no attackers team');
        }

        return { conveyorsTeam, attackersTeam };
    }
}
