import { Component, OnInit } from '@angular/core';
import { SandboxService } from '../../services/sandbox.service';

@Component({
  selector: 'app-sandbox-briefing',
  templateUrl: './briefing.component.html',
  styleUrls: ['./briefing.component.scss']
})
export class BriefingComponent implements OnInit {

  constructor(public sandboxService: SandboxService) { }

  ngOnInit() {
  }

}
