import { Component, OnInit } from '@angular/core';
import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';

@Component({
    selector: 'app-teams',
    templateUrl: './teams.component.html',
    styleUrls: ['./teams.component.scss']
})
export class TeamsComponent implements OnInit {

    public isUnbalanced = false;

    constructor(public registerService: RegisterService) { }

    ngOnInit(): void {

        let higestCount = 0;

        for (const team of this.registerService.teams) {
            if(team.operatorsCount > higestCount) {
                higestCount = team.operatorsCount;
            }
        }

        this.isUnbalanced =
            this.registerService.teams.some(team => (higestCount - team.operatorsCount) >= 2);

    }

}
