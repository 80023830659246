import { Component } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';

import { RegisterService } from '../../../../../shared/services/register/register.service';
import { OperationStates } from '../../../shared/enums/operation.enums';
import { ConvoyService } from '../../services/convoy.service';

@Component({
    selector: 'app-convoy-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss']
})

export class ProcessComponent {

    OperationStates = OperationStates;

    constructor(public environmentService:EnvironmentService,
                public onlineService: OnlineService,
                public registerService: RegisterService,
                public convoyService: ConvoyService) { }
}
