import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { DropsService } from '../../services/drops.service';

@Component({
    selector: 'app-drops-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss']
})
export class DebriefingComponent {

    constructor(
        public onlineService: OnlineService,
        public operationService: DropsService) { }
}
