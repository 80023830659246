import { Component, OnInit } from '@angular/core';

import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';

@Component({
    selector: 'app-simulation',
    templateUrl: './simulation.component.html',
    styleUrls: ['./simulation.component.scss']
})
export class SimulationComponent implements OnInit {

    constructor(public loraProtocolService: LoraProtocolService) { }

    ngOnInit() {
    }

}
