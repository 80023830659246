<app-container>

    <app-header [logo]="'assets/apps/bot/logo.svg'"
                [description]="'Control a bot to make tests using physical nfc cards on the ground modules.'"></app-header>

    <wui-tabs [tabs]="tabs"></wui-tabs>

    <ng-container *ngComponentOutlet="tabs.selected?.component"></ng-container>

</app-container>
