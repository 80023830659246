import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { SandboxService } from '../../services/sandbox.service';

@Component({
    selector: 'app-sandbox-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss']
})
export class DebriefingComponent {

    constructor(
        public onlineService: OnlineService,
        public operationService: SandboxService) { }
}
