import { Component } from '@angular/core';
import { AssemblyService } from '../../services/assembly.service';

@Component({
    selector: 'app-assembly-briefing',
    templateUrl: './briefing.component.html',
    styleUrls: ['./briefing.component.scss'],
})
export class BriefingComponent {
    constructor(public assemblyService: AssemblyService) {}
}
