import { EventEmitter, Injectable } from '@angular/core';

import { EnvironmentService, ScreenService } from '@lightning/wild-environment';
import { NfcTagsTypes, OperatorUnderOperation, TeamUnderOperation } from '@lightning/lightning-definitions';

import { ModalAlertComponent } from '../../components/modal-alert/modal-alert.component';
import { ModalTeamAssignComponent } from '../../components/modal-team-assign/modal-team-assign.component';
import { TranslateService } from '@ngx-translate/core';

const STORAGE_NAME = 'teams';
const STORAGE_VERSION = 2;
const TEAM_RANKING_LEAD_DELAY = 1000;

@Injectable({
    providedIn: 'root'
})

export class RegisterService {

    public onOperatorAdded = new EventEmitter<OperatorUnderOperation>();
    public onOperatorRemoved = new EventEmitter<OperatorUnderOperation>();
    public onOperatorsCleared = new EventEmitter();
    public onTeamsRankingLeadChanged = new EventEmitter<TeamUnderOperation>();

    public teamsSlots: Array<TeamUnderOperation> = [
        {   id: 'A',
            name: 'Alpha',
            color: '#FF0000',   // 'red',
            operatorsCount: 0,
            pmr: '1.10',
            score: { points: 0, rank: 0},
            data: {}
        },
        {
            id: 'B',
            name: 'Bravo',
            color: '#4169E1',   //'royalblue',
            operatorsCount: 0,
            pmr: '2.10',
            score: { points: 0, rank: 0},
            data: {}
        },
        {
            id: 'C',
            name: 'Charlie',
            color: '#FFFF00',   // 'yellow',
            operatorsCount: 0,
            pmr: '3.10',
            score: { points: 0, rank: 0},
            data: {}
        },
        {
            id: 'D',
            name: 'Delta',

            color: '#00FF00',   //'green',
            operatorsCount: 0,
            pmr: '4.10',
            score: { points: 0, rank: 0},
            data: {}
        },
        {
            id: 'S',
            name: 'Staff',
            color: '#808080',   // 'grey',
            operatorsCount: 0,
            pmr: '5.10',
            score: { points: 0, rank: 0},
            isStaff: true,
            data: {}
        }
    ];

    public operators: Array<OperatorUnderOperation> = [];

    private teamsRankingLeadTimer: any = undefined;

    constructor(
        private environmentService: EnvironmentService,
        private screenService: ScreenService,
        private translateService: TranslateService) {

        this.load();
    }

    public get assignedOperatorsCount(): number {

        return this.teamsSlots.reduce((accumulator, team) => {

            return accumulator + (team.isStaff ? 0 : team.operatorsCount);

        }, 0);
    }

    public createOperator(number: number, name: string, isGuest: boolean): OperatorUnderOperation {

        // Create an operator object
        const operator = {
            number,
            name,
            isGuest,
            teamId: undefined,
            score: {
                points: 0,
                rank: 0
            },
            data: {}
        };

        // Add to existing operators
        this.operators.push(operator);

        // Notify (on desktop only)
        if (this.screenService.isDesktop) {
            this.environmentService.notificationOpen({
                logo: 'assets/apps/register/logo.svg',
                message: this.translateService.instant('apps.register.notifications.operatorJoins', { name: this.getOperatorFriendlyName(operator) })
            });
        }

        // Fire an event
        this.onOperatorAdded.emit(operator);

        // Save
        this.save();

        // Return the created operator
        return operator;
    }

    public getOrCreateOperator(number: number, name: string): OperatorUnderOperation {

        // Guest doesn't have a name
        const isGuest = name === '';

        // Name the guest with its number
        if (isGuest) {
            name = number.toString(10);
        }

        // Check if not already present
        const operator = this.operators.find(operator => operator.number === number && operator.isGuest === isGuest);

        if (operator) {

            if (operator.name !== name) {
                this.environmentService.modalOpen({
                    title: this.translateService.instant('apps.register.modals.multipleNames.title'),
                    component: ModalAlertComponent,
                    inputs: {
                        logo: 'assets/apps/register/logo.svg',
                        description: this.translateService.instant('apps.register.modals.multipleNames.description', { number, nameA: operator.name, nameB: name }),
                    }
                });
            }

            return operator;
        }

        return this.createOperator(number, name, isGuest);
    }

    public getOrCreateOperatorFromFrienlyName(friendlyName: string): OperatorUnderOperation | undefined {

        const fields = friendlyName.split(' ');

        if (fields[0] === NfcTagsTypes.Operator) {
            return this.getOrCreateOperator(parseInt(fields[1], 0), fields[2]);
        }

        if (fields[0] === NfcTagsTypes.Guest) {
            return this.getOrCreateOperator(parseInt(fields[1], 0), '');
        }

        throw 'Invalid operator friendly name';
    }

    public removeOperator(operator: OperatorUnderOperation): void {

        this.environmentService.notificationOpen({
            logo: 'assets/apps/register/logo.svg',
            message: this.translateService.instant('apps.register.notifications.operatorLefts', { name: this.getOperatorFriendlyName(operator) })
        });

        this.operators = this.operators.filter(item => item !== operator);

        this.updateTeams();

        // Fire an event
        this.onOperatorRemoved.emit(operator);

        // Save
        this.save();
    }

    public clearOperators(): void {

        this.operators = new Array<OperatorUnderOperation>();

        this.updateTeams();

        // Fire an event
        this.onOperatorsCleared.emit();

        // Save
        this.save();
    }

    public getOperatorFriendlyName(operator: OperatorUnderOperation): string {

        if (operator.isGuest) {
            return `${NfcTagsTypes.Guest}-${operator.number}`;
        }

        return `${NfcTagsTypes.Operator}-${operator.number} ${operator.name}`;

    }

    public getOperatorByNumber(number: number): OperatorUnderOperation | undefined {

        return this.operators.find(operator => operator.number === number);
    }

    public getOperatorByFriendlyName(fiendlyName: string): OperatorUnderOperation | undefined { // UNUSED

        const fields = fiendlyName.split(/[\s-]+/);

        const isGuest = fields[0] === NfcTagsTypes.Guest;

        const number = parseInt(fields[1], 0);

        return this.operators.find(operator => operator.isGuest === isGuest && operator.number === number);
    }

    public setOperatorTeam(operator: OperatorUnderOperation, team: TeamUnderOperation | undefined): void {

        if (!team) {

            console.log('The operator', operator.name, 'lefts the team', operator.teamId);

            operator.teamId = undefined;

        } else {

            operator.teamId = team.id;

            console.log('The operator', operator.name, 'joins the team', team.name);
        }

        this.updateTeams();

        // Save
        this.save();
    }

    public async askOperatorTeam(operator: OperatorUnderOperation): Promise<TeamUnderOperation | undefined> {

        if (operator.teamId) {

            return this.getTeam(operator.teamId);
        }

        const team: TeamUnderOperation = await this.environmentService.modalOpen({
            title: this.translateService.instant('apps.register.modals.unassignedOperator.title'),
            component: ModalTeamAssignComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description: this.translateService.instant('apps.register.modals.unassignedOperator.description'),
                operator,
                teams: this.teamsSlots
            }
        });

        if (team) {

            this.setOperatorTeam(operator, team);
        }

        return team;
    }

    public sortOperatorsByTeam(): void {

        this.operators.sort((a, b) => (a.teamId || '') < (b.teamId || '') ? -1 : 1);
    }

    public sortOperatorsByNumber(): void {

        this.operators.sort((a, b) => a.number < b.number ? -1 : 1);
    }

    public sortOperatorsByName(): void {

        this.operators.sort((a, b) => (a.name || '') < (b.name || '') ? -1 : 1);
    }

    public shuffleOperatorsInTeams(): void {

        const teamsCount = this.teams.length;

        const operatorsPerTeam = Math.round(this.operators.length / teamsCount);

        // Shuffle
        for(let from = 0; from < this.operators.length; from++) {

            const to = Math.ceil(Math.random() * this.operators.length);

            this.operators.splice(to, 0, this.operators.splice(from, 1)[0]);
        }

        let operatorsCount = 0;
        let teamIndex = 0;

        // Cut
        for(let i = 0; i < this.operators.length; i++) {

            this.setOperatorTeam(this.operators[i], this.teamsSlots[teamIndex]);

            if (++operatorsCount >= operatorsPerTeam && teamIndex + 1 < teamsCount) {
                operatorsCount = 0;
                teamIndex++;
            }
        }

        // Save
        this.save();
    }



    public get teams(): Array<TeamUnderOperation> {

        return this.teamsSlots.filter(team => (team.operatorsCount > 0 || team.role) && !team.isStaff);
    }

    public get teamsLimit(): number {

        return this.teamsSlots.filter(team => !team.isStaff).length;
    }

    public get isTeamsBalancingPossible(): boolean {   // TODO: Signal computed

        let higestCount = 0;

        for (const team of this.teams) {
            if(team.operatorsCount > higestCount) {
                higestCount = team.operatorsCount;
            }
        }

        return this.teams.some(team => (higestCount - team.operatorsCount) >= 2);
    }

    public getTeam(teamId: string): TeamUnderOperation | undefined {

        return this.teamsSlots.find(team => team.id === teamId);
    }



    public increaseOperatorScore(operator: OperatorUnderOperation, points: number): void {

        // Increase points
        operator.score.points += points;

        // Update ranking
        this.updateOperatorsRanking();
    }

    public clearOperatorScore(operator: OperatorUnderOperation): void {

        // Increase points
        operator.score.points = 0;

        // Update ranking
        this.updateOperatorsRanking();
    }

    private updateOperatorsRanking(): void {

        const ordered = this.operators
            .sort((a, b) => b.score.points - a.score.points);

        let rank = 1;

        for (const [index, item] of ordered.entries()) {

            if (index > 0 && ordered[index - 1].score.points !== item.score.points) {
                rank++;
            }

            item.score.rank = rank;
        }
    }


    public increaseTeamScore(team: TeamUnderOperation, points: number): void {

        // Team without score (eg: staff)
        if (!team.score) {
            return;
        }

        // Increase the points
        team.score.points += points;

        // Update the ranking
        this.updateTeamsRanking();
    }

    public clearTeamScore(team: TeamUnderOperation): void {

        // Team without score (eg: staff)
        if (!team.score) {
            return;
        }

        // Clear points
        team.score.points = 0;

        // Update ranking
        this.updateTeamsRanking();
    }

    public updateTeams(): void {

        // Update the count of operator by team slot
        this.teamsSlots.map(teamSlot => {
            teamSlot.operatorsCount = this.operators.filter(operator => operator.teamId === teamSlot.id).length;
        })

        // Update the ranking
        this.updateTeamsRanking();
    }

    private updateTeamsRanking(): void {

        // Get the leading team before the update
        const leadingTeamBefore = this.getLeadingTeam();

        const orderedTeamsByScore = this.teams
            .sort((a, b) => b.score.points - a.score.points);

        let rank = 1;

        for (const [index, item] of orderedTeamsByScore.entries()) {

            if (index > 0 && orderedTeamsByScore[index - 1].score.points !== item.score.points) {
                rank++;
            }

            item.score.rank = rank;
        }

        // Get the leading team after the update
        const leadingTeam = this.getLeadingTeam();

        // No leading team
        if (!leadingTeam) {

            clearTimeout(this.teamsRankingLeadTimer);

            return;
        }

        // The leading team changed
        if(leadingTeamBefore !== leadingTeam) {

            // Event with anti debounce
            // That makes sure the change is stable and not a change during calculations (eg: domination)

            clearTimeout(this.teamsRankingLeadTimer);

            this.teamsRankingLeadTimer =
                setTimeout(() => { this.onTeamsRankingLeadChanged.emit(leadingTeam); }, TEAM_RANKING_LEAD_DELAY);
        }

    }

    private getLeadingTeam(): TeamUnderOperation | undefined {

       const topRankedTeams = this.teams.filter(team => team.score.rank === 1);

        // Return one or none (no equalities)
        if (topRankedTeams.length === 1) {
            return topRankedTeams[0];
        }

        return undefined;
    }

    public clearScores(): void {

        this.operators.map(operator => {
            operator.score.points = operator.score.rank = 0;
        });

        this.teamsSlots.map(teamSlot => {
            if (teamSlot.score) {
                teamSlot.score.points = teamSlot.score.rank = 0;
            }
        });
    }

    public clearRoles(): void { // TODO: Put role into data, like it is now made with devices.

        this.operators.map(operator => {
            operator.role = undefined;
        });

        this.teamsSlots.map(team => {
            team.role = undefined;
        });
    }

    public clearData(): void {

        this.operators.map(operator => {
            operator.data = {};
        });

        this.teamsSlots.map(team => {
            team.data = {};
        });
    }



    public save(): void {

        localStorage.setItem(STORAGE_NAME, JSON.stringify({
            version: STORAGE_VERSION,
            operators: this.operators
        }));

    }

    public load(): void {

        const stored = localStorage.getItem(STORAGE_NAME);

        if (!stored) {
            return;
        }

        const data = JSON.parse(stored);

        if (data.version != STORAGE_VERSION) {
            return;
        }

        this.operators = data.operators || [];

        this.updateTeams();
    }

}

