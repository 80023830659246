@if(report && report.timeline.length) {

    @let devices = selectedTimelineItem?.context.devices;
    @let teams = selectedTimelineItem?.context.teams;

    <!-- overview components -->
     @if(overview.teamsRanking) {
        <lui-operation-teams-ranking
            wuiTacticalCorners
            [teams]="teams"
            [pointsCurrency]="report.settings.pointsCurrency"
            [pointsLimit]="report.settings.pointsLimit">
        </lui-operation-teams-ranking>
    }

    @if(overview.teamsRoles) {
        <lui-operation-teams-roles
            wuiTacticalCorners
            [teams]="teams"
            [pointsCurrency]="report.settings.pointsCurrency"
            [pointsLimit]="report.settings.pointsLimit">
        </lui-operation-teams-roles>
    }

    @if(overview.teamsProgress) {
        <lui-operation-teams-progress
            wuiTacticalCorners
            [teams]="teams">
        </lui-operation-teams-progress>
    }

    @if(overview.areasRoles) {
        <lui-operation-areas-roles
            wuiTacticalCorners
            [devices]="devices">
        </lui-operation-areas-roles>
    }

    @if(overview.areasCaptures) {
        <lui-operation-areas-captures wuiTacticalCorners
            [devices]="devices"
            [teams]="teams">
        </lui-operation-areas-captures>
    }

    <!-- timeline -->
    <lui-timeline wuiTacticalCorners
        [customIconsRootUrl]="customIconsRootUrl"
        [items]="report.timeline"
        [selectedIndex]="selectedTimelineItemIndex"
        [scrolling]="isReplaying"
        (itemClick)="timelineItemClick($event)">
    </lui-timeline>

    <!-- only when completed -->
    @if(selectedTimelineItemIndex === report.timeline.length - 1) {

        <!-- winner -->
        @if (report.winner) {
            <lui-operation-winner
                [winner]="report.winner">
            </lui-operation-winner>
        }

        <!-- trophies -->
        @if(report.trophies) {
            <lui-operation-trophies
                [trophies]="report.trophies"
                (trophyClick)="trophyClick.emit($event)">
            </lui-operation-trophies>
        }
    }

}
