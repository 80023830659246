import { Injectable } from '@angular/core';
import { Products, OperationWinner, OperationTrophy } from '@lightning/lightning-definitions';

import { ModalAlertComponent } from '../../../../shared/components/modal-alert/modal-alert.component';
import { NfcData, NfcOperatorData, NfcCashupData, NfcPartData, NfcSpecialData, } from '../../../../shared/enums/lora.enum';

import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';


@Injectable({
    providedIn: 'root'
})

export class SandboxService extends OperationBase {

    constructor() {

        super('sandbox');

        this.settings = {
            version: 1,

            name: '',
            timeLimit: 1800000,
        };

        this.organisatorActions = [
            this.getOrganisatorActionNote()
        ];

        this.loraProtocolService.onNfcReceive
            .subscribe((data: NfcData) => { this.onNfcReceive(data); });

        this.loraProtocolService.onNfcOperatorReceive
            .subscribe((data: NfcOperatorData) => { this.onNfcOperatorReceive(data); });

        this.loraProtocolService.onNfcCashupReceive
            .subscribe((data: NfcCashupData) => { this.onNfcCashupReceive(data); });

        this.loraProtocolService.onNfcPartReceive
            .subscribe((data: NfcPartData) => { this.onNfcPartReceive(data); });

        this.loraProtocolService.onNfcSpecialReceive
            .subscribe((data: NfcSpecialData) => { this.onNfcSpecialReceive(data); });
    }


    public override getRequierements(): OperationRequierements {

        const groundModules = this.loraProtocolService.getDevicesByProduct(Products.GroundModule);

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                name: 'Count of teams',
                value: this.registerService.teams.length,
                isReady: true,
                help: '',
            },
            {
                name: 'Ground modules',
                value: groundModules.length.toString(),
                isReady: true,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady = requierements.some(requierement => requierement.isReady === false) === false;

        return { requierements, isReady };
    }

    public override settingsApply(): void {

        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return undefined;
    }

    public override getTrophies(): Array<OperationTrophy> {

        return [];
    }


    private onNfcReceive(data: NfcData): void {

        const { sender, record } = data;

        if (this.state !== OperationStates.Processing) {
            return;
        }

        const description =
            '\nType: Nfc card' +
            '\nArea: ' + sender.name +
            '\nRecord: ' + record;

        this.environmentService.modalOpen({
            title: 'Sandbox operation',
            component: ModalAlertComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description
            }
        });

    }

    private async onNfcOperatorReceive(data: NfcOperatorData): Promise<void> {

        const { sender, operator } = data;

        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const description =
            '\nType: Nfc operator card' +
            '\nArea: ' + sender.name +
            '\nOperator: ' + operator.name +
            '\nTeam: ' + team?.name;

        this.environmentService.modalOpen({
            title: 'Sandbox operation',
            component: ModalAlertComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description
            }
        });

    }

    private async onNfcCashupReceive(data: NfcCashupData): Promise<void> {

        const { sender, operator, total } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const description =
            '\nType: Nfc cashup' +
            '\nArea: ' + sender.name +
            '\nOperator: ' + operator.name +
            '\nTeam: ' + team?.name +
            '\nTotal: ' + total;

        this.environmentService.modalOpen({
            title: 'Sandbox operation',
            component: ModalAlertComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description
            }
        });

    }

    private async onNfcPartReceive(data: NfcPartData): Promise<void> {

        const { sender, operator, number } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const description =
            '\nType: Nfc part' +
            '\nArea: ' + sender.name +
            '\nOperator: ' + operator.name +
            '\nTeam: ' + team?.name +
            '\nPart number: ' + number;

        this.environmentService.modalOpen({
            title: 'Sandbox operation',
            component: ModalAlertComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description
            }
        });

    }

    private async onNfcSpecialReceive(data: NfcSpecialData): Promise<void> {

        const { sender, operator, special } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        const description =
            '\nType: Nfc special' +
            '\nArea: ' + sender.name +
            '\nOperator: ' + operator.name +
            '\nTeam: ' + team?.name +
            '\nSpecial: ' + special;

        this.environmentService.modalOpen({
            title: 'Sandbox operation',
            component: ModalAlertComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description
            }
        });
    }

}
