import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import {
    ProductsWithSoftware,
    LoraPacket,
    MinimalCompatibleVersions,
    NfcTagsTypes,
    OperatorUnderOperation,
    SementicVersionUtils,
    TeamUnderOperation,
    SoftwaresChannels,
    UnpublishedVersion,
    LoraDevice
} from '@lightning/lightning-definitions';

import { EnvironmentService } from '@lightning/wild-environment';
import { StyleUtils } from '@lightning/wild-ui';
import {
    NfcData,
    NfcOperatorData,
    NfcCashupData,
    NfcSpecialData,
    LongRangeProtocolsNames,
    NfcPartData
} from '../../enums/lora.enum';

import { GatewayService } from '../gateway/gateway.service';
import { LoraService } from '../lora/lora.service';
import { RegisterService } from '../register/register.service';
import { SettingsService, SettingsWifi } from '../settings/settings.service';

@Injectable({
    providedIn: 'root'
})

export class LoraProtocolService extends LoraService {

    public onNfcReceive: EventEmitter<NfcData> = new EventEmitter();
    public onNfcOperatorReceive: EventEmitter<NfcOperatorData> = new EventEmitter();
    public onNfcCashupReceive: EventEmitter<NfcCashupData> = new EventEmitter();
    public onNfcPartReceive: EventEmitter<NfcPartData> = new EventEmitter();
    public onNfcSpecialReceive: EventEmitter<NfcSpecialData> = new EventEmitter();

    constructor(
        private translateService: TranslateService,
        private environmentService: EnvironmentService,
        private settingsService: SettingsService,
        private registerService: RegisterService,
                gatewayService: GatewayService) {

        super(gatewayService);

        this.onReceivePacket.subscribe(reception => {

            const { device, packet } = reception;

            // No state available yet
            if (!device.state) {
                return;
            }

            // Bind maiden real devices automatically
            if (!device.state.bindedGatewayId && !device.isVirtual) {

                this.sendBindCommand(packet.senderId);

                console.log(`Bind command sent to the maiden device ${ packet.senderId }.`);

                return;
            }

            // Check the compatibility of the software
            if (packet.payload.state) {

                const minimalCompatibleSoftware = MinimalCompatibleVersions[packet.payload.state.product as ProductsWithSoftware];

                device.isUsingUnpublishedVersion =
                    packet.payload.state.software === UnpublishedVersion;

                device.isCompatible =
                    SementicVersionUtils.isCompatibleFromStrings(packet.payload.state.software, minimalCompatibleSoftware) ||
                    device.isUsingUnpublishedVersion;   // Consider unpublished version as always compatible for the development


                if (!device.isCompatible) {

                    this.environmentService.notificationOpen({
                        message: this.translateService.instant('services.loraProtocol.incompatibleSoftware', { type: device.state.product, name: device.name || device.id }),
                        color: StyleUtils.getVariable('--color-error'),
                        callback: () => {
                            this.environmentService.windowOpenByAppId('updates');
                        }
                    });
                }

                this.checkDeviceUsability(device);
            }

            // Ignore if unusable
            if(!device.isUsable) {

                console.warn(`LoraProtocolService: A packet received from the UNUSABLE device ${ device.id } has been ignored`);

                return;
            }

            if (!device.isEnabled) {

                console.warn(`LoraProtocolService: A packet received from the DISABLED device ${device.id} has been ignored`);

                return;
            }

            // Contextualize packets and fire dedicated events
            this.fireContextualizedEvent(device, packet);
        });

        this.onDeviceDisabled.subscribe(device => {

            // Clear the data of the device
            device.data = {};

            // Send a command to clear the module
            this.sendClearCommand(device.id);
        });
    }

    // ----------------------------------------------------------------------------------------------
    // Public methods

    /**
     * Send a Bind command to a device
     */
    public sendBindCommand(destinationId: string): void {
        this.send({ bind: {} }, destinationId);
    }

    /**
     * Send a Bind command to a device
     */
    public sendUnbindCommand(destinationId: string): void {
        this.send({ unbind: {} }, destinationId);
    }

    /**
     * Send a reset settings command to a device
     */
    public sendResetCommand(destinationId: string): void {
        this.send({ reset: {} }, destinationId);
    }

    /**
     * Send a clear command to one or all devices
     */
    public sendClearCommand(destinationId = '0'): void {
        this.send({ clear: {} }, destinationId);
    }

    /**
     * Send a discovery command to all devices
     */
     public sendDiscoveryCommand(): void {
        this.send({ discovery: {} });
    }

    /**
     * Send a range test command to all devices
     */
     public sendRangeTestCommand(): void {
        this.send({ rangeTest: {} });
    }

    /**
     * Send a Color command
     * @param value HTML color code
     * @param duration Duration in milliseconds (optional)
     * @param destinationId Destination id (optional)
     */
    public sendColorCommand(value: string, duration = 0, destinationId = '0'): void {
        this.send({ color: { value, duration } }, destinationId);
    }

    /**
     * Send a Update command
     * @param destinationId Destination id (optional)
     */
    public async sendUpdateCommand(wifiCredentials: SettingsWifi, channel: SoftwaresChannels, destinationId = '0'): Promise<void> {

        this.send({ update: { ...wifiCredentials, channel } }, destinationId);
    }


    // ----------------------------------------------------------------------------------------------
    // Protected methods

    /**
     * Deserialize compact payloads (string)
     * It is an override, comment it to rollback to the default option (JSON)
     * @param payload
     * @returns
     */
    protected override deserializePayload(payload: string | object): any {

        switch(this.settingsService.settings.longRange.protocol) {

            case LongRangeProtocolsNames.Json:
                return this.deserializePayloadAsJson(payload);

            case LongRangeProtocolsNames.Comptact:
                return this.deserializePayloadAsCompact(payload);

            default:
                return {};
        }
    }

    /**
     * Serialize compact payloads
     * It is an override, comment it to rollback to the default option (JSON)
     * @param payload
     * @returns
     */
    protected override serializePayload(payload: any): string {

        switch(this.settingsService.settings.longRange.protocol) {

            case LongRangeProtocolsNames.Json:
                return this.serializePayloadAsJson(payload);

            case LongRangeProtocolsNames.Comptact:
                return this.serializePayloadAsCompact(payload);

            default:
                return '';
        }
    }

    // ----------------------------------------------------------------------------------------------
    // Private methods

    private deserializePayloadAsJson(payload: string | object): any {

        // That is the native option
        return super.deserializePayload(payload);
    }

    private serializePayloadAsJson(payload: any): string {

        // That is the native option
        return super.serializePayload(payload);
    }


    private deserializePayloadAsCompact(payload: string | object): any {

        // Deserialize as the custom 'compact' protocol

        if (typeof payload !== 'string') {
            return;
        }

        const fields = (payload as string).split('|');

        switch(fields[0]) {

            case 'bindRequest':
                return {
                    bindRequest: {}
                };
            break;

            case 'state':
                if (fields.length > 6) {
                    return {
                        state: {
                            time:       fields[1],
                            product:    fields[2],
                            hardware:   fields[3],
                            software:   fields[4],
                            battery: parseInt(fields[5], 10),
                            bindedGatewayId: fields[6],
                        }
                    };
                }
            break;

            case 'discovery':
                return {
                    discovery: {}
                };
            break;

            case 'rangeTest':
                return {
                    rangeTest: {}
                };
            break;

            case 'location':
                return {
                    location: {
                        name:       fields[1],
                        longitude:  fields[2]?.length ? parseFloat(fields[2]) : undefined,
                        latitude:   fields[3]?.length ? parseFloat(fields[3]) : undefined,
                    }
                };
            break;

            case 'nfc':
                if(fields.length > 2) {
                    return {
                        nfc: {
                            uid:    fields[1],
                            record: fields[2]
                        }
                    };
                }
            break;

            case 'nfcCashup':
                if (fields.length > 2) {
                    return {
                        nfcCashup: {
                            owner: fields[1],
                            total: parseInt(fields[2], 10)
                        }
                    };
                }
            break;

            case 'nfcPart':
                if (fields.length > 2) {
                    return {
                        nfcPart: {
                            owner: fields[1],
                            number: parseInt(fields[2], 10)
                        }
                    };
                }
            break;

            case 'nfcSpecial':
                if (fields.length > 2) {
                    return {
                        nfcSpecial: {
                            owner: fields[1],
                            special: fields[2]
                        }
                    };
                }
            break;

            case 'log':
                if (fields.length > 1) {
                    return {
                        log: {
                            message: fields[1]
                        }
                    };
                }
            break;
        }

        return {
            unknown: payload
        };

    }

    private serializePayloadAsCompact(payload: any): string {

        // Serialize as the custom 'compact' protocol

        if (payload.color) {
            return `color|${ payload.color.value }|${ payload.color.duration }`;
        }

        if (payload.bind) {
            return `bind`;
        }

        if (payload.unbind) {
            return `unbind`;
        }

        if (payload.update) {
            return `update|${ payload.update.ssid }|${ payload.update.password }|${ payload.update.channel }`;
        }

        if (payload.reset) {
            return `reset`;
        }

        if (payload.clear) {
            return `clear`;
        }

        if (payload.discovery) {
            return `discovery`;
        }

        if (payload.rangeTest) {
            return `rangeTest`;
        }

        console.warn('LoraProtocolService: Unable to serialize to compact protocol, unkown payload type');

        return '';
    }


    private async fireContextualizedEvent(sender: LoraDevice, packet: LoraPacket): Promise<void> {

        // NFC Records
        if (packet.payload.nfc) {

            const record = packet.payload.nfc.record;

            if(record.startsWith(NfcTagsTypes.Operator) || record.startsWith(NfcTagsTypes.Guest)) {

                const operatorContext = this.getOperatorContext(record);

                if (!operatorContext) {

                    return;
                }

                this.onNfcOperatorReceive.emit({ sender, ...operatorContext });

                return;
            }

            this.onNfcReceive.emit({ sender, record });

            return;
        }

        // NFC Cashup
        if (packet.payload.nfcCashup) {

            const operatorContext = this.getOperatorContext(packet.payload.nfcCashup.owner);

            if (!operatorContext) {
                return;
            }

            const total = packet.payload.nfcCashup.total;

            this.onNfcCashupReceive.emit({ sender, ...operatorContext, total });

            return;
        }

        // NFC Part
        if (packet.payload.nfcPart) {

            const operatorContext = this.getOperatorContext(packet.payload.nfcPart.owner);

            if (!operatorContext) {
                return;
            }

            const number = packet.payload.nfcPart.number;

            this.onNfcPartReceive.emit({ sender, ...operatorContext, number });

            return;
        }

        // NFC Special
        if (packet.payload.nfcSpecial) {

            const operatorContext = this.getOperatorContext(packet.payload.nfcSpecial.owner);

            if (!operatorContext) {
                return;
            }

            const special: string = packet.payload.nfcSpecial.special;

            this.onNfcSpecialReceive.emit({ sender, ...operatorContext, special });

            return;
        }

    }

    private getOperatorContext(operatorFrienlyName: string): { operator: OperatorUnderOperation, team: TeamUnderOperation | undefined } | undefined {

        const operator = this.registerService.getOrCreateOperatorFromFrienlyName(operatorFrienlyName);

        if(!operator) {
            return;
        }

        return {
            operator,
            team: operator.teamId ? this.registerService.getTeam(operator.teamId) : undefined
        };
    }

}
