import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { PrisonersService } from '../../services/prisoners.service';

@Component({
    selector: 'app-prisonners-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss'],
})
export class DebriefingComponent {

    constructor(
        public onlineService: OnlineService,
        public operationService: PrisonersService) { }
}
