<app-body>
    <wui-section [name]="'shared.generalities' | translate ">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate | capitalize }}</div>
            <wui-input value type="text" [(value)]="lootingService.settings.name"></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate ">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate | capitalize }}</div>
            <wui-select value [(value)]="lootingService.settings.timeLimit">
                <option *ngFor="let item of OperationProcessDurations | keyValue" [value]="item.value">{{ 'apps.operations.setup.durations.' + item.key | translate }}</option>
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name>{{ 'apps.operations.setup.pointsLimit' | translate | capitalize }}</div>
            <wui-input value type="number" [min]="0" [(value)]="lootingService.settings.pointsLimit"></wui-input>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="lootingService.preview()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="lootingService.brief()"
                    [wuiDisabled]="lootingService.settingsAreValid() === false">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
