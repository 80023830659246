import { Component } from '@angular/core';
import { GroundLocations, ProductsWithSoftware } from '@lightning/lightning-definitions';
import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { RegisterService } from '../../../shared/services/register/register.service';
import { OperatorsNames } from '../../enums/simulation.enums';

@Component({
    selector: 'app-simulation-scene',
    templateUrl: './scene.component.html',
    styleUrls: ['./scene.component.scss']
})
export class SceneComponent {

    public devices = {
        count: 3,
        maxCount: Object.keys(GroundLocations).length
    }

    public operators = {
        count: 6,
        maxCount: OperatorsNames.length,
    }

    constructor(
        private registerService: RegisterService,
        private loraProtocolService: LoraProtocolService) { }


    public run(): void {

        this.registerService.clearOperators();

        if (this.operators.count > OperatorsNames.length) {
            this.operators.count = OperatorsNames.length;
        }

        for (let index = 0; index < this.operators.count; index++) {
            this.registerService.getOrCreateOperator(this.registerService.operators.length + 1, OperatorsNames[index]);
        }

        this.loraProtocolService.clearDevices();

        if (this.devices.count > Object.keys(GroundLocations).length) {
            this.devices.count = Object.keys(GroundLocations).length;
        }

        for (let index = 0; index < this.devices.count; index++) {
            const device = this.loraProtocolService.addDevice(index.toString(10), ProductsWithSoftware.GroundModule, true);
            device.name = Object.keys(GroundLocations)[index];
        }

    }

}
