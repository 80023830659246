<app-container>

    <app-header logo="assets/apps/sandbox/logo.svg"
                [description]="'apps.sandbox.headerDescription' | translate"></app-header>
    <app-body>

        <wui-section [name]="'apps.sandbox.sectionModalWindows' | translate">
            <wui-button (click)="modalAlert()">alert</wui-button>
            <wui-button (click)="modalCards()">cards</wui-button>
            <wui-button (click)="modalInput()">input</wui-button>
            <wui-button (click)="modalTextarea()">textarea</wui-button>
            <wui-button (click)="modalList(5)">list (short)</wui-button>
            <wui-button (click)="modalList(50)">list (long)</wui-button>
            <wui-button (click)="modalTeamAssign()">team assign</wui-button>
            <wui-button (click)="modalYesNo()">yes no</wui-button>
        </wui-section>

        <wui-section [name]="'apps.sandbox.sectionNotifications' | translate">
            <wui-button (click)="notificationOpen()">notification</wui-button>
        </wui-section>

        <wui-section [name]="'apps.sandbox.screen' | translate">
            <wui-button (click)="screenCapture()">screen capture</wui-button>
            <img [src]="screenCaptureImage">
        </wui-section>

        <wui-section [name]="'apps.sandbox.sectionIpcXElectron' | translate">
            <wui-button (click)="ipcService.send('message', 'hello from angular')">electron send</wui-button>
            <wui-button (click)="ipcService.send('openDevTools')">openDevTools</wui-button>
        </wui-section>

    </app-body>

</app-container>
