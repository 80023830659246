import { Component, OnInit } from '@angular/core';
import { PmrService } from 'apps/headquarter/src/app/apps/shared/services/pmr/pmr.service';

@Component({
    selector: 'app-manual',
    templateUrl: './manual.component.html',
    styleUrls: ['./manual.component.scss']
})

export class ManualComponent implements OnInit {

    public channels: Array<number> = [];
    public subChannels: Array<number> = [];

    constructor(public pmrService: PmrService) { }

    ngOnInit() {

        for(let i = 1; i <= 8; i++) {
            this.channels.push(i);
        }

        for(let i = 1; i <= 10 /*36*/; i++) {
            this.subChannels.push(i);
        }

    }

    public setTxChannel(channel: string) {

        this.pmrService.txChannel = channel;
    }

    public startTransmission() {

        this.pmrService.startTransmission();
    }
    
    public endTransmission() {

        this.pmrService.endTransmission();
    }

}
