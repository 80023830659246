<app-body>
    <div class="time" wuiTacticalCorners>
        {{ timer.time | milliseconds }}
    </div>

    <p>
        {{ 'apps.operations.steps.deploying.description' | translate }}
    </p>

    {{ 'apps.operations.steps.deploying.manual' | translate }}
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="contaminationService.brief()">{{
            'shared.back' | translate
        }}</wui-button>
        <wui-button (click)="continue()">{{
            'shared.continue' | translate
        }}</wui-button>
    </wui-buttons>
</app-footer>
