import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';

import { WarmupModule } from './modes/warmup/warmup.module';
import { DominationModule } from './modes/domination/domination.module';
import { LootingModule } from './modes/looting/looting.module';
import { ConvoyModule } from './modes/convoy/convoy.module';
import { DropsModule } from './modes/drops/drops.module';
import { DestructionModule } from './modes/destruction/desctruction.module';
import { PrisonersModule } from './modes/prisoners/prisoners.module';
import { AssemblyModule } from './modes/assembly/assembly.module';
import { ContaminationModule } from './modes/contamination/contamination.module';
import { SandboxModule } from './modes/sandbox/sandbox.module';
import { OperationsComponent } from './components/operations/operations.component';

@NgModule({
    declarations: [
        OperationsComponent
    ],
    imports: [
        CommonModule,
        SharedModule,

        WarmupModule,
        DominationModule,
        LootingModule,
        ConvoyModule,
        DropsModule,
        DestructionModule,
        PrisonersModule,
        AssemblyModule,
        ContaminationModule,
        SandboxModule
    ],
    exports: [
        OperationsComponent
    ]
})

export class OperationsModule { }
