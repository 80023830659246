import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';

import { SharedModule } from '../shared/shared.module';

import { RegisterComponent } from './components/register/register.component';
import { OperatorsComponent } from './components/operators/operators.component';
import { TeamsComponent } from './components/teams/teams.component';


@NgModule({
    declarations: [
        RegisterComponent,
        OperatorsComponent,
        TeamsComponent
    ],
    imports: [
        CommonModule,
        WildUiModule,
        SharedModule
    ],
    exports: [
        RegisterComponent
    ]
})

export class RegisterModule { }
