import { Directive, ElementRef } from '@angular/core';
import { SettingsService } from '../../services/settings/settings.service';

@Directive({
    selector: '[usersOnly]'
})
export class UsersOnlyDirective {

    private defaultDisplay: string = '';

    constructor(
        private elementRef: ElementRef,
        private settingsService: SettingsService) { }

    public ngOnInit() {

        this.defaultDisplay = this.elementRef.nativeElement.style.display;

        this.settingsService.onSaved
            .subscribe(() => this.update());

        this.update();
    }

    private update(): void {

        this.elementRef.nativeElement.style.display = this.settingsService.isDeveloppersModeEnabled ? 'none' : this.defaultDisplay;
    }
}
