import { Injectable } from '@angular/core';
import { TeamUnderOperation, ProductsWithSoftware, OperationWinner, OperationTrophy, OperationActions, PmrSounds, OperatorAvatarName } from '@lightning/lightning-definitions';
import { NfcOperatorData } from '../../../../shared/enums/lora.enum';
import { OperationBase } from '../../shared/classes/operation.base';
import { OperationStates } from '../../shared/enums/operation.enums';
import { OperationRequierements, OperationRequierement } from '../../shared/interfaces/operation.interface';


@Injectable({
    providedIn: 'root',
})
export class WarmupService extends OperationBase {

    constructor() {

        super('warmup');

        // Define operation settings
        this.settings = {
            version: 1,

            name: 'Warmup op',
            timeLimit: 1800000,
        };

        // Define organisator actions
        this.organisatorActions = [this.getOrganisatorActionNote()];

        // Subscribe to events
        this.loraProtocolService.onNfcOperatorReceive.subscribe(
            (data: NfcOperatorData) => {
                this.onNfcOperatorReceive(data);
            }
        );

        this.registerService.onTeamsRankingLeadChanged.subscribe(
            (team: TeamUnderOperation) => this.teamsRankingLeadChanged(team)
        );
    }

    public override getRequierements(): OperationRequierements {
        const groundModules = this.loraProtocolService.getDevicesByProduct(
            ProductsWithSoftware.GroundModule
        );

        // Describing requierements
        const requierements: Array<OperationRequierement> = [
            {
                // 2 teams or more
                name: 'Count of teams',
                value: this.registerService.teams.length,
                isReady: this.registerService.teams.length >= 2,
                help: '',
            },
            {
                // One ground module per team
                name: 'Ground modules',
                value: groundModules.length.toString(),
                isReady: groundModules.length > 0 && groundModules.length >= this.registerService.teams.length,
                help: '',
            }
        ];

        // Check if all requierements are ready
        const isReady =
            requierements.some(
                (requierement) => requierement.isReady === false
            ) === false;

        return { requierements, isReady };
    }

    public override settingsAreValid(): boolean {

        return true;
    }

    public override settingsApply(): void {
        // Timer setup
        this.timer.setCountDown(this.settings.timeLimit);

        // Save settings of the next time
        this.settingsSave();
    }

    public override getWinner(): OperationWinner | undefined {

        return this.getWinnerByTeamRank();
    }

    public override getTrophies(): Array<OperationTrophy> {

        return [];
    }

    private async onNfcOperatorReceive(data: NfcOperatorData): Promise<void> {

        const { sender, operator } = data;

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }
        // Ensure the operator has a team (use or ask for)
        const team = data.team || await this.registerService.askOperatorTeam(operator);

        if (!team) {
            return;
        }

        // Increase the score of all other teams
        this.registerService.teams
            .filter(t => t !== team)
            .forEach(t => this.registerService.increaseTeamScore(t, 1000));

        // Fill the timeline
        this.timelinePush({
            level: 1,
            action: OperationActions.OperatorRespawned,
            data: {
                team, operator
            },
            customIconPath:
                this.onlineService.getOperatorAvatarPath(operator.number, OperatorAvatarName.Neutral)
        });

        // Announce via PMR
        //

    }

    private teamsRankingLeadChanged(team: TeamUnderOperation): void {

        // Check the operation is processing
        if (this.state !== OperationStates.Processing) {
            return;
        }

        // Be sure the main announce is before
        // [ USE IT ONLY ON TEAM RANKING BASED OPERATIONS ]
        setTimeout(() => {
            this.pmrService.announce(
                (PmrSounds as any)['TeamLeads' + team.name]
            );
        }, 1000);

        // [ CUSTOM LOGIC HERE ]
    }
}
