import { Component, OnInit } from '@angular/core';
import { DropsService } from '../../services/drops.service';

@Component({
  selector: 'app-drops-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})

export class PreviewComponent implements OnInit {

  constructor(public dropsService: DropsService) { }

  ngOnInit(): void {
  }

}
