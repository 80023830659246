<wui-section [name]="'shared.action' | translate">

    <wui-entry>
        <div name>{{ 'shared.operator' | translate | capitalize }}</div>
        <wui-select value [(value)]="action.operatorNumber" type="number">
            @for(operator of registerService.operators; track operator.number) {
                <option [value]="operator.number">
                    {{ operator.name }}
                </option>
            }
        </wui-select>
    </wui-entry>

    <wui-entry>
        <div name>{{ 'shared.action' | translate | capitalize }}</div>
        <wui-select value [(value)]="action.action">
            @for(action of ActionsNames | keyValue; track action) {
                <option [value]="action.value">
                    {{ 'apps.simulation.simulator.actions.' + action.value | translate }}
                </option>
            }
        </wui-select>
    </wui-entry>

    @if(action.action === ActionsNames.Resource) {
        <wui-entry>
            <div name>{{ 'shared.value' | translate | capitalize }}</div>
            <wui-input [(value)]="action.value" type="number" [min]="1000"></wui-input>
        </wui-entry>
    }

    @if(action.action === ActionsNames.Part) {
        <wui-entry>
            <div name>{{ 'shared.number' | translate | capitalize }}</div>
            <wui-input [(value)]="action.number" type="number" [min]="1"></wui-input>
        </wui-entry>
    }

    <wui-entry>
        <div name>{{ 'shared.module' | translate | capitalize }}</div>
        <wui-select value [(value)]="action.moduleName">
            @for(item of loraProtocolService.devices; track item.id) {
                <option [value]="item.name">
                    {{ item.name }}
                </option>
            }
        </wui-select>
    </wui-entry>

    <wui-entry>
        <div name></div>
        <wui-button value (click)="run()">
            {{ 'shared.simulate' | translate }}</wui-button>
    </wui-entry>

</wui-section>
