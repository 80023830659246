{{ targets }}

<app-body>

    <wui-section *ngFor="let target of targets" [name]="target || ''">

        <wui-entry *ngFor="let target of [].constructor(4); let index = index">
            <div name>Slot {{ index }}</div>
            <!-- <app-s value [(value)]="txChannel">
                <option *ngFor="let team of registerService.teams" [value]="team.pmr">{{team.name}} team ({{team.pmr}})</option>
                <option [value]="pmrService.broadcastChannel">broadcast {{pmrService.broadcastChannel}}</option>
            </wui-select> -->
            <!-- <wui-select value [(value)]="txChannel">
                <option *ngFor="let team of registerService.teams" [value]="team.pmr">{{team.name}} team ({{team.pmr}})</option>
                <option [value]="pmrService.broadcastChannel">broadcast {{pmrService.broadcastChannel}}</option>
            </wui-select> -->
        </wui-entry>

    </wui-section>

</app-body>
