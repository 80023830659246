import { Component, OnInit } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';

import { GatewayService } from '../../../shared/services/gateway/gateway.service';
import { OperationsService } from '../../services/operations/operations.service';

@Component({
    selector: 'app-operations',
    templateUrl: './operations.component.html',
    styleUrls: ['./operations.component.scss']
})

export class OperationsComponent implements OnInit {

    constructor(
        public environmentService: EnvironmentService,
        public gatewayService: GatewayService,
        public operationsService: OperationsService) {
    }

    ngOnInit() {

        this.gatewayService.connect();
    }
}
