<app-header [logo]="'logo'" [description]="'modals.teamScoreAdjust.description' | translate | capitalize"></app-header>

<app-body>

    <wui-entry>

        <div name>{{ 'shared.team' | translate | capitalize }}</div>

        <!-- <wui-select value [(value)]="team">
            <option
                *ngFor="let team of registerService.teams"
                [value]="team">
                {{ team.name }}
            </option>
        </wui-select> -->

        <select value [(ngModel)]="team">
            <option
                *ngFor="let team of registerService.teams"
                [ngValue]="team">
                {{ team.name }}
            </option>
        </select>

    </wui-entry>

    <wui-entry>

        <div name>{{ 'modals.teamScoreAdjust.reason' | translate | capitalize }}</div>

        <!-- <wui-select value ([value])="isBonus">
            <option value="true">{{ 'shared.bonus' | translate | capitalize }}</option>
            <option value="false">{{ 'shared.penality' | translate | capitalize }}</option>
        </wui-select>
     -->

        <!-- <wui-select value [(value)]="isBonus">
            <option value="true">{{ 'shared.bonus' | translate | capitalize }}</option>
            <option value="false">{{ 'shared.penality' | translate | capitalize }}</option>
        </wui-select>
     -->
        <select value [(ngModel)]="isBonus">
            <option [ngValue]="true">{{ 'shared.bonus' | translate | capitalize }}</option>
            <option [ngValue]="false">{{ 'shared.penality' | translate | capitalize }}</option>
        </select>

    </wui-entry>

    <wui-entry>

        <div name>{{ 'shared.points' | translate | capitalize }}</div>

        <wui-input value [(value)]="points" type="number" [min]="0"></wui-input>

    </wui-entry>



    <!-- <wui-entry>

        <div name>{{ 'modals.teamScoreAdjust.reason' | translate | capitalize }}</div>

        <wui-textarea value [(value)]="reason"></wui-textarea>

    </wui-entry> -->

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="confirmClick()" [wuiDisabled]="isInvalid">ok</wui-button>
    </wui-buttons>
</app-footer>
