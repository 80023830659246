import { EventEmitter, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { TimelineItem, OperationReport, NfcTagsSpecialsActions, PmrSounds, OperationActions, OperationWinner, TeamUnderOperation, OperatorUnderOperation, OperationTrophy, OPERATION_REPORT_VERSION } from '@lightning/lightning-definitions';
import { UuidUtils } from '@lightning/utils';
import { EnvironmentService } from '@lightning/wild-environment';
import { StringUtils } from '@lightning/wild-ui';

import { Timer, TimerMode } from '@lightning/headquarter/app/apps/shared/classes/timer';
import { ModalCardsComponent } from '@lightning/headquarter/app/apps/shared/components/modal-cards/modal-cards.component';
import { ModalTeamScoreAdjustComponent } from '@lightning/headquarter/app/apps/shared/components/modal-team-score-adjust/modal-team-score-adjust.component';
import { ModalTextareaComponent } from '@lightning/headquarter/app/apps/shared/components/modal-textarea/modal-textarea.component';
import { NfcSpecialData } from '@lightning/headquarter/app/apps/shared/enums/lora.enum';
import { LoraProtocolService } from '@lightning/headquarter/app/apps/shared/services/lora-protocol/lora-protocol.service';
import { SettingsService } from '@lightning/headquarter/app/apps/shared/services/settings/settings.service';
import { PmrService } from '@lightning/headquarter/app/apps/shared/services/pmr/pmr.service';
import { RegisterService } from '@lightning/headquarter/app/apps/shared/services/register/register.service';
import { ReportsService } from '@lightning/headquarter/app/apps/shared/services/reports/reports.service';
import { OnlineService } from '@lightning/lightning-services';

import { OperationSteps, OperationStates } from '../enums/operation.enums';
import { OperationOrganisatorAction, OperationRequierements } from '../interfaces/operation.interface';


const OPERATION_COMPLETE_SOON_MINUTES = 5;

export class OperationBase {

    public stepChanged: EventEmitter<OperationSteps> = new EventEmitter<OperationSteps>();
    public stateChanged: EventEmitter<OperationStates> = new EventEmitter<OperationStates>();

    public settings: any = {};
    public timer: Timer = new Timer();
    public timeline: Array<TimelineItem> = [];
    public report: OperationReport | undefined;

    // Dependencies injection
    protected translateService = inject(TranslateService);
    protected environmentService = inject(EnvironmentService);
    protected settingsService = inject(SettingsService);
    protected registerService = inject(RegisterService);
    protected loraProtocolService = inject(LoraProtocolService);
    protected pmrService = inject(PmrService);
    protected reportsService = inject(ReportsService);
    protected onlineService = inject(OnlineService);

    protected organisatorActions: Array<OperationOrganisatorAction> = [];

    protected _mode: string;
    protected _step: OperationSteps = OperationSteps.Previewing;
    protected _state: OperationStates = OperationStates.Iddle;

    constructor(mode: string) {

        this._mode = mode;

        this.loraProtocolService.onNfcSpecialReceive.subscribe((data: NfcSpecialData) => {

            switch(this.step) {

                case OperationSteps.Deploying:

                    if (data.special === NfcTagsSpecialsActions.OperationStartResume) {
                        this.operate(true);
                    }

                break;

                case OperationSteps.Operating: {

                    switch (data.special) {
                        case NfcTagsSpecialsActions.OperationStartResume:
                            this.state === OperationStates.Iddle ? this.start() : this.resume();
                        break;
                        case NfcTagsSpecialsActions.OperationPause:
                            this.pause();
                        break;
                        case NfcTagsSpecialsActions.OperationComplete:
                            this.complete();
                        break;
                    }
                }
            }
        });

        this.timer.onSyncMinute.subscribe({
            next: (minute: number) => {
                if (this.timer.mode === TimerMode.Countdown && minute === OPERATION_COMPLETE_SOON_MINUTES) {
                    this.completeSoon();
                }
            }
        });

        this.timer.onComplete.subscribe(() => this.complete());
    }

    public get mode(): string {

        return this._mode;
    }

    public initialize(): void {

        this.clear();

        this.settingsLoad();

        this.preview();
    }

    public clear(): void {

        // Clear timeline
        this.timeline = [];

        // Clear timer
        this.timer.clear();

        // Clear operators and teams scores
        this.registerService.clearScores();

        // Clear operators and teams roles
        this.registerService.clearRoles();

        // Clear operators and teams aditional data
        this.registerService.clearData();


        // Clear lora items aditional data
        this.loraProtocolService.clearData();

        // Clear the ground modules
        // Broadcast burst: Faster than addressed sending for each device, most reliable than a single broadcast sending.
        for (let i = 0; i < 3; i++) {
            this.loraProtocolService.sendClearCommand();
        }

    }


    // ----------------------------------------------------------------
    // Workflow (step & state)
    // ----------------------------------------------------------------

    public get step(): OperationSteps {

        return this._step;
    }

    public set step(step: OperationSteps) {

        this._step = step;

        this.stepChanged.emit(this._step);
    }

    public preview(): void {

        this.step = OperationSteps.Previewing;
    }

    public setup(): void {

        this.step = OperationSteps.Setup;
    }

    public brief(): void {

        this.step = OperationSteps.Briefing;

        if (this.settingsService.settings.operations.isBriefingAnnounceEnabled) {

            this.pmrService.stop();

            this.pmrService.announce(PmrSounds.OperationBriefing);
        }

    }

    public briefSpeech(): void {

        const sound = (PmrSounds as any)['OperationBriefingSpeech' + StringUtils.capitalize(this._mode)];

        this.pmrService.announce(sound);
    }

    public deploy(): void {

        this.step = OperationSteps.Deploying;

        if (this.settingsService.settings.operations.isDeployingAnnounceEnabled) {

            this.pmrService.stop();

            this.pmrService.announce(PmrSounds.OperationDeploying);
        }
    }

    public operate(isImmediateStart: boolean): void {

        this.step = OperationSteps.Operating;

        if (isImmediateStart) {
            this.start();
        }
    }

    public debrief(): void {

        this.step = OperationSteps.Debriefing;

        this.pmrService.announce(PmrSounds.OperationDebriefing);
    }

    public share(): void {

        this.step = OperationSteps.Sharing;
    }

    public exit(): void {

        this._state = OperationStates.Iddle;

        this.step = OperationSteps.Exiting;

        this.clear();
    }



    public get state(): OperationStates {
        return this._state;
    }

    public set state(state: OperationStates) {

        this._state = state;

        this.stateChanged.emit(this._state);
    }

    public start(): void {

        if (this.state !== OperationStates.Iddle) {
            return;
        }

        if (this.settings.timeLimit > 0) {
            this.timer.startCountdown(this.settings.timeLimit);
        }

        this.timelinePush({
            level: 2,
            action: OperationActions.OperationStarted,
            data: { mode: this._mode }
        });

        this.pmrService.announce(PmrSounds.Caution);
        this.pmrService.announce(PmrSounds.OperationStarted);

        this.state = OperationStates.Processing;
    }

    public pause(): void {

        if (this.state !== OperationStates.Processing) {
            return;
        }

        this.timer.pause();

        this.timelinePush({
            level: 2,
            action: OperationActions.OperationPaused});

        this.pmrService.announce(PmrSounds.OperationPaused);

        this.state = OperationStates.Paused;
    }

    public resume(): void {

        if (this.state !== OperationStates.Paused) {
            return;
        }

        this.timer.resume();

        this.timelinePush({
            level: 2,
            action: OperationActions.OperationResumed});

        this.pmrService.announce(PmrSounds.OperationResumed);

        this.state = OperationStates.Processing;
    }

    private completeSoon(): void {

        this.environmentService.notificationOpen({
            logo: 'assets/apps/operations/logo.svg',
            message: this.translateService.instant('apps.operations.notifications.completeSoon', { minutes: OPERATION_COMPLETE_SOON_MINUTES })
        });

        this.pmrService.announce(PmrSounds.OperationCompleteSoon);
    }

    public complete(): void {

        if (this.state === OperationStates.Complete) {
            return;
        }

        this.timer.clear();

        this.timelinePush({
            level: 2,
            action: OperationActions.OperationComplete
        });

        this.pmrService.announce(PmrSounds.Caution);
        this.pmrService.announce(PmrSounds.OperationCompleted);

        this.generateReport();

        this.reportSave();

        this.state = OperationStates.Complete;

        this.debrief();
    }

    // ----------------------------------------------------------------
    // Requierements
    // ----------------------------------------------------------------

    public getRequierements(): OperationRequierements {

        return {
            requierements: [],
            isReady: true
        }
    }

    // ----------------------------------------------------------------
    // Settings
    // ----------------------------------------------------------------

    public settingsSave(): void {

        localStorage.setItem('operation-' + this.mode + '-settings', JSON.stringify(this.settings));
    }

    public settingsLoad(): void {

        const stored = localStorage.getItem('operation-' + this.mode + '-settings');

        if (!stored) {
            return;
        }

        const data = JSON.parse(stored);

        if (data.version === this.settings.version) {
            this.settings = data;
        }
    }

    public settingsAreValid(): boolean {

        // ...

        return true;
    }

    public settingsApply(): void {

        // ...

        this.settingsSave();
    }

    // ----------------------------------------------------------------
    // Organisator's actions
    // ----------------------------------------------------------------

    public async selectOrganisatorAction(): Promise<void> {

        const result = await this.environmentService.modalOpen({
            title: 'action',
            component: ModalCardsComponent,
            inputs: {
                logo: 'assets/apps/operations/logo.svg',
                description: 'Select an operation action',
                items: this.organisatorActions
            }
        });

        if (result) {
            result.data.process();
        }
    }

    protected getOrganisatorActionNote(): OperationOrganisatorAction {
        return {
            title: 'write the story',
            description: 'write a note in the timeline',
            logo: 'assets/apps/operations/shared/organisatorActions/logo/note.svg',
            data: {
                process: async () => {

                    const note = await this.environmentService.modalOpen({
                        title: 'action',
                        component: ModalTextareaComponent,
                        inputs: {
                            logo: 'assets/apps/operations/shared/organisatorActions/logo/note.svg',
                            description: 'write a note in the timeline',
                            placeholder: 'Type here'
                        }
                    });

                    if (note) {
                        this.timelinePush({
                            level: 1,
                            action: OperationActions.Note,
                            data: {
                                note
                            }
                        });
                    }
                }
            }
        };
    }

    protected getOrganisatorActionTeamBonusPenality(): OperationOrganisatorAction {
        return {
            title: 'bonus / penality',
            logo: 'assets/apps/operations/shared/organisatorActions/logo/bonusPenality.svg',
            description: 'for a team',
            data: {
                process: async () => {

                    const data = await this.environmentService.modalOpen({
                        title: 'bonus / penality',
                        component: ModalTeamScoreAdjustComponent,
                        inputs: {}
                    });

                    if (data) {

                        console.log(data.isBonus)

                        this.timelinePush({
                            level: 2,
                            action: data.isBonus ? OperationActions.TeamBonus : OperationActions.TeamPenality,
                            data
                        });
                    }
                }
            }
        };
    }

    // ----------------------------------------------------------------
    // Winner
    // ----------------------------------------------------------------

    protected getWinner(): OperationWinner | undefined {

        // ...

        return undefined;
    }

    protected getWinnerByTeamRank(): OperationWinner | undefined  {

        const endContext = this.timeline[this.timeline.length - 1].context;

        const teams: Array<TeamUnderOperation> = endContext.teams
            .filter((team: TeamUnderOperation) => team.score?.rank === 1);

        // No ranked teams or equality
        if(teams.length !== 1) {
            return;
        }

        const team: TeamUnderOperation = teams[0];

        const operators: Array<OperatorUnderOperation> = endContext.operators
            .filter((operator: OperatorUnderOperation) => operator.teamId === team.id);

        return { operators, team };
    }

    protected getWinnerByProgress(): OperationWinner | undefined  {

        const endContext = this.timeline[this.timeline.length - 1].context;

        const teams: Array<TeamUnderOperation> = endContext.teams
            .filter((team: TeamUnderOperation) => team.data.steps && !team.data.steps.some(step => !step.isDone) );

        // No teams full progress
        if(teams.length !== 1) {
            return;
        }

        const team: TeamUnderOperation = teams[0];

        const operators: Array<OperatorUnderOperation> = endContext.operators
            .filter((operator: OperatorUnderOperation) => operator.teamId === team.id);

        return { operators, team };
    }

    // ----------------------------------------------------------------
    // Trophies
    // ----------------------------------------------------------------

    protected getTrophies(): Array<OperationTrophy> {

        // ...

        return [];
    }

    // ----------------------------------------------------------------
    // Timeline
    // ----------------------------------------------------------------

    protected timelinePush(values: { level: number, action: string, data?: any, customIconPath?: string } ) {

        this.timeline.push({
            time: new Date(),
            ...values,
            context: {
                // Snapshots
                operators:  structuredClone(this.registerService.operators),
                teams:      structuredClone(this.registerService.teams),
                devices:    structuredClone(this.loraProtocolService.devices)
            }
        });
    }

    // ----------------------------------------------------------------
    // Report
    // ----------------------------------------------------------------

    private generateReport(): void {

        this.report = structuredClone({

            version: OPERATION_REPORT_VERSION,

            mode:     this.mode,
            settings: this.settings,
            timeline: this.timeline,

            sessionId: UuidUtils.getDayUniqueId(),

            begin:  this.timeline[0].time,
            end:    this.timeline[this.timeline.length - 1].time,

            operators:  this.getAllOperators(),
            winner:     this.getWinner(),
            trophies:   this.getTrophies()
        });
    }

    private reportSave(): void {

        if (!this.report) {
            return;
        }

        this.reportsService.add(this.report);
    }

    // ----------------------------------------------------------------
    // Helpers
    // ----------------------------------------------------------------

    private getAllOperators(): Array<OperatorUnderOperation> {

        let operators: Array<OperatorUnderOperation> = [];

        // Get all operator with team detected during the
        // operation starting by the end to collect the more recent data
        for (const timelineItem of this.timeline.slice().reverse()) {
            operators = operators.concat(
                timelineItem.context.operators.filter((operator: OperatorUnderOperation) =>
                    !operators.find(o => o.number === operator.number) && operator.teamId
                ));
        }

        return operators;
    }

}

