<app-header *ngIf="description" [logo]="logo" [description]="description"></app-header>

<wui-input  [autofocus]="true"
            [type]="type"
            [min]="min"
            [max]="max"
            [(value)]="value"
            [placeholder]="placeholder"></wui-input>

<app-footer>
    <wui-buttons>
        <wui-button (click)="confirmClick()">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
