<app-body>

    <div class="channel">{{pmrService.rxChannel}}</div>

    <div class="channels">
        CH
        <div *ngFor="let channel of channels">{{ channel }}</div>
    </div>
    <div class="channels">
        SUB
        <div *ngFor="let subChannel of subChannels">{{ subChannel }}</div>
    </div>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button
            (wuiPointerStart)="startTransmission()"
            (wuiPointerEnd)="endTransmission()"
            [ngClass]="pmrService.isTransmitting ? 'warning' : ''">PTT</wui-button>
    </wui-buttons>
</app-footer>
