import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductsWithSoftware, OperationProcessDurations } from '@lightning/lightning-definitions';

import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { LoraProtocolService } from '../../../../../shared/services/lora-protocol/lora-protocol.service';
import { RegisterService } from '../../../../../shared/services/register/register.service';
import { ConvoyService } from '../../services/convoy.service';


@Component({
    selector: 'app-convoy-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],

})
export class SetupComponent implements OnInit, OnDestroy {

    public OperationProcessDurations = OperationProcessDurations;

    public Products = ProductsWithSoftware;

    constructor(public convoyService: ConvoyService,
                public loraProtocolService: LoraProtocolService,
                public registerService: RegisterService,
                private settingsService: SettingsService) { }

    ngOnInit() {

        this.convoyService.settingsLoad();

        this.convoyService.settings.name =
            `${ new Date().toLocaleTimeString() } - ${ this.convoyService.mode }`;

        this.convoyService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;
    }

    ngOnDestroy() {

        this.convoyService.settingsApply();
    }
}
