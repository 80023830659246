import { Component } from '@angular/core';
import { AssemblyService } from '../../services/assembly.service';

@Component({
    selector: 'app-assembly-preview',
    templateUrl: './preview.component.html',
    styleUrls: ['./preview.component.scss'],
})
export class PreviewComponent {

    constructor(public assemblyService: AssemblyService) {}
}
