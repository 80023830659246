<app-container>

    <app-header [logo]="'assets/apps/settings/logo.svg'"
                [description]="'apps.settings.headerDescription' | translate | capitalize"></app-header>

    <wui-tabs developpersOnly [tabs]="tabs"></wui-tabs>

    <ng-container *ngIf="tabs.selected">
        <ng-container *ngComponentOutlet="tabs.selected.component"></ng-container>
    </ng-container>

</app-container>
