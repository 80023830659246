import { Component } from '@angular/core';
import { RegisterService } from '../../../shared/services/register/register.service';
import { OperatorsNames } from '../../enums/simulation.enums';

@Component({
    selector: 'app-simulation-operators',
    templateUrl: './operators.component.html',
    styleUrls: ['./operators.component.scss']
})
export class OperatorsComponent {

    public count = 6;
    public maxCount = OperatorsNames.length;

    constructor(
        private registerService: RegisterService) { }


    public run(): void {

        this.registerService.clearOperators();

        if (this.count > OperatorsNames.length) {
            this.count = OperatorsNames.length;
        }

        for (let index = 0; index < this.count; index++) {
            this.registerService.getOrCreateOperator(this.registerService.operators.length + 1, OperatorsNames[index]);
        }
    }
}
