import { Injectable, EventEmitter, OnDestroy } from '@angular/core';

import { LoraDevice as LoraDevice, ProductsWithSoftware, LoraPacket as LoraPacket, LoraReception } from '@lightning/lightning-definitions';

import { Historizer } from '../../classes/historizer';
import { GatewayCommandType, GatewayService } from '../gateway/gateway.service';

const STORAGE_DEVICES_NAMES = 'lora-devices-names';
const INACTIVITY_CHECKING =         1000;       // 1 sec
const INACTIVITY_WARNING =          60000;      // 1 min
const INACTIVITY_KILL =             1200000;    // 20 min
const INACTIVITY_RESET_VIRTUAL =    30000;      // 30 sec

@Injectable({
    providedIn: 'root'
})

export class LoraService implements OnDestroy {

    /**
     * Fired when a packet is received from an USABLE device
     */
    public onReceivePacket = new EventEmitter<LoraReception>();

    /**
     * Fired when a device has been added
     */
    public onDeviceAdd = new EventEmitter<LoraDevice>();

    /**
     * Fired when a device has been removed
     */
    public onDeviceRemove = new EventEmitter<LoraDevice>();

    /**
     * Fired when a device is usable.
     */
    public onDeviceUsable = new EventEmitter<LoraDevice>();

    /**
     * Fired when a device is removed or unusable.
     */
    public onDeviceUnusable = new EventEmitter<LoraDevice>();

    /**
     * Fired when a device is enabled.
     */
    public onDeviceEnabled = new EventEmitter<LoraDevice>();

    /**
     * Fired when a device is disabled.
     */
    public onDeviceDisabled = new EventEmitter<LoraDevice>();


    /**
     * Fired when all the devices are been removed.
     */
    public devicesCleared = new EventEmitter();

    private _historizer = new Historizer('lora-history');
    private _devices = new Array<LoraDevice>();
    private _checkDevicesTimer: any;

    constructor(protected gatewayService: GatewayService) {

        // Lora data from the ground service
        this.gatewayService.on('lora', (packet: LoraPacket) => {

            // Ignore real lora data when a replay is running
            if (this.historizer.isReplaying) {
                return;
            }

            // Deserialize the payload when it comes from the ground
            packet.payload = this.deserializePayload(packet.payload);

            this.onReceiveLora(packet);
        });

        this.gatewayService.on('commandAck', this.onReceiveCommandAck);

        // Lora data from the historizer (replay)
        this.historizer.replayOut.subscribe({ next: data => this.simulateReceive(data as LoraPacket) });

        // Devices check (inactivity...)
        this._checkDevicesTimer = setInterval(() => { this.checkDevicesActivity(); }, INACTIVITY_CHECKING);

    }

    ngOnDestroy() {

        this.historizer.dispose();

        this.gatewayService.removeListener('lora');

        clearInterval(this._checkDevicesTimer);
    }

    public get historizer(): Historizer {

        return this._historizer;
    }

    /**
     * Return true if connected to the ground gateway
     */
    public get isConnected(): boolean {

        return this.gatewayService.isConnected;
    }

    /**
     * Return true if some lora devices are warning
     */
    public get isWarning(): boolean {

        // Device inactivity warning
        if (this._devices.some(device => device.inactivity && device.inactivity >= INACTIVITY_WARNING)) {
            return true;
        }

        // Device with a low battery level
        if (this._devices.some(device => device.state?.battery === 0)) {
            return true;
        }

        // Add other warning reasons here...

        return false;
    }

    /**
     * Return true if is replaying data
     */
    public get isReplaying(): boolean {

        return this.historizer.isReplaying;
    }


    /**
     * Get all devices
     */
    public get devices(): Array<LoraDevice> {
        return this._devices;
    }

    /**
     * Start the connection to the ground service
     */
    public start(): void {

        this.gatewayService.connect();
    }

    /**
     *
     * @param id Id of the device to find
     * @returns The lora device found
     */
    public getDeviceById(id: string): LoraDevice | undefined {

        return this.devices.find(device => device.id === id);
    }

    /**
     * Get named devices of an asked type
     * @param product Product to get
     * @param includeUnusable To include unusable devices, false by default
     * @param includeDisabled To include disabled devices, false by default
     */
    public getDevicesByProduct(product: ProductsWithSoftware, includeUnusable = false, includeDisabled = false) {

        return this._devices
            .filter(device => device.state?.product === product)
            .filter(device => device.isUsable || includeUnusable)
            .filter(device => device.isEnabled || includeDisabled)
            .sort((a, b) => (a.name || '').localeCompare(b.name || ''));
    }


    /**
     * Create device
     * @param id Id of the device to create
     * @param type Type of the device to create
     */
    public addDevice(id: string, product?: ProductsWithSoftware, isVirtual = false): LoraDevice {

        const device: LoraDevice = {
            id,
            name: this.getStoredDeviceName(id),
            isEnabled: true,
            isUsable: false,
            isCompatible: false,
            isUsingUnpublishedVersion: false,
            isVirtual,
            rssi: 0,
            updated: new Date(),
            state: {
                product
            },
            data: {}
        }

        this._devices.push(device);

        // Notify the device was added
        this.onDeviceAdd.emit(device);

        // Check if the device is usable or not
        this.checkDeviceUsability(device);

        return device;
    }


    /**
     * Remove a device
     * @param device Device to remove
     */
     public removeDevice(device: LoraDevice): void {

        // Notify the device will be removed
        this.onDeviceRemove.emit(device);

        // Notify the device will be unusable
        this.onDeviceUnusable.emit(device);

        // Remove the device
        this._devices.splice(this._devices.indexOf(device), 1);
    }


    /**
     * Enable a device
     * @param device Device to disable
     */
    public enableDevice(device: LoraDevice): void {

        if (device.isEnabled || !device.isUsable) {
            return;
        }

        device.isEnabled = true;

        // Check if the device is usable or not
        this.checkDeviceUsability(device);

        // Notify the device is enabled
        this.onDeviceEnabled.emit(device);
    }

    /**
     * Disable a device
     * @param device Device to disable
     */
    public disableDevice(device: LoraDevice): void {

        if (device.isEnabled === false) {
            return;
        }

        device.isEnabled = false;

        // Check if the device is usable or not
        this.checkDeviceUsability(device);

        // Notify the device is disabled
        this.onDeviceDisabled.emit(device);
    }


    /**
     * Save the 'name' property of a device
     * @param device Device to save the name
     */
    public saveDeviceName(device: LoraDevice): void {

        const stored = localStorage.getItem(STORAGE_DEVICES_NAMES) || '{}';

        const data = JSON.parse(stored);

        // Remove name duplications from dynamic devices
        for (const d of this._devices) {
            if(d !== device && d.name && d.name.toLowerCase() === device.name.toLowerCase()) {

                // Remove the name
                d.name = '';

                // Check if the device is usable or not
                this.checkDeviceUsability(d);
            }
        }

        // Remove name duplications from stored device names
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                if(data[key].toLowerCase() === device.name.toLowerCase()) {
                    delete data[key];
                }
            }
        }

        data[device.id] = device.name;

        localStorage.setItem(STORAGE_DEVICES_NAMES, JSON.stringify(data));

        // Check if the device is usable or not
        this.checkDeviceUsability(device);
    }

    /**
     * Clear all stored lora devices
     */
    public clearDevices(): void {

        // Doesn't work :s
        // for (const device of this._devices) {
        //     this.removeDevice(device);
        // }

        this._devices.splice(0);

        this.devicesCleared.emit();
    }


    // /**
    //  * Clear all lora devices roles
    //  */
    // public clearRoles(): void {
    //     this._devices.map(device => device.role = undefined);
    // }

    /**
     * Clear all lora devices aditional data
     */
     public clearData(): void {
        this._devices.map(device => device.data = {});
    }


    /**
     * Send a packet
     * @param payload Payload to send
     * @param destinationId Destination id (optional)
     * @returns The token of the transaction
     */
    public send(payload: any, destinationId = '0'): number {

        return this.gatewayService.send(GatewayCommandType.SendLoRa, destinationId + ' ' + this.serializePayload(payload));
    }

    /**
     * Simulate the reception of a packet, usefull for replaying and simulation
     * @param packet Fake packet
     */
    public simulateReceive(packet: LoraPacket): void {
        this.onReceiveLora(packet);
    }


    // ----------------------------------------------------------------------------------------------
    // Protected methods

    /**
     * Override this method if you are using a non JSON procotol to convert it
     * @param payload possibly not an object
     * @returns an object
     */
    protected deserializePayload(payload: object | string): object {

        if (typeof payload !== 'object') {
            return {};
        }

        return payload;
    }

    /**
     * Override this method if you are using a non JSON procotol to convert it
     * @param payload object to send
     * @returns encoded payload as string
     */
    protected serializePayload(payload: any): string {

        return JSON.stringify(payload);
    }


    // ----------------------------------------------------------------------------------------------
    // Protected methods

    /**
     *
     * @param device The device to check and notify the usability
     */
    protected checkDeviceUsability(device: LoraDevice): void {

        const isUsableBefore = device.isUsable;

        // To be usable, a device must be compatible and named
        device.isUsable =
            (device.isCompatible || device.isVirtual) && device.name?.length > 0;

        // No change
        if(device.isUsable === isUsableBefore) {
            return;
        }

        if (device.isUsable) {

            this.onDeviceUsable.emit(device);

        } else {

            // Disable
            device.isEnabled = false;

            this.onDeviceUnusable.emit(device);

            // Clear role because is not usable,
            // This is made after the event to let operations process to know it must be replaced or not (drops)
            // device.role = undefined;

            device.data = {};
        }
    }


    // ----------------------------------------------------------------------------------------------
    // Private methods

    /**
     * Receive Lora data from the gateway
     * @param packet Received packet
     */
    private onReceiveLora(packet: LoraPacket): void {

        // If it is not already binded to another gateway
        if (packet.payload.state) {

            if (packet.payload.state.bindedGatewayId && packet.payload.state.bindedGatewayId !== this.gatewayService.state?.id) {

                console.warn(`LoRaService: The device ${packet.senderId} is binded with another gateway.`);

                return;
            }
        }

        // Check for existing device
        let device = this._devices.find(i => i.id === packet.senderId);

        // It's a new device
        if (!device) {

            // Create devices only from a state payload
            if (!packet.payload.state) {

                return;
            }

            // Create the new device
            device = this.addDevice(packet.senderId);
        }

        // It's a ground module sending the name of its location
        // TODO Create a new property 'location' for devices ?
        if (device.state?.product === ProductsWithSoftware.GroundModule && packet.payload.location?.name) {

            device.name = packet.payload.location.name;

            this.saveDeviceName(device);
        }

        // Update the device with the new rssi, date of updade and payload
        device.updated = new Date();
        device.rssi = packet.rssi;
        device = Object.assign(device, packet.payload) as LoraDevice;

        // Trace log
        if(packet.payload.log) {
            console.log(`LoRaService: Log from device ${device.id} : ${packet.payload.log}`);
        }

        // Recording
        this.historizer.write({ time: new Date(), data: packet });

        // Fire on receive event
        this.onReceivePacket.emit({ device, packet });
    }

    private onReceiveCommandAck(data: unknown): void {

        console.log(`LoRaService: On Receive Command ACK`, data);
    }


    /**
     * Get the 'name' previously saved
     * @param id Id of the device
     * @returns Name or undefined
     */
    private getStoredDeviceName(id: string): string {

        const stored = localStorage.getItem(STORAGE_DEVICES_NAMES);

        if (!stored) {
            return '';
        }

        const data = JSON.parse(stored);

        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                if (key === id) {
                    return data[key];
                }
            }
        }

        return '';
    }

    /**
     * Check devices inactivity
     */
    private checkDevicesActivity(): void {

        for (const device of this._devices) {

            device.inactivity = new Date().getTime() - new Date(device.updated).getTime();

            // Simulate activity of virtual devices
            if(device.isVirtual && device.inactivity >= INACTIVITY_RESET_VIRTUAL) {

                device.updated = new Date();

                continue;
            }

            // Inactivity timeout elapsed
            if (device.inactivity >= INACTIVITY_KILL) {

                this.removeDevice(device);
            }
        }
    }

}
