import { Component, Input } from '@angular/core';

import { MinimalCompatibleVersions, ProductsWithSoftware } from '@lightning/lightning-definitions';
import { App, EnvironmentService, Window } from '@lightning/wild-environment';

import { GatewayCommandType, GatewayService } from '../../../shared/services/gateway/gateway.service';
import { WebsiteService } from '../../../shared/services/website/website.service';

@Component({
  selector: 'app-gateway',
  templateUrl: './gateway.component.html',
  styleUrls: ['./gateway.component.scss']
})

export class GatewayComponent implements App {

    @Input()
    public window: Window | undefined;

    public MinimalCompatibleVersions = MinimalCompatibleVersions;

    public ProductsWithSoftware = ProductsWithSoftware;

    public GatewayCommandType = GatewayCommandType;


    constructor(
        public gatewayService: GatewayService,
        public websiteService: WebsiteService,
        private environmentService: EnvironmentService) { }

    public openUpdates(): void {

        this.environmentService.windowOpenByAppId('updates');
    }
}
