import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { ProductsWithSoftware, MinimalCompatibleVersions, LoraDevice, GroundLocations } from '@lightning/lightning-definitions';

import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { ModalYesNoComponent } from '../../../shared/components/modal-yes-no/modal-yes-no.component';
import { WebsiteService } from '../../../shared/services/website/website.service';

@Component({
    selector: 'app-items',
    templateUrl: './devices.component.html',
    styleUrls: ['./devices.component.scss']
})
export class DevicesComponent {

    public Products = ProductsWithSoftware;

    public GroundLocations = GroundLocations;

    constructor(
        public loraProtocolService: LoraProtocolService,
        public websiteService: WebsiteService,
        private translateService: TranslateService,
        private environmentService: EnvironmentService) { }

    public getMinimalCompatibleVersion(product: ProductsWithSoftware): string {

        return MinimalCompatibleVersions[product];
    }

    public async toggleEnableDisable(device: LoraDevice): Promise<void> {

        if (device.isEnabled) {

            const result = await this.environmentService.modalOpen({
                title: 'action',
                component: ModalYesNoComponent,
                inputs: {
                    logo: 'assets/apps/lora/logo.svg',
                    description: this.translateService.instant('apps.lora.devices.disablingConfirmation')
                }
            });

            if (result) {
                this.loraProtocolService.disableDevice(device);
            }

        } else {
            this.loraProtocolService.enableDevice(device);
        }
    }

    public getFormattedBatteryLevel(device: LoraDevice): string {

        if (device.state?.battery === undefined) {
            return this.translateService.instant('shared.unknown');
        }

        if (device.state.battery === 0) {
            return this.translateService.instant('shared.low');
        }

        return `${device.state.battery}%`;
    }

    public isCompatibleSofware(device: LoraDevice): boolean {

        if (device.state?.software === undefined) {
            return true;
        }

        return device.isCompatible;
    }

    public openUpdates(): void {

        this.environmentService.windowOpenByAppId('updates');
    }
}
