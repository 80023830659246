import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Tab, Tabs } from '@lightning/wild-ui';

import { OperationSteps } from '../../../shared/enums/operation.enums';
import { LootingService } from '../../services/looting.service';

import { PreviewComponent } from '../preview/preview.component';
import { SetupComponent } from '../setup/setup.component';
import { BriefingComponent } from '../briefing/briefing.component';
import { DeployingComponent } from '../deploying/deploying.component';
import { ProcessComponent } from '../process/process.component';
import { DebriefingComponent } from '../debriefing/debriefing.component';


@Component({
    selector: 'app-looting',
    templateUrl: './looting.component.html',
    styleUrls: ['./looting.component.scss']
})

export class LootingComponent implements OnInit {

    public tabs: Tabs = {
        items: [
            {
                selector: OperationSteps.Previewing,
                text: this.translateService.instant('apps.operations.steps.preview.title'),
                component: PreviewComponent,
            },
            {
                selector: OperationSteps.Setup,
                text: this.translateService.instant('apps.operations.steps.setup.title'),
                component: SetupComponent,
            },
            {
                selector: OperationSteps.Briefing,
                text: this.translateService.instant('apps.operations.steps.briefing.title'),
                component: BriefingComponent,
            },
            {
                selector: OperationSteps.Deploying,
                text: this.translateService.instant('apps.operations.steps.deploying.title'),
                component: DeployingComponent,
            },
            {
                selector: OperationSteps.Operating,
                text: this.translateService.instant('apps.operations.steps.operation.title'),
                component: ProcessComponent,
            },
            {
                selector: OperationSteps.Debriefing,
                text: this.translateService.instant('apps.operations.steps.debriefing.title'),
                component: DebriefingComponent,
            },
        ],
    };

    constructor(
        public translateService: TranslateService,
        public lootingService: LootingService) { }

    ngOnInit(): void {

        this.select(this.lootingService.step);

        this.lootingService.stepChanged.subscribe(step => this.select(step));
    }

    private select(selector: OperationSteps) {

        this.tabs.selected = this.tabs.items.find((item: Tab) => item.selector === selector);
    }
}
