
import { IsBoolean, IsDate, IsObject, IsOptional, IsString } from '@lightning/decorators';
import { Expose } from 'class-transformer';

export class Feedback {

    public id?: any;

    @Expose()
    @IsString()
    public authorAccountId = '';

    @Expose()
    @IsDate()
    public created = new Date();

    @Expose()
    @IsString()
    public description = '';

    @Expose()
    @IsString()
    @IsOptional()
    public screenshot = '';

    @Expose()
    @IsBoolean()
    public hasScreenshot = false;

    @Expose()
    @IsObject()
    public context?: any;

}
