<app-header *ngIf="description" [logo]="logo" [description]="description"></app-header>

<wui-textarea   [autofocus]="true"
                [(value)]="value"
                [placeholder]="placeholder"></wui-textarea>

<app-footer>
    <wui-buttons>
        <wui-button (click)="confirmClick()">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
