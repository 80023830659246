import { ProductsWithSoftware } from '../enums/products.enum';

export class LoraDeviceState {

    // Time since the device started in ms, may be useful to detect a reboot
    public time?: number;

    public product?: ProductsWithSoftware;

    public hardware?: string;

    public software?: string;

    public battery?: number;

    public bindedGatewayId?: string;
}

export class LoraDevice {

    public id = '';

    public name = '';

    public rssi = 0;

    public updated = new Date();

    public isEnabled = true;        // Manual choice from the user

    public isUsable = false;        // The device is compatible and named

    public isCompatible = false;    // The devices uses a compatible firmware

    public isUsingUnpublishedVersion = false;

    public isVirtual = false;

    public data: any;

    public state?: LoraDeviceState;

    // Computed: Time between now and the updated property
    public inactivity?: number;

    // [x: string]: any; // Extendable for various payloads
}
