import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProductsWithSoftware, OperationProcessDurations } from '@lightning/lightning-definitions';

import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { RegisterService } from '../../../../../shared/services/register/register.service';
import { LoraProtocolService } from '../../../../../shared/services/lora-protocol/lora-protocol.service';
import { PrisonersService } from '../../services/prisoners.service';

@Component({
    selector: 'app-prisonners-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit, OnDestroy {

    public OperationProcessDurations = OperationProcessDurations;

    public groundLocations = new Array<string>();

    constructor(
        public prisonersService: PrisonersService,
        public registerService: RegisterService,
        private LoraProtocolService: LoraProtocolService,
        private settingsService: SettingsService) { }

    ngOnInit() {

        this.prisonersService.settingsLoad();

        this.prisonersService.settings.name =
            `${ new Date().toLocaleTimeString() } - ${ this.prisonersService.mode }`;

        this.prisonersService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;

        this.groundLocations = this.LoraProtocolService
            .getDevicesByProduct(ProductsWithSoftware.GroundModule)
            .map(device => device.name || '');
    }

    ngOnDestroy() {

        this.prisonersService.settingsApply();
    }
}
