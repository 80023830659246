import { Component, OnInit } from '@angular/core';

import { EnvironmentService } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';

import { LoraProtocolService } from 'apps/headquarter/src/app/apps/shared/services/lora-protocol/lora-protocol.service';
import { RegisterService } from 'apps/headquarter/src/app/apps/shared/services/register/register.service';
import { OperationStates } from '../../../shared/enums/operation.enums';

import { DropsService } from '../../services/drops.service';


@Component({
    selector: 'app-drops-process',
    templateUrl: './process.component.html',
    styleUrls: ['./process.component.scss']
})

export class ProcessComponent implements OnInit {

    OperationStates = OperationStates;

    constructor(public environmentService: EnvironmentService,
                public onlineService: OnlineService,
                public loraProtocolService: LoraProtocolService,
                public registerService: RegisterService,
                public dropsService: DropsService) { }

    ngOnInit() {
        // this.dropsService.start();
    }

}
