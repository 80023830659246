import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WildUiModule } from '@lightning/wild-ui';
import { LightningUiModule } from '@lightning/lightning-ui';

import { SharedModule } from '../shared/shared.module';

import { LoraComponent } from './components/lora/lora.component';
import { DevicesComponent } from './components/devices/devices.component';
import { SimulationComponent } from './components/simulation/simulation.component';
import { LogsComponent } from './components/logs/logs.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CommandsComponent } from './components/commands/commands.component';

@NgModule({
    declarations: [
        LoraComponent,
        DevicesComponent,
        SimulationComponent,
        LogsComponent,
        SettingsComponent,
        CommandsComponent
    ],
    imports: [
        CommonModule,
        WildUiModule,
        LightningUiModule,
        SharedModule,
    ],
    exports: [
        LoraComponent
    ]
})

export class LoraModule { }
