import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TrayComponent } from './components/tray/tray.component';

import { EnvironmentService } from '@lightning/wild-environment';
import { SharedModule } from '../apps/shared/shared.module';

@NgModule({
    declarations: [
        TrayComponent
    ],
    imports: [
        CommonModule,
        SharedModule
    ]
})

export class TrayModule {

    constructor(environmentService: EnvironmentService) {

        // Registration
        environmentService.trayRegister({
            component: TrayComponent
        });
    }
}
