import { Component, Input } from '@angular/core';

import { TeamUnderOperation } from '@lightning/lightning-definitions';

@Component({
  selector: 'lui-operation-teams-roles',
  templateUrl: './operation-teams-roles.component.html',
  styleUrls: ['./operation-teams-roles.component.scss']
})
export class OperationTeamsRolesComponent {

  @Input()
  public teams: Array<TeamUnderOperation> = [];

  @Input()
  public pointsCurrency = ' ';

  @Input()
  public pointsLimit = 0;

}
