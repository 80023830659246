import { Component } from '@angular/core';
import { OnlineService } from '@lightning/lightning-services';

import { ContaminationService } from '../../services/contamination.service';

@Component({
    selector: 'app-contamination-debriefing',
    templateUrl: './debriefing.component.html',
    styleUrls: ['./debriefing.component.scss'],
})
export class DebriefingComponent {
    constructor(
        public onlineService: OnlineService,
        public operationService: ContaminationService
    ) {}
}
