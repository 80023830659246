import { Component, Input, HostListener } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modal-yes-no',
    templateUrl: './modal-yes-no.component.html',
    styleUrls: ['./modal-yes-no.component.scss']
})

export class ModalYesNoComponent {

    @Input()
    public logo = '';

    @Input()
    public description = '';

    @Input()
    // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
    public resolve = (result = false) => { };

    constructor(
        public translateService: TranslateService,
        private environmentService: EnvironmentService) { }


    public noClick(): void {

        this.resolve(false);

        this.environmentService.modalClose();
    }

    @HostListener('document:keyup.enter', ['$event'])
    public yesClick(): void {

        this.resolve(true);

        this.environmentService.modalClose();
    }

}
