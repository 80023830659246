<app-body>
    simulation
</app-body>

<app-footer>
    <wui-buttons>
        <!-- <wui-button (click)="loraProtocolService.createVirtualItems(3, 'flag')">create items</wui-button>
        <wui-button (click)="loraProtocolService.clearVirtualItems()">clear items</wui-button> -->
    </wui-buttons>
</app-footer>
