import { Component, Input } from '@angular/core';

import { App, EnvironmentService, ScreenService, Window } from '@lightning/wild-environment';
import { OnlineService } from '@lightning/lightning-services';
import { FeedbackCreateRequest } from '@lightning/lightning-definitions';
import { environment } from '@lightning/headquarter/environments/environment';

import { FeedbacksService } from '../../services/feedbacks/feedbacks.service';
import { ModalOnlineLoginComponent } from '../../../shared/components/modal-online-login/modal-online-login.component';

@Component({
    selector: 'app-feedbacks',
    templateUrl: './feedbacks.component.html',
    styleUrls: ['./feedbacks.component.scss']
})
export class FeedbacksComponent implements App {

    @Input()
    public window: Window | undefined;

    public request = new FeedbackCreateRequest();

    constructor(
        public onlineService: OnlineService,
        public environmentService: EnvironmentService,
        private screenService: ScreenService,
        private feedbacksService: FeedbacksService) { }

    public async changeHasScreenshot(value: boolean): Promise<void> {

        if (value) {

            if (!this.request.screenshot) {
                this.request.screenshot = await this.screenService.capture(environment.feedback.screenshotScale);
            }

        } else {
            this.request.screenshot = '';
        }
    }

    public get isInvalid(): boolean {

        return !this.request.description;
    }

    public send(): void {

        this.feedbacksService.send(this.request);

        this.request = new FeedbackCreateRequest();
    }

    public loginOnline(): void {

        this.environmentService.modalOpen({ title: '', component: ModalOnlineLoginComponent });
    }

}
