<app-body>

    <div class="line">
        <div class="header replay"        title="Replay" *ngIf="loraProtocolService.historizer.isReplaying">rp.</div>
        <div class="header line"          title="Line number">ln</div>
        <div class="header time"          title="Time">time</div>
        <div class="header senderId"      title="Source (sender id)">source</div>
        <div class="header packetNumber"  title="Packet number">pkn</div>
        <div class="header destinationId" title="Destination id">destination</div>
        <div class="header payloadNumber" title="Payload number">pln</div>
        <div class="header payload"       title="Payload">payload</div>
        <div class="header rssi"          title="Received signal strength indication">rssi</div>
    </div>

    <div class="line"
        *ngFor="let line of loraProtocolService.historizer.entries; let index = index;"
        (click)="loraProtocolService.historizer.replaySingleEntry(index)"
        (dblclick)="loraProtocolService.historizer.startReplay(index)"
        [wuiScroll]="index === (loraProtocolService.historizer.isReplaying ? loraProtocolService.historizer.replayIndex : loraProtocolService.historizer.entries.length - 1)"
        [wuiScrollOptions]="{ behavior: 'smooth', block: 'center', inline: 'center' }">
        <div class="value replay" *ngIf="loraProtocolService.historizer.isReplaying">{{index === loraProtocolService.historizer.replayIndex ? '⯈' : ''}}</div>
        <div class="value line">{{index}}</div>
        <div class="value time">{{line.time.toLocaleTimeString()}}</div>
        <div class="value senderId">{{line.data.senderId}}</div>
        <div class="value packetNumber">{{line.data.packetNumber}}</div>
        <div class="value destinationId">{{line.data.destinationId === '0' ? 'BROADCAST' : line.data.destinationId}}</div>
        <!-- <div class="value rebounds">{{line.data.rebounds}}</div> -->
        <div class="value payloadNumber">{{line.data.payloadNumber}}</div>
        <div class="value payload" [title]="line.data.payload | json">{{line.data.payload | json}}</div>
        <div class="value rssi">{{line.data.rssi}}</div>
    </div>

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="loraProtocolService.historizer.clear()"        [wuiDisabled]="loraProtocolService.historizer.isEmpty">clear</wui-button>
        <wui-button (click)="loraProtocolService.historizer.saveToFile()"   [wuiDisabled]="loraProtocolService.historizer.isEmpty">save</wui-button>
        <wui-button (click)="loraProtocolService.historizer.loadFromFile()">load</wui-button>
        <wui-button (click)="loraProtocolService.historizer.toggleReplay()" [wuiDisabled]="loraProtocolService.historizer.isEmpty">{{loraProtocolService.historizer.isReplaying ? 'stop' : 'start'}} replay</wui-button>
    </wui-buttons>
</app-footer>
