<app-body>
    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="contaminationService.settings.timeLimit > 0">
            {{ contaminationService.timer.time | milliseconds }}
        </ng-container>
        <ng-container *ngIf="contaminationService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <!-- <lui-operation-teams-ranking wuiTacticalCorners
        [teams]="registerService.teams"
        [pointsCurrency]="contaminationService.settings.pointsCurrency"
        [pointsLimit]="contaminationService.settings.pointsLimit"></lui-operation-teams-ranking> -->

    <!-- <lui-operation-areas-captures wuiTacticalCorners [items]="LoraProtocolService.items"
        [teams]="registerService.teams"></lui-operation-areas-captures> -->

    <!-- <lui-operation-areas-roles wuiTacticalCorners
        [items]="loraProtocolService.devices"></lui-operation-areas-roles> -->

    <lui-timeline
        *ngIf="contaminationService.timeline.length"
        wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="contaminationService.timeline"
        [scrolling]="false"
    >
    </lui-timeline>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button
            *ngIf="contaminationService.state === OperationStates.Iddle"
            (click)="contaminationService.start()"
            >{{ 'shared.start' | translate }}</wui-button
        >
        <wui-button
            *ngIf="contaminationService.state === OperationStates.Processing"
            (click)="contaminationService.pause()"
            >{{ 'shared.pause' | translate }}</wui-button
        >
        <wui-button
            *ngIf="contaminationService.state === OperationStates.Paused"
            (click)="contaminationService.resume()"
            >{{ 'shared.resume' | translate }}</wui-button
        >
        <wui-button
            *ngIf="contaminationService.state === OperationStates.Processing"
            (click)="contaminationService.complete()"
            >{{ 'shared.complete' | translate }}</wui-button
        >
    </wui-buttons>
</app-footer>
