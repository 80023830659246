/// <reference types="@types/web-bluetooth" />

import { EventEmitter } from '@angular/core';
import { environment } from '../../../../../../environments/environment';
import { GatewayConnectorsNames } from '../../../enums/gateway.enum';
import { GatewayConnector } from './connector.interface';

export class WebBluetoothGatewayConnector implements GatewayConnector {

    public onReceive = new EventEmitter<string>;
    public onConnect = new EventEmitter<void>;
    public onConnectFail = new EventEmitter<void>;
    public onDisconnect = new EventEmitter<void>;

    private _isConnected = false;

    private _server: BluetoothRemoteGATTServer | undefined;

    private _characteristicOutput: BluetoothRemoteGATTCharacteristic | undefined;

    public get name(): GatewayConnectorsNames {
        return GatewayConnectorsNames.Bluetooth;
    }

    public async connect(): Promise<void> {

        if (!('bluetooth' in navigator)) {

            console.error('Web Bluetooth not supported');

            this.onConnectFailHandler();

            return;
        }

        this.disconnect();

        const options = {
            filters: [{
                services: [environment.gateway.bluetooth.serviceUuid]
            }]
        };

        try {

            const device = await navigator.bluetooth.requestDevice(options);

            this._server = await device.gatt?.connect();

            if (!this._server) {

                this.onConnectFailHandler();

                return;
            }

            const service = await this._server.getPrimaryService(environment.gateway.bluetooth.serviceUuid);

            const characteristicInput = await service.getCharacteristic(environment.gateway.bluetooth.characteristicInputUuid);

            device.addEventListener('gattserverdisconnected', () => {

                if (this._server?.connected) {
                    this.onDisconnectHandler();
                }

            });

            characteristicInput.addEventListener('characteristicvaluechanged', async (event: any) => {

                this.onReceiveHandler(event);
            });

            await characteristicInput.startNotifications();

            // Read the previous value confirms immediately the connection is done
            await characteristicInput.readValue();

            this._characteristicOutput = await service.getCharacteristic(environment.gateway.bluetooth.characteristicOutputUuid);

            this.onConnectHandler();

        } catch (error) {

            console.log(error);

            this.onConnectFailHandler();
        }
    }

    public async disconnect(): Promise<void> {

        if (!this._server) {

            return;
        }

        this._server.disconnect();
    }

    public async send(data: string): Promise<void> {

        if (!this._isConnected || !this._characteristicOutput) {

            return;
        }

        const encoder = new TextEncoder();

        try {

            await this._characteristicOutput.writeValue(encoder.encode(data));

        } catch (error: unknown) {

            console.warn(`gatewayService: bluetoothSend: ${error}`);

            setTimeout(() => { this.send(data); }, 100);
        }
    }

    public get isConnected(): boolean {

        return this._isConnected;
    }

    private onConnectHandler(): void {

        this._isConnected = true;

        this.onConnect.emit();
    }

    private onConnectFailHandler(): void {

        this._isConnected = false;

        this.onConnectFail.emit();
    }

    private onReceiveHandler(event: any): void {

        const decoder = new TextDecoder('utf-8');

        const data = decoder.decode(event.target.value);

        this.onReceive.emit(data);
    }

    private onDisconnectHandler(): void {

        this._isConnected = false;

        this.onDisconnect.emit();

    }

}
