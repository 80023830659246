<app-body>

    <ng-container *ngFor="let property of themeService.theme.properties">
        <wui-entry>
            <div name>{{ property.name }}</div>
            <wui-input value [type]="property.type" [(value)]="property.value" (valueChange)="themeService.apply()"></wui-input>
        </wui-entry>
    </ng-container>

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button value (click)="themeService.reset()">reset</wui-button>
        <wui-button value (click)="import()">import</wui-button>
        <wui-button value (click)="export()">export</wui-button>
    </wui-buttons>
</app-footer>
