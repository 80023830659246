<app-body>

    <app-simulation-devices></app-simulation-devices>

    <app-simulation-operators></app-simulation-operators>

    <app-simulation-action></app-simulation-action>

</app-body>
<app-footer>

</app-footer>
