import { StoreProduct } from '../entities/store.entity';
import { Products } from '../enums/products.enum';
import { StoreProductsStatus } from '../enums/store.enum';

export const StoreProductOperatorTag: StoreProduct = {
    name: Products.NfcTagOperator,
    details: '',
    url: '/store/products/nfc-tag-operator',
    quantity: {
        value: 1,
        min: 1,
        max: 1
    },
    options: [{
        name: 'operatorName',
        value: '',
        type: 'text',
        pattern: '^[a-zA-Z0-9]{2,}$',
        isRequired: true,
        price: 0
    },
    /*{
        name: 'legend',
        value: false,
        type: 'checkbox',
        isRequired: false,
        price: 10
    }*/],
    price: {
        base: 34.99,
        total: 34.99,
        period: 'shared.perYear'
    },
    status: StoreProductsStatus.Available
};

export const StoreProductLegend: StoreProduct = {
    name: Products.Legend,
    details: '',
    url: '/store/products/legend',
    quantity: {
        value: 1,
        min: 1,
        max: 1
    },
    options: [],
    price: {
        base: 29.99,
        total: 29.99,
        period: ''//shared.lifeTime'
    },
    status: StoreProductsStatus.Available
};

export const StoreProductGuestsTags: StoreProduct = {
    name: Products.NfcTagGuest,
    details: '',
    url: '/store/products/nfc-tag-guest',
    quantity: {
        value: 1,
        min: 1,
        max: 10
    },
    options: [],
    price: {
        base: 39.99,
        total: 39.99,
        period: ''
    },
    status: StoreProductsStatus.New
}

export const StoreProductResourcesTags: StoreProduct = {
    name: Products.NfcTagResource,
    details: '',
    url: '/store/products/nfc-tag-resource',
    quantity: {
        value: 1,
        min: 1,
        max: 10
    },
    options: [],
    price: {
        base: 39.99,
        total: 39.99,
        period: ''
    },
    status: StoreProductsStatus.New
}

export const StoreProductStartKit: StoreProduct = {
    name: Products.StarterKit,
    details: '',
    url: '/store/products/starter-kit',
    quantity: {
        value: 1,
        min: 1,
        max: 10
    },
    options: [{
        name: 'extendedBattery',
        value: false,
        type: 'checkbox',
        isRequired: false,
        price: 40
    }],
    price: {
        base: 649.99,
        total: 0,
        period: ''
    },
    status: StoreProductsStatus.Soon
};

export const StoreProductGroundModule: StoreProduct = {
    name: Products.GroundModule,
    details: '',
    url: '/store/products/ground-module',
    quantity: {
        value: 1,
        min: 1,
        max: 5
    },
    options: [{
        name: 'extendedBattery',
        value: false,
        type: 'checkbox',
        isRequired: false,
        price: 40
    }],
    price: {
        base: 199.99,
        total: 0,
        period: ''
    },
    status: StoreProductsStatus.Soon
};

export const StoreProductDummy: StoreProduct = {
    name: Products.Dummy,
    details: '',
    url: '/store/products/dummy',
    quantity: {
        value: 1,
        min: 1,
        max: 1
    },
    options: [],
    price: {
        base: 0,
        total: 0,
        period: ''
    },
    status: StoreProductsStatus.New
};

export const StoreProductDummyOutOfStock: StoreProduct = {
    name: Products.Dummy,
    details: '',
    url: '/store/products/dummy',
    quantity: {
        value: 1,
        min: 1,
        max: 1
    },
    options: [],
    price: {
        base: 0,
        total: 0,
        period: ''
    },
    status: StoreProductsStatus.OutOfStock
};

export const StoreProducts = [
    StoreProductStartKit,
    StoreProductOperatorTag,
    StoreProductGuestsTags,
    StoreProductResourcesTags,
    StoreProductGroundModule,
    StoreProductLegend,
    StoreProductDummy,
    StoreProductDummyOutOfStock
];

// ---------------------------------------------------------------
// TAGS
// ---------------------------------------------------------------

// Parts tags (set of 6)



// ---------------------------------------------------------------
// ACCESSORIES
// ---------------------------------------------------------------

// Transport rack for guest tags x10 (specify numbers)

// Transport rack for ground modules

// Charging station for ground modules

// Minimalist custom handle for ground modules

// Hi gain antenna for ground module and gateway

// Protective cap for ground modules




// ---------------------------------------------------------------
// SPARE
// ---------------------------------------------------------------

// Armband locker (set of 3)

// Armband case (guest)

// Single guest tag (specify number)



// ---------------------------------------------------------------
// GOODIES
// ---------------------------------------------------------------

// Pocket case (optional laser making)

// Organisator t-shirt

// Stickers set

// Flag "Lighting powered operations"

// Picatinny laser marked emblem (specify operator)

// Molle laser marked emblem (specify operator)



