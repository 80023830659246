import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Tab, Tabs } from '@lightning/wild-ui';

import { OperationSteps } from '../../../shared/enums/operation.enums';
import { AssemblyService } from '../../services/assembly.service';

import { PreviewComponent } from '../preview/preview.component';
import { SetupComponent } from '../setup/setup.component';
import { BriefingComponent } from '../briefing/briefing.component';
import { DeployingComponent } from '../deploying/deploying.component';
import { ProcessComponent } from '../process/process.component';
import { DebriefingComponent } from '../debriefing/debriefing.component';

@Component({
    selector: 'app-assembly',
    templateUrl: './assembly.component.html',
    styleUrls: ['./assembly.component.scss'],
})
export class AssemblyComponent implements OnInit {

    public tabs: Tabs = {
        items: [
            {
                selector: OperationSteps.Previewing,
                text: this.translateService.instant('apps.operations.steps.preview.title'),
                component: PreviewComponent,
            },
            {
                selector: OperationSteps.Setup,
                text: this.translateService.instant('apps.operations.steps.setup.title'),
                component: SetupComponent,
            },
            {
                selector: OperationSteps.Briefing,
                text: this.translateService.instant('apps.operations.steps.briefing.title'),
                component: BriefingComponent,
            },
            {
                selector: OperationSteps.Deploying,
                text: this.translateService.instant('apps.operations.steps.deploying.title'),
                component: DeployingComponent,
            },
            {
                selector: OperationSteps.Operating,
                text: this.translateService.instant('apps.operations.steps.operation.title'),
                component: ProcessComponent,
            },
            {
                selector: OperationSteps.Debriefing,
                text: this.translateService.instant('apps.operations.steps.debriefing.title'),
                component: DebriefingComponent,
            },
        ],
    };

    constructor(
        public translateService: TranslateService,
        public assemblyService: AssemblyService) {}

    ngOnInit(): void {
        this.select(this.assemblyService.step);

        this.assemblyService.stepChanged.subscribe((step) => this.select(step));
    }

    private select(selector: OperationSteps) {
        this.tabs.selected = this.tabs.items.find(
            (tab: Tab) => tab.selector === selector
        );
    }
}
