import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { OperationProcessDurations } from '@lightning/lightning-definitions';

import { RegisterService } from '@lightning/headquarter/app/apps/shared/services/register/register.service';
import { SettingsService } from '../../../../../shared/services/settings/settings.service';
import { ContaminationService } from '../../services/contamination.service';

@Component({
    selector: 'app-contamination-setup',
    templateUrl: './setup.component.html',
    styleUrls: ['./setup.component.scss'],
})
export class SetupComponent implements OnInit, OnDestroy {
    public OperationProcessDurations = OperationProcessDurations;

    constructor(
        public contaminationService: ContaminationService,
        public registerService: RegisterService,
        private translateService: TranslateService,
        private settingsService: SettingsService
    ) {}

    public ngOnInit() {
        this.contaminationService.settingsLoad();

        this.contaminationService.settings.name = `${new Date().toLocaleTimeString()} - ${this.translateService.instant(
            'apps.operations.modes.' + this.contaminationService.mode + '.name'
        )}`;

        this.contaminationService.settings.timeLimit =
            this.settingsService.settings.operations.timeLimit;
    }

    public ngOnDestroy() {
        this.contaminationService.settingsApply();
    }

}
