import { OnInit, Component, OnDestroy } from '@angular/core';
import { GroundLocations, ProductsWithSoftware } from '@lightning/lightning-definitions';
import { Window, EnvironmentService, WindowState, ScreenService } from '@lightning/wild-environment';
import { Subscription } from 'rxjs';
import { OperationStates, OperationSteps } from '../../../operations/modes/shared/enums/operation.enums';
import { OperationsService } from '../../../operations/services/operations/operations.service';
import { LoraProtocolService } from '../../../shared/services/lora-protocol/lora-protocol.service';
import { RegisterService } from '../../../shared/services/register/register.service';
import { OperatorsNames } from '../../enums/simulation.enums';

const FAST_MODE = false;
const MINIMAL_STEP_DELAY = FAST_MODE ? 0 : 2000;

enum Steps {
    Welcome,
    Headquarter,
    GlobalTheory,
    GroundModulesDescription,
    GroundModulesNaming,
    GroundModulesOk,
    OperatorsDescription,
    OperatorsTeamsMaking,
    OperationSelection,
    OperationPreview,
    OperationSetup,
    OperationBriefing,
    OperationDeploying,
    OperationDeployed,
    OperationSimulation,
    OperationComplete,
}

enum Mistakes {
    None,
    ModulesRemoved,
    OperatorsRemoved
}


@Component({
    selector: 'app-simulation-tutorial',
    templateUrl: './tutorial.component.html',
    styleUrls: ['./tutorial.component.scss']
})

export class TutorialComponent implements OnInit, OnDestroy {

    public simulationWindow: Window | undefined;
    public remoteDevicesWindow: Window | undefined;
    public registerWindow: Window | undefined;
    public operationsWindow: Window | undefined;

    public stepsCount = Object.keys(Steps).length / 2;
    public step = 0;
    public mistake = Mistakes.None;

    private stepDelayTimer: any;
    private isStepDelayElapsed = true;

    private deviceRemovedSubscription: Subscription | undefined;
    private devicesClearedSubscription: Subscription | undefined;
    private operatorRemovedSubscription: Subscription | undefined;
    private operatorsClearedSubscription: Subscription | undefined;
    private operationSelectedSubscription: Subscription | undefined;
    private operationStepChangedSubscription: Subscription | undefined;
    private operationStateChangedSubscription: Subscription | undefined;

    constructor(
        public registerService: RegisterService,
        public loraProtocolService: LoraProtocolService,
        private environmentService: EnvironmentService,
        private screenService: ScreenService,
        private operationsService: OperationsService) { }

    ngOnInit() {

        this.loraProtocolService.clearDevices();
        this.registerService.clearOperators();

        this.simulationWindow = this.environmentService.appGetWindows('simulation')[0];

        this.beAware();
    }

    ngOnDestroy() {

        this.deviceRemovedSubscription?.unsubscribe();
        this.devicesClearedSubscription?.unsubscribe();
        this.operatorRemovedSubscription?.unsubscribe();
        this.operatorsClearedSubscription?.unsubscribe();
        this.operationSelectedSubscription?.unsubscribe();
        this.operationStepChangedSubscription?.unsubscribe();
        this.operationStateChangedSubscription?.unsubscribe();
    }


    public back(): void {

        if (this.step <= 0) {
            return;
        }

        this.step--;
    }

    public continue(): void {

        // Continue after a mistake
        if(this.mistake !== Mistakes.None) {

            switch(this.mistake) {

                case Mistakes.ModulesRemoved:
                    this.createGroundModules(3, false);
                break;

                case Mistakes.OperatorsRemoved:
                    this.createOperators(6);
                break;
            }

            this.mistake = Mistakes.None;

            return;
        }


        // Discard if all steps are done
        if (this.step >= this.stepsCount - 1) {
            return;
        }

        // Continue to the next step
        this.nextStep();

        if (!this.simulationWindow) {
            return;
        }

        switch (this.step) {

            case Steps.Headquarter:

                if (!this.simulationWindow) {
                    return;
                }

                this.environmentService.closeWindowAll([this.simulationWindow]);

                this.screenService.enterFullscreen();

            break;

            case Steps.GroundModulesNaming:

                this.simulationWindow

                // Move the window of this app to the left
                this.simulationWindow.display = {
                    left: 15,
                    top: 5,
                    width: 25,
                    height: 90,
                    state: WindowState.Normal,
                    priority: this.simulationWindow.display.priority
                };

                // Open a window for the remote device app
                this.remoteDevicesWindow = this.environmentService.windowOpenByAppId('lora');

                if (this.remoteDevicesWindow) {
                    this.remoteDevicesWindow.display = {
                        left: 45,
                        top: 5,
                        width: 40,
                        height: 90,
                        state: WindowState.Normal,
                        priority: this.simulationWindow.display.priority + 1
                    };
                }

                // Create 3 ground modules without auto naming
                this.createGroundModules(3, FAST_MODE ? true : false);

            break;

            case Steps.OperatorsTeamsMaking:

                // Minimize the window of the remote devices app
                if (this.remoteDevicesWindow) {
                    this.remoteDevicesWindow.display.state = WindowState.Minimized;
                }

                // Open a window for the register app
                this.registerWindow = this.environmentService.windowOpenByAppId('register');

                if (this.registerWindow) {
                    this.registerWindow.display = {
                        left: 45,
                        top: 5,
                        width: 40,
                        height: 90,
                        state: WindowState.Normal,
                        priority: this.simulationWindow.display.priority + 1
                    };
                }

                // Create 6 operators without teams
                this.createOperators(6);

            break;

            case Steps.OperationSelection:

                // Open a window for the register app
                this.operationsWindow = this.environmentService.windowOpenByAppId('operations');

                if (this.operationsWindow) {
                    this.operationsWindow.display = {
                        left: 45,
                        top: 5,
                        width: 40,
                        height: 90,
                        state: WindowState.Normal,
                        priority: this.simulationWindow.display.priority + 1
                    };
                }

            break;

            case Steps.OperationSimulation:

                if (this.simulationWindow) {
                    this.simulationWindow.display = {
                        left: 3,
                        top: 5,
                        width: 25,
                        height: 90,
                        state: WindowState.Normal,
                        priority: this.simulationWindow.display.priority
                    };
                }

                if (this.registerWindow) {
                    this.registerWindow.display = {
                        left: 30,
                        top: 5,
                        width: 25,
                        height: 90,
                        state: WindowState.Normal,
                        priority: this.simulationWindow.display.priority + 1
                    };
                }

                if (this.operationsWindow) {
                    this.operationsWindow.display = {
                        left: 57,
                        top: 5,
                        width: 40,
                        height: 90,
                        state: WindowState.Normal,
                        priority: this.simulationWindow.display.priority + 2
                    };
                }

            break;
        }
    }

    public canContinue(): boolean {

        // Delay locker
        if (this.isStepDelayElapsed === false) {
            return false;
        }

        // You can always continue after a mistake
        if(this.mistake > Mistakes.None) {
            return true;
        }

        // Conditional continues step by step
        switch (this.step) {

            case Steps.GroundModulesNaming:

                // Disabled while the 3 ground modules doesn't have a name
                if (this.loraProtocolService.getDevicesByProduct(ProductsWithSoftware.GroundModule, true).filter(module => module.name).length < 3) {

                    return false;
                }
                break;

            case Steps.OperatorsTeamsMaking:

            // Disabled while there is no 2 teams
                if (this.registerService.teams.length !== 2) {
                    return false;
                }

                // Disabled while there is operators out of a team
                if(this.registerService.teams.reduce((acc, team) => acc + team.operatorsCount, 0) < this.registerService.operators.length) {
                    return false;
                }

                break;

            case Steps.OperationSelection:
            case Steps.OperationPreview:
            case Steps.OperationSetup:
            case Steps.OperationBriefing:
            case Steps.OperationDeploying:
            case Steps.OperationDeployed:
            case Steps.OperationSimulation:
                // Disabled because uses an automatic continue
                return false;
                break;
        }

        return true;
    }


    private beAware(): void {

        this.deviceRemovedSubscription = this.loraProtocolService.onDeviceRemove.subscribe(() => {

            // // this.createGroundModules(3, false);
        });

        this.devicesClearedSubscription = this.loraProtocolService.devicesCleared.subscribe(() => {

            this.fireMistake(Mistakes.ModulesRemoved);
        });

        this.operatorRemovedSubscription = this.registerService.onOperatorRemoved.subscribe(operator => {

            if (this.registerService.operators.length < 2) {
                this.fireMistake(Mistakes.OperatorsRemoved);
            }
        });

        this.operatorsClearedSubscription = this.registerService.onOperatorsCleared.subscribe(() => {

            this.fireMistake(Mistakes.OperatorsRemoved);
        });

        this.operationSelectedSubscription = this.operationsService.selectedChanged.subscribe(operation => {

            if (!operation) {

                this.operationStepChangedSubscription?.unsubscribe();

                return;
            }

            if (operation.name !== 'drops') {

                return;
            }

            if (this.step === Steps.OperationSelection) {

                this.continue();
            }

            this.operationStepChangedSubscription = operation.service.stepChanged.subscribe(operationStep => {

                if ((this.step === Steps.OperationPreview && operationStep === OperationSteps.Setup) ||
                    (this.step === Steps.OperationSetup && operationStep === OperationSteps.Briefing) ||
                    (this.step === Steps.OperationBriefing && operationStep === OperationSteps.Deploying) ||
                    (this.step === Steps.OperationDeploying && operationStep === OperationSteps.Operating) ||
                    (this.step === Steps.OperationSimulation && operationStep === OperationSteps.Debriefing)) {

                    this.continue();
                }
            });

            this.operationStateChangedSubscription = operation.service.stateChanged.subscribe(operationState => {

                if(this.step === Steps.OperationDeployed && operationState === OperationStates.Processing) {

                    this.continue();
                }
            });

        });

    }

    private nextStep(): void {

        this.step++;

        this.resetStepDelay();
    }

    private fireMistake(mistake: Mistakes): void {

        this.mistake = mistake;

        this.resetStepDelay();
    }

    private resetStepDelay(): void {

        this.isStepDelayElapsed = false;

        if(this.stepDelayTimer) {
            clearTimeout(this.stepDelayTimer);
        }

        this.stepDelayTimer = setTimeout(() => {
            this.isStepDelayElapsed = true;
        }, MINIMAL_STEP_DELAY);
    }

    private createGroundModules(count: number, autoNaming: boolean): void {

        console.log(this.loraProtocolService.getDevicesByProduct(ProductsWithSoftware.GroundModule, true).length);

        if (this.loraProtocolService.getDevicesByProduct(ProductsWithSoftware.GroundModule, true).length > 0) {
            this.loraProtocolService.clearDevices();
        }

        if (count > Object.keys(GroundLocations).length) {
            count = Object.keys(GroundLocations).length;
        }

        for (let i = 0; i < count; i++) {
            const item = this.loraProtocolService.addDevice(i.toString(10), ProductsWithSoftware.GroundModule, true);

            item.name = autoNaming ? Object.keys(GroundLocations)[i] : '';
        }
    }

    private createOperators(count: number): void {

        if (this.registerService.operators.length > 0) {
            this.registerService.clearOperators();
        }

        if (count > OperatorsNames.length) {
            count = OperatorsNames.length;
        }

        for (let i = 0; i < count; i++) {
            this.registerService.getOrCreateOperator(this.registerService.operators.length + 1, OperatorsNames[i]);
        }
    }
}
