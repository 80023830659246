<app-body>

    <div class="time" wuiTacticalCorners>
        <ng-container *ngIf="destructionService.settings.timeLimit > 0">
            {{ destructionService.timer.time | milliseconds}}
        </ng-container>
        <ng-container *ngIf="destructionService.settings.timeLimit === '0'">
            {{ 'shared.unlimitedTime' | translate | capitalize }}
        </ng-container>
    </div>

    <lui-operation-areas-roles wuiTacticalCorners
        [devices]="loraProtocolService.devices"></lui-operation-areas-roles>

    <lui-timeline *ngIf="destructionService.timeline.length" wuiTacticalCorners
        [customIconsRootUrl]="onlineService.backendUrl"
        [items]="destructionService.timeline"
        [scrolling]="false">
    </lui-timeline>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button *ngIf="destructionService.state === OperationStates.Iddle"       (click)="destructionService.start()">{{ 'shared.start' | translate }}</wui-button>
        <wui-button *ngIf="destructionService.state === OperationStates.Processing"  (click)="destructionService.pause()">{{ 'shared.pause' | translate }}</wui-button>
        <wui-button *ngIf="destructionService.state === OperationStates.Paused"      (click)="destructionService.resume()">{{ 'shared.resume' | translate }}</wui-button>
        <wui-button *ngIf="destructionService.state === OperationStates.Processing"  (click)="destructionService.complete()">{{ 'shared.complete' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
