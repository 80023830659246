import { Component, OnInit, OnDestroy, ElementRef, ViewChild } from '@angular/core';

import { EnvironmentService } from '../../services/environment/environment.service';
import { ScreenService } from '../../services/screen/screen.service';
import { Window } from '../../interfaces/window.interface';
import { Manifest } from '../../interfaces/manifest.interface';
import { Clock } from '../../interfaces/clock.interface';
import { Panels } from '../../interfaces/panels.interface';

@Component({
    selector: 'wild-environment',
    templateUrl: './environment.component.html',
    styleUrls: ['./environment.component.scss']
})
export class EnvironmentComponent implements OnInit, OnDestroy {

    @ViewChild('searchField')
    private searchField: ElementRef<HTMLInputElement> | undefined;

    public searchValue = '';
    public searchedManifests = new Array<Manifest>();

    public panels: Panels = {
        isStartDisplayed: false,
        isNotificationsDisplayed: false
    };

    public clock: Clock = {
        date: new Date(),
        timer: undefined,
        formats: ['HH : mm : ss', 'fullDate'],
        formatIndex: 0
    };

    constructor(
        public environmentService: EnvironmentService,
        private screenService: ScreenService) { }

    public ngOnInit() {

        // Open windows for apps from the previous session
        this.environmentService.sessionLoad();

        // Initialize the clock
        this.clock.timer = setInterval(() => {
            this.clock.date = new Date();
        },
        500);

        // Clear the search
        this.clearSearch();
    }

    public ngOnDestroy() {

        clearInterval(this.clock.timer);
    }


    public search(search: string | null): void {

        this.searchValue = search?.toLowerCase() || '';

        if (this.searchValue.length) {

            // Filtered, with alphabetic ordered results
            this.searchedManifests = this.environmentService.manifests
                .filter(manifest => manifest.name.startsWith(this.searchValue))
                .sort((a, b) => a.name > b.name ? 1 : -1);
        } else {

            // All, with default order
            this.searchedManifests = this.environmentService.manifests;
        }
    }

    public confirmSearch(): void {

        if (this.searchedManifests.length) {

            this.openWindow(this.searchedManifests[0]);

        } else {

            console.log(`Environment: Maybe it's a secret command: ${this.searchValue}`);
        }

        this.closePanels();
    }

    public clearSearch(): void {

        this.search(null);
    }


    public openWindow(manifest: Manifest): void {
        this.environmentService.windowOpen(manifest);
    }

    public focusWindow(window: Window): void {
        this.environmentService.windowFocus(window);
    }

    public windowInBarClick(window: Window): void {

        if(this.screenService.isDesktop) {
            this.environmentService.toggleMinimizeRestoreWindow(window);
        } else {

            this.environmentService.windowMinimizeAll();

            this.environmentService.windowRestore(window);
        }
    }

    public toggleMinimizeRestoreWindow(window: Window): void {
        this.environmentService.toggleMinimizeRestoreWindow(window);
    }

    public closeWindow(window: Window): void {
        this.environmentService.closeWindow(window);
    }

    public modalClick(event: any): void {

        // Close the current modal if clicking around
        if (event.target.id === 'modals') {
            this.environmentService.modalClose();
        }
    }


    public toggleStartPanel(): void {
        this.panels.isStartDisplayed = !this.panels.isStartDisplayed;

        // Focus the search field when the start panel is open on a desktop device
        if (this.screenService.isDesktop && this.panels.isStartDisplayed) {
            setTimeout(() => {
                this.searchField?.nativeElement.focus();
            }, 0);
        }
    }

    public toggleNotificationsPanel(): void {
        this.panels.isNotificationsDisplayed = !this.panels.isNotificationsDisplayed
        this.environmentService.toastsClear();
    }

    public closePanels(): void {
        this.panels.isStartDisplayed = false;
        this.panels.isNotificationsDisplayed = false;

        this.clearSearch();
    }

    public toggleFullscreen(): void {
        this.screenService.toggleFullscreen();
    }


    public changeClockFormat(): void {

        if (++this.clock.formatIndex >= this.clock.formats.length) {
            this.clock.formatIndex = 0;
        }
    }

}
