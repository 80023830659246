<app-body>

    <ng-container *ngIf="registerService.operators.length > 0">

        <!-- header -->
        <div class="line">
            <div class="name"></div>
            <div class="teams">
                <div class="team"
                    *ngFor="let team of registerService.teamsSlots"
                    [class]="team.name | lowercase"
                    [wuiDisabled]="team.operatorsCount === 0"
                    [title]="team.operatorsCount + ' / ' + registerService.assignedOperatorsCount">
                    {{ team.id }}
                </div>
            </div>
            <div class="button"></div>
        </div>

        <!-- operators -->
        <div *ngFor="let operator of registerService.operators" class="line">
            <div class="name">{{ registerService.getOperatorFriendlyName(operator) }} </div>
            <div class="teams">
                <div class="team"
                    *ngFor="let team of registerService.teamsSlots"
                    [class]="team.name | lowercase"
                    [wuiBackgroundColor]="operator.teamId === team.id ? team.color : ''"
                    (click)="registerService.setOperatorTeam(operator, operator.teamId === team.id ? undefined : team)">
                    {{operator.teamId === team.id ? team.id : '·'}}
                </div>
            </div>
            <div class="button" (click)="registerService.removeOperator(operator)">-</div>
        </div>
    </ng-container>

    <wui-placeholder *ngIf="registerService.operators.length === 0">
        {{ 'apps.register.placeHolders.noOperators' | translate }}
    </wui-placeholder>

</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="sort()">{{ 'shared.sort' | translate }}</wui-button>
        <wui-button (click)="shuffle()">{{ 'shared.suffle' | translate }}</wui-button>
        <wui-button (click)="clear()">{{ 'shared.clear' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
