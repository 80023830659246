<app-body>
    <div class="time" wuiTacticalCorners>Operation complete</div>
    <lui-operation-report
        #reportComponent
        [report]="operationService.report"
        [customIconsRootUrl]="onlineService.backendUrl"
    >
    </lui-operation-report>
</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="reportComponent.toggleReplay()">
            {{
                'shared.' +
                    (reportComponent.isReplaying ? 'replaying' : 'replay')
                    | translate
            }}
        </wui-button>
        <wui-button (click)="operationService.exit()">
            {{ 'shared.exit' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
