import { Component, OnInit, Input } from '@angular/core';
import { EnvironmentService } from '@lightning/wild-environment';

@Component({
    selector: 'app-modals-card',
    templateUrl: './modal-cards.component.html',
    styleUrls: ['./modal-cards.component.scss']
})

export class ModalCardsComponent implements OnInit {

    @Input() 
    public logo: string = '';

    @Input()
    public description: string = '';

    @Input()
    public items: Array<any> = [];

    @Input() resolve = (item: any = null) => { };

    constructor(private environmentService: EnvironmentService) { }

    ngOnInit(): void {
    }

    public cancelClick(): void {

        this.resolve();

        this.environmentService.modalClose();
    }

    public itemClick(item: any): void {

        this.resolve(item);

        this.environmentService.modalClose();
    }
}
