import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    @Input()
    public logo: string = 'url(assets/logo.svg)';

    @Input()
    public description: string = '';

    public isHidden = false;

    constructor() {
    }

    ngOnInit() {
    }
}
