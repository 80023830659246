<app-body>
    <wui-section [name]="'shared.generalities' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.name' | translate }}</div>
            <wui-input value type="text" [(value)]="convoyService.settings.name"></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section [name]="'shared.ending' | translate">
        <wui-entry>
            <div name>{{ 'apps.operations.setup.timeLimit' | translate }}</div>
            <wui-select value [(value)]="convoyService.settings.timeLimit">
                @for(item of OperationProcessDurations | keyValue; track item) {
                    <option [value]="item.value">
                        {{ 'apps.operations.setup.durations.' + item.key | translate }}
                    </option>
                }
                <option value="60000" developpersOnly>{{ 'apps.operations.setup.durations.s60' | translate }}</option>
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name>Points limit</div>
            <wui-input value type="number" [min]="0" [(value)]="convoyService.settings.pointsLimit"></wui-input>
        </wui-entry>
    </wui-section>

    <wui-section name="modules">
        <wui-entry>
            <div name>Central bank module</div>
            <wui-select value [(value)]="convoyService.settings.modules.bank">
                @for(item of loraProtocolService.getDevicesByProduct(Products.GroundModule); track item) {
                    <option [value]="item.id">
                        {{ item.name || item.id }}
                    </option>
                }
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name>Stash module</div>
            <wui-select value [(value)]="convoyService.settings.modules.stash">
                @for(item of loraProtocolService.getDevicesByProduct(Products.GroundModule); track item) {
                    <option [value]="item.id">
                        {{ item.name || item.id }}
                    </option>
                }
            </wui-select>
        </wui-entry>
    </wui-section>

    <wui-section name="teams">
        <wui-entry>
            <div name>Conveyors team</div>
            <wui-select value [(value)]="convoyService.settings.teams.conveyors">
                @for(team of registerService.teams; track team) {
                    <option [value]="team.id">
                        {{team.name}}
                    </option>
                }
            </wui-select>
        </wui-entry>
        <wui-entry>
            <div name>Attackers team</div>
            <wui-select value [(value)]="convoyService.settings.teams.attackers">
                @for(team of registerService.teams; track team) {
                    <option [value]="team.id">
                        {{team.name}}
                    </option>
                }
            </wui-select>
        </wui-entry>
    </wui-section>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="convoyService.preview()">{{ 'shared.back' | translate }}</wui-button>
        <wui-button (click)="convoyService.brief()"
                    [wuiDisabled]="convoyService.settingsAreValid() === false">{{ 'shared.confirm' | translate }}</wui-button>
    </wui-buttons>
</app-footer>
