import { EventEmitter } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { GatewayConnectorsNames } from '../../../enums/gateway.enum';
import { GatewayConnector } from './connector.interface';

export class WebSocketGatewayConnector implements GatewayConnector {

    public onReceive = new EventEmitter<string>;
    public onConnect = new EventEmitter<void>;
    public onConnectFail = new EventEmitter<void>;
    public onDisconnect = new EventEmitter<void>;

    private _isConnected = false;

    private _webSocket: WebSocketSubject<any> | undefined;

    constructor(url: string) {

        this._webSocket = webSocket({
            url,
            serializer: e => e,     // No serializer (JSON.stringify() by default)
            deserializer: e => e,   // No deserializer (JSON.parse() by default)
            openObserver: {
                next: this.onConnectHandler.bind(this)
            },
            closeObserver: {
                next: this.onDisconnectHandler.bind(this)
            }
        });
    }

    public get name(): GatewayConnectorsNames {
        return GatewayConnectorsNames.Websocket;
    }

    public async connect(): Promise<void> {

        if (!this._webSocket) {
            return;
        }

        console.log('gatewayService: Connecting...');

        this._webSocket.subscribe({

            next: message => {
                if (message) {
                    this.onReceiveHandler(message.data);
                }
            },
            error: this.onConnectFailHandler.bind(this)
        });
    }

    public async disconnect(): Promise<void> {

        // TODO

    }

    public async send(data: string): Promise<void> {

        if (!this._webSocket || !this.isConnected) {
            return;
        }

        this._webSocket.next(data);
    }

    public get isConnected(): boolean {

        return this._isConnected;
    }

    private onConnectHandler(): void {

        this._isConnected = true;

        this.onConnect.emit();
    }

    private onConnectFailHandler(): void {

        this._isConnected = false;

        this.onConnectFail.emit();
    }

    private onReceiveHandler(data: string): void {

        this.onReceive.emit(data);
    }

    private onDisconnectHandler(): void {

        if (!this._isConnected) {
            return;
        }

        this._isConnected = false;

        this.onDisconnect.emit();

    }

}
