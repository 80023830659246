<app-header logo="assets/modals/online/logo.svg" [description]="'modals.onlineLogin.description' | translate"></app-header>

<app-body>
    @if(onlineService.isTokenValid()) {

        <wui-section name="{{ 'shared.account' | translate }}">

            {{ 'modals.onlineLogin.loggedIn' | translate: { account: onlineService.tokenPayload } | capitalize }}

            <pre developpersOnly>{{ onlineService.tokenPayload | json}}</pre>
        </wui-section>

    } @else {

        <wui-section name="{{ 'shared.login' | translate }}">
            <wui-entry>
                <div name>{{ 'shared.email' | translate | capitalize }}</div>
                <wui-input value type="email" [(value)]="loginRequest.email"></wui-input>
            </wui-entry>
            <wui-entry>
                <div name>{{ 'shared.password' | translate | capitalize }}</div>
                <wui-input value type="password" [(value)]="loginRequest.password" [required]="true"></wui-input>
            </wui-entry>
        </wui-section>
    }
</app-body>

<app-footer>
    <wui-buttons>
        <wui-button (click)="close()">{{ 'shared.cancel' | translate }}</wui-button>
        @if(onlineService.isTokenValid()) {
            <wui-button (click)="logout()">{{ 'shared.logout' | translate }}</wui-button>
        } @else {
            <wui-button (click)="signin()">{{ 'shared.signin' | translate }}</wui-button>
            <wui-button (click)="login()"
                [wuiDisabled]="!loginRequest.email || !loginRequest.password">
                {{ 'shared.login' | translate }}
            </wui-button>
        }
    </wui-buttons>
</app-footer>
