import { Component } from '@angular/core';
import { OperationDeploymentDurations, OperationProcessDurations, SoftwaresChannels } from '@lightning/lightning-definitions';
import { GatewayConnectorsNames } from '../../../shared/enums/gateway.enum';
import { LongRangeProtocolsNames } from '../../../shared/enums/lora.enum';
import { SettingsService } from '../../../shared/services/settings/settings.service';
import { GatewayService } from '../../../shared/services/gateway/gateway.service';

@Component({
    selector: 'app-generalities',
    templateUrl: './generalities.component.html',
    styleUrls: ['./generalities.component.scss']
})
export class GeneralitiesComponent {

    public readonly OperationDeploymentDurations = OperationDeploymentDurations;

    public readonly OperationProcessDurations = OperationProcessDurations;

    public readonly GatewayConnections = GatewayConnectorsNames;

    public readonly LongRangeProtocols = LongRangeProtocolsNames;

    public readonly SoftwaresChannels = SoftwaresChannels;

    constructor(
        public settingsService: SettingsService,
        public gatewayService: GatewayService) { }

    public wifiSsidChanged(): void {

        if( this.settingsService.settings.wifi.ssid === '') {
            this.settingsService.settings.wifi.password = '';
        }
    }

    public restart(): void {
        // eslint-disable-next-line no-self-assign
        window.location = window.location;
    }

}
