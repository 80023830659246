<app-body>

    <div class="track">{{track}}</div>

    <div class="tracks">
        FX
        <div (click)="setTrack(1)">1</div>
        <div (click)="setTrack(2)">2</div>
        <div (click)="setTrack(3)">3</div>
        <div (click)="setTrack(4)">4</div>
        <div (click)="setTrack(5)">5</div>
    </div>

</app-body>
<app-footer>
    <wui-buttons>
        <wui-button (click)="play()">Play</wui-button>
    </wui-buttons>
</app-footer>
