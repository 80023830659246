<app-container>

    <app-header logo="assets/apps/diagnostic/logo.svg"
                [description]="'apps.diagnostic.description' | translate">
    </app-header>

    @if(gatewayService.isConnected === false) {
        <app-disclamer
            level="error"
            [isInteractive]="true"
            (click)="environmentService.windowOpenByAppId('gateway')"
            wuiStopPropagation>
            {{ 'apps.diagnostic.disclamers.gatewayRequired' | translate }}
        </app-disclamer>
    }


    <app-body>

        <!-- @if (gatewayService.isConnected === false) {
            <wui-placeholder>
                {{ 'apps.diagnostic.disconnectedPlaceholder' | translate }}
            </wui-placeholder>
        } @else { -->


        <wui-section [name]="'apps.diagnostic.longRange.name' | translate">
            <wui-entry>
                <div name>{{ 'apps.diagnostic.longRange.rangeTest.name' | translate | capitalize }}</div>
                <div detail>{{ ('apps.diagnostic.longRange.rangeTest.detail.' + (isLoraRangeTesting ? 'started' : 'stoped')) | translate }}</div>
                <wui-button value (click)="toggleLoraRangeTest()" [class.pulsing]="isLoraRangeTesting">
                    {{ (isLoraRangeTesting ? 'shared.stop' : 'shared.start') | translate }}
                </wui-button>
            </wui-entry>
        </wui-section>

        <wui-section [name]="'apps.diagnostic.pmr.name' | translate">
            <wui-entry>
                <div name>{{ 'apps.diagnostic.pmr.rangeTest.name' | translate | capitalize }}</div>
                <div detail>{{ ('apps.diagnostic.pmr.rangeTest.detail.' + (isPMRRangeTesting ? 'started' : 'stoped')) | translate }}</div>
                <wui-button value (click)="togglePMRRangeTest()" [class.pulsing]="isPMRRangeTesting">
                    {{ (isPMRRangeTesting ? 'shared.stop' : 'shared.start') | translate }}
                </wui-button>
            </wui-entry>
        </wui-section>

        <!-- } -->

    </app-body>
</app-container>
