export const OperatorsNames = [
    'Solid',
    'Liquid',
    'Otacon',
    'Wolf',
    'Meryl',
    'Mantis',
    'Raven',
    'Decoy',
    'Ocelot',
    'Naomi',
    'Mei Ling'
];

export enum ActionsNames {
    Presence = 'presence',
    Resource = 'resource',
    Part = 'part'
}
