import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { SettingsService } from '@lightning/headquarter/app/apps/shared/services/settings/settings.service';
import { Timer } from '@lightning/headquarter/app/apps/shared/classes/timer';

import { LootingService } from '../../services/looting.service';

@Component({
    selector: 'app-looting-deploying',
    templateUrl: './deploying.component.html',
    styleUrls: ['./deploying.component.scss'],
})
export class DeployingComponent implements OnDestroy {

    public timer: Timer = new Timer();

    private timerSubscription: Subscription;

    constructor(
        public lootingService: LootingService,
        private settingsService: SettingsService) {

        // Same deployment time for all operations
        this.timer.startCountdown(this.settingsService.settings.operations.timeForDeployment);

        this.timerSubscription = this.timer.onComplete.subscribe(() => {

            this.lootingService.operate(true);
        });
    }

    public ngOnDestroy(): void {

        this.timer.clear();

        this.timerSubscription.unsubscribe();
    }

    public continue(): void {

        this.lootingService.operate(false);
    }
}

