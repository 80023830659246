import { Component, OnInit } from '@angular/core';
import { DropsService } from '../../services/drops.service';

@Component({
  selector: 'app-drops-briefing',
  templateUrl: './briefing.component.html',
  styleUrls: ['./briefing.component.scss']
})
export class BriefingComponent implements OnInit {

  constructor(public dropsService: DropsService) { }

  ngOnInit() {
  }

}
