<div class="top-bar">

    <!-- tray -->
    <ng-container *ngComponentOutlet="environmentService.tray?.component"></ng-container>

    <!-- clock -->
    <div class="clock" (click)="changeClockFormat()">
        {{clock.date | date: clock.formats[clock.formatIndex]}}
    </div>

</div>


<!-- desktop -->
<div class="desktop" (click)="environmentService.toastsClear()">

    <!-- windows -->
    <div class="windows">
        @for(window of environmentService.windows; track window) {
            <wild-window
                [data]="window"
                (mousedown)="environmentService.windowFocus(window)"
                (closing)="environmentService.closeWindow(window)"
                (changed)="environmentService.sessionSave()">
            </wild-window>
        }
    </div>

    <!-- panels -->
     @if(panels.isStartDisplayed || panels.isNotificationsDisplayed) {
        <div class="panels" (click)="closePanels()">

            <!-- start panel -->
            <div class="start2" *ngIf="panels.isStartDisplayed">
                <input
                    #searchField
                    type="text"
                    class="search"
                    [class.searching]="searchValue"
                    [ngModel]="searchValue"
                    (ngModelChange)="search($event)"
                    (keydown.enter)="confirmSearch()">
                <wui-cards *ngIf="searchedManifests.length">
                    @for(app of searchedManifests; track app) {
                        @if(app.isHidden !== true) {
                            <wui-card
                                [title]="app.name"
                                [description]="app.description"
                                [logo]="'assets/apps/' + app.id + '/icon.svg'"
                                (click)="openWindow(app)">
                            </wui-card>
                        }
                    }
                </wui-cards>
            </div>

            <!-- notifications -->
            @if(panels.isNotificationsDisplayed) {
                <div class="notifications" wuiTacticalCorners [wuiTacticalCornersOptions]="{ noRight: true }">
                    <div class="items">
                        @for(notification of environmentService.notifications; track notification; let index = $index) {
                            <div
                                class="notification"
                                [style.backgroundColor]="notification.color"
                                [wuiScroll]="index === environmentService.notifications.length - 1">
                                    <div class="logo" [wuiBackgroundImage]="notification.logo"></div>
                                    <div class="text">
                                        <div class="message">{{notification.message}}</div>
                                        <div class="time">{{notification.date | date: 'HH:mm:ss'}}</div>
                                    </div>
                            </div>
                        }
                    </div>
                </div>
            }

        </div>
    }

</div>

<!-- bar -->
<div class="bottom-bar" (dblclick)="toggleFullscreen()">

    <!-- start button -->
    <div class="start" (click)="toggleStartPanel()"></div>

    <!-- opened apps -->
    <div class="windows">
        @for(window of environmentService.windows; track window) {
            <div class="window"
                [ngClass]="window.display.state"
                [wuiBackgroundImage]="'assets/apps/' + window.manifest.id + '/logo.svg'"
                (click)="windowInBarClick(window)"
                (wuiPointerLong)="environmentService.closeWindow(window)">
            </div>
        }
    </div>

    <!-- tray -->
    <div class="tray">
        <ng-container *ngComponentOutlet="environmentService.tray?.component"></ng-container>
    </div>

    <!-- clock -->
    <div class="clock" (click)="changeClockFormat()">
        {{clock.date | date: clock.formats[clock.formatIndex]}}
    </div>

    <!-- notifications button -->
    @if(environmentService.notifications.length) {
        <div class="notifications" (click)="toggleNotificationsPanel()"></div>
    }
</div>


<!-- toast (lastest notifications)-->
<div class="toasts">
    @for(toast of environmentService.toasts; track toast; let index = $index) {
        <div class="toast"
            [style.backgroundColor]="toast.color"
            [style.cursor]="toast.callback ? 'pointer' : 'default'"
            [wuiScroll]="index === environmentService.toasts.length - 1"
            (click)="environmentService.toastClick(toast)">
            @if(toast.logo) {
                <div class="logo" [wuiBackgroundImage]="toast.logo"></div>
            }
            <div class="message">{{toast.message}}</div>
        </div>
    }
</div>

<!-- modals -->
@if (environmentService.modals.length > 0) {
    <div class="modals" id="modals" (click)="modalClick($event)">
        <wild-modal *ngFor="let modal of environmentService.modals" [data]="modal"></wild-modal>
    </div>
}

