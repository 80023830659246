import { Component, OnInit } from '@angular/core';
import { Tab, Tabs } from '@lightning/wild-ui';

import { OperationSteps } from '../../../shared/enums/operation.enums';
import { ConvoyService } from '../../services/convoy.service';

import { PreviewComponent } from '../preview/preview.component';
import { SetupComponent } from '../setup/setup.component';
import { BriefingComponent } from '../briefing/briefing.component';
import { ProcessComponent } from '../process/process.component';
import { DebriefingComponent } from '../debriefing/debriefing.component';

@Component({
    selector: 'app-convoy',
    templateUrl: './convoy.component.html',
    styleUrls: ['./convoy.component.scss']
})

export class ConvoyComponent implements OnInit {

    public tabs: Tabs = {
        items: [
            { selector: OperationSteps.Previewing,  text: 'preview',    component: PreviewComponent },
            { selector: OperationSteps.Setup,       text: 'setup',      component: SetupComponent },
            { selector: OperationSteps.Briefing,    text: 'briefing',   component: BriefingComponent },
            { selector: OperationSteps.Operating,   text: 'operation',  component: ProcessComponent },
            { selector: OperationSteps.Debriefing,  text: 'debriefing', component: DebriefingComponent }
        ]
    };

    constructor(public convoyService: ConvoyService) { }

    ngOnInit(): void {

        this.select(this.convoyService.step);

        this.convoyService.stepChanged.subscribe(step => this.select(step));
    }

    private select(selector: OperationSteps) {

        this.tabs.selected = this.tabs.items.find((item: Tab) => item.selector === selector);
    }
}
