<app-body>

    <!-- Placeholders -->
    @if(loraProtocolService.devices.length === 0) {
        <wui-placeholder>
            {{ (loraProtocolService.isConnected ?
                'apps.lora.devices.noItemDetectedYetPlaceholder' :
                'apps.lora.devices.noItemGatewayDisconnectedPlaceholder')
            | translate }}
        </wui-placeholder>
    } @else {

        <!-- Items -->
        @for(device of loraProtocolService.devices; track device) {
            <wui-expendable wuiTacticalCorners>
                <div preview class="preview">
                    <div class="logo"
                        [wuiDisabled]="!device.isEnabled"
                        [wuiBackgroundImage]="'assets/products/' + (device.state?.product || '' | uncapitalize) + '.svg'"></div>
                    <div class="information">
                        <div class="title">

                            <!-- name -->
                            <div class="name"
                                [class.invalid]="!device.name"
                                [wuiDisabled]="!device.isEnabled">
                                {{ device.state?.product }} {{ device.name || '#' + device.id }}
                            </div>

                            <!-- small buttons -->
                            <div class="buttons" wuiStopPropagation>

                                <div
                                    [wuiDisabled]="!device.isUsable"
                                    [class]="device.isEnabled ? 'disable' : 'enable'"
                                    [title]="(device.isEnabled ? 'shared.disable' : 'shared.enable') | translate"
                                    (click)="toggleEnableDisable(device)"></div>

                                <div class="remove"
                                    [title]="'shared.remove' | translate"
                                    (click)="loraProtocolService.removeDevice(device)"></div>
                            </div>

                        </div>
                        <div class="properties"
                            [wuiDisabled]="!device.isEnabled">

                            <!-- properties-->
                            <div class="property">
                                <img src="assets/apps/lora/properties/activity.svg"/>
                                {{ (device.inactivity || 0) / 1000 | number: '1.0-0' }}s ago
                            </div>
                            <div class="property"
                                [class.pulsing]="device.state?.battery === 0">
                                <img src="assets/apps/lora/properties/battery.svg"/>
                                {{ getFormattedBatteryLevel(device) }}
                            </div>
                            <div class="property"
                                [class.pulsing]="!isCompatibleSofware(device) || device.isUsingUnpublishedVersion"
                                [class.invalid]="!isCompatibleSofware(device)"
                                (click)="openUpdates()"
                                wuiStopPropagation>
                                v
                                {{ device.state?.software || ('shared.unknown' | translate) }}
                            </div>
                            <div class="property">
                                <img src="assets/apps/lora/properties/signal.svg"/>
                                {{ device.rssi }}dB {{ device.rssi | loraRssiPercents }}%
                            </div>
                        </div>
                    </div>
                </div>

                <div content class="content">
                    <wui-section name="network">
                        <wui-entry>
                            <div name>{{ 'shared.identifier' | translate | capitalize }}</div>
                            <div value>{{ device.id }}</div>
                        </wui-entry>
                        <wui-entry>
                            <div name>{{ 'shared.name' | translate | capitalize }}</div>
                            @if(device.state?.product === Products.GroundModule) {
                                <wui-select value [(value)]="device.name" (change)="loraProtocolService.saveDeviceName(device)" [required]="true">
                                    <option value=""></option>
                                    @for(location of GroundLocations | keyValue; track location) {
                                        <option [value]="location.value">{{ location.key }}</option>
                                    }
                                </wui-select>
                            } @else {
                                <wui-input value type="text" [(value)]="device.name" (change)="loraProtocolService.saveDeviceName(device)"></wui-input>
                            }
                        </wui-entry>
                        <wui-entry>
                            <div name>{{ 'shared.signal' | translate | capitalize }}</div>
                            <div value>{{ device.rssi }}dB {{ device.rssi | loraRssiPercents }}%</div>
                        </wui-entry>
                        <wui-entry>
                            <div name>{{ 'shared.lastActivity' | translate | capitalize }}</div>
                            <div value>{{ device.updated | date: 'HH:mm:ss' }}</div>
                        </wui-entry>
                    </wui-section>

                    @if(device.state) {
                        <wui-section name="state">
                            <wui-entry>
                                <div name>{{ 'shared.software' | translate | capitalize }}</div>
                                <wui-button value
                                    [class.pulsing]="!isCompatibleSofware(device)"
                                    [class.invalid]="!isCompatibleSofware(device)"
                                    (click)="openUpdates()"
                                    wuiStopPropagation>
                                    {{ device.state.software || ('shared.unknown' | translate) }}
                                </wui-button>
                            </wui-entry>
                            <wui-entry>
                                <div name>{{ 'shared.hardware' | translate | capitalize }}</div>
                                <div value>{{ device.state.hardware || ('shared.unknown' | translate) }}</div>
                            </wui-entry>
                            <wui-entry>
                                <div name>{{ 'shared.battery' | translate | capitalize }}</div>
                                <div value>{{ getFormattedBatteryLevel(device) }}</div>
                            </wui-entry>
                        </wui-section>
                    }

                    <wui-section name="actions">
                        <wui-entry>
                            <div name>{{ 'shared.identify' | translate| capitalize }}</div>
                            <div detail>{{ 'apps.lora.devices.identify.detail' | translate }}</div>
                            <wui-button value (click)="loraProtocolService.sendColorCommand('#FFFFFF', 1000, device.id)">{{ 'shared.run' | translate }}</wui-button>
                        </wui-entry>
                        <wui-entry>
                            <div name>{{ 'shared.reset' | translate| capitalize }}</div>
                            <div detail>{{ 'apps.lora.devices.reset.detail' | translate }}</div>
                            <wui-button value (click)="loraProtocolService.sendResetCommand(device.id)">{{ 'shared.run' | translate }}</wui-button>
                        </wui-entry>
                    </wui-section>

                    <wui-section name="development" developpersOnly>
                        <wui-entry>
                            <div name>Raw data</div>
                            <wui-json value [value]="device"></wui-json>
                        </wui-entry>
                    </wui-section>

                </div>
            </wui-expendable>
        }
    }
</app-body>

<app-footer>
    <wui-buttons>
        <wui-button
            (click)="loraProtocolService.clearDevices()"
            [wuiDisabled]="loraProtocolService.devices.length === 0">
            {{ 'shared.clear' | translate }}
        </wui-button>
    </wui-buttons>
</app-footer>
